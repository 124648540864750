import moment from "moment";

const PriceConvert = (value) =>{
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
    } else if(val >=1000) {
        val = (val/1000).toFixed(2) + ' K';
    }
    return val;
}

// NEED WORK
const DateYearsDiff = (customDate) => {
  console.log(customDate)
  let diff = (moment(customDate).unix() - moment().unix) / 1000;
  diff /= (60 * 60 * 24);
  return Math.abs(Math.round(diff/365.25));
 }

export {PriceConvert,DateYearsDiff};