import React, { useEffect, useState } from "react";
import Details from "./Details";
import Slider from "./Slider";
import AdminApi from "../../../api/AdminApi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Loading from "../../../utils/Loading";
import ScrollToTop from "../../../utils/ScrollToTop";

const SinglePropertyContainer = () => {
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop();
  console.log(lastPart);
  const Auth = useSelector((state) => state.auth.authData);
  const [data, setData] = useState("");
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const getPropertyById = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getProperty(lastPart, Auth.token);
        if (response) {
          // const projectData = await AdminApi.getProject(response.data.projectId, Auth.token);
          // console.log(projectData);
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };
  ScrollToTop();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPropertyById();
  }, [location]);
  return (
    <>
      {isLoading ? (
        <>
          <div className="flex mt-12 mx-auto items-center justify-center">
            <Loading />{" "}
            <h1 className="loading ml-2 dark:text-white">Loading</h1>
          </div>
        </>
      ) : (
        <>
          <div className="mt-3">
            {err.show ? (
              <div className="mt-2 text-[#da232aff] text-sm">
                {err.error.message ? err.error.message : err.error}
              </div>
            ) : (
              ""
            )}
          </div>
          {data && (
            <div className="single-property-main-container">
              <Slider data={data} />
              <Details data={data} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SinglePropertyContainer;
