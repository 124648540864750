import React from "react";

const IsRequired = ({ fieldLabel, fieldName, schema, isRequired = false }) => {
  if (isRequired) {
    return (
      <>
        {fieldLabel}
        <Asterik />
      </>
    );
  }
  return (
    <>
      {fieldLabel}
      {schema?.fields[fieldName]?.exclusiveTests?.required ? <Asterik /> : null}
    </>
  );
};

const Asterik = () => <span className="required-asterisk text-red-500">*</span>;

export default IsRequired;
