import { Button, Label, Textarea, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { FaMobile, FaUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material";
import AdminApi from "../../../../api/AdminApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import moment from "moment";
import Loading from "../../../../utils/Loading";
import DatePickerDefault from "../../../common/DatePickerDefault";
import SingleFileUpload from "../../../common/FileUpload/SingleFileUpload";
import { MdEmail } from "react-icons/md";
import ResellerApi from "../../../../api/ResellerApi";
import StateCitySelector from "./StateCitySelector";

const UploadBuilderContainer = () => {
  const navigate = useNavigate();
  const { theme, setTheme } = React.useContext(ThemeContext);
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop();
  const [isNew, setIsNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const auth = useSelector((state) => state.auth.authData);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });
  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  const createBuilderFormik = useFormik({
    initialValues: {
      userId: auth.id,
      name: "",
      logo: "",
      desc: "",
      businessSince: { type: Date },
      about: "",
      headquarter: "",
      address: "",
      operatingStates: [],
      operatingCities: [],
      contactPerson: {
        name: "",
        email: "",
        mobile: "",
      },
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 1 characters")
        .max(20, "Name must be max 20 Characters or less")
        .required("Required"),
      about: Yup.string().required("required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("images", values.logo);
      console.log(values);
      setFetching(true);
      const postUpdatedData = {
        name: values.name,
        logo: "",
        desc: values.desc,
        businessSince: values.businessSince,
        about: values.about,
        headquarter: values.headquarter,
        address: values.address,
        operatingStates: selectedStates,
        operatingCities: selectedCities,
        contactPerson: {
          name: values.contactPerson.name,
          email: values.contactPerson.email,
          mobile: values.contactPerson.mobile,
        },
        userId: auth.id,
      };

      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          //To create a new Builder
          if (isNew) {
            const imageResponse = values.logo
              ? await ResellerApi.addImages(formData, auth.token)
              : "";
            if (!values.logo || imageResponse?.length > 0) {
              postUpdatedData.logo = imageResponse[0];
              const response = await AdminApi.createBuilder(
                postUpdatedData,
                auth.token
              );
              if (response) {
                resetForm();
                toast("Success: Builder Created!");
                setFetching(false);
                navigate("/dashboard/admin/all-builders");
              } else {
                // setErr({ error: response.data.data, show: true });
                toast.warning("Some error");
              }
            } else {
              setFetching(false);
              setErr({ error: imageResponse?.data?.data, show: true });
            }
          }
          //To update existing builders
          else {
            //if image is updated
            if (imageChange) {
              const imageResponse = await ResellerApi.addImages(
                formData,
                auth.token
              );
              if (imageResponse?.length > 0) {
                postUpdatedData.logo = imageResponse[0];
                const response = await AdminApi.updateBuilder(
                  lastPart,
                  postUpdatedData,
                  auth.token
                );
                if (response) {
                  resetForm();
                  toast("Success: Builder Updated!");
                  setFetching(false);
                  navigate("/dashboard/admin/all-builders");
                } else {
                  setErr({ error: response.data.data, show: true });
                }
              }
            } else {
              // no image change
              postUpdatedData.logo = originalImgPath;
              const response = await AdminApi.updateBuilder(
                lastPart,
                postUpdatedData,
                auth.token
              );
              if (response) {
                setFetching(false);
                toast("Success: Builder Updated!");
                navigate("/dashboard/admin/all-builders");
              } else {
                // setErr({ error: response.data.data, show: true });
                toast.warning("some error occured, Please try again!");
              }
            }
            setFetching(false);
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        // alert("Error Updating Data");
      }
    },
  });

  // Image Variables
  const [imageChange, setImageChange] = useState(false);

  const [originalImgPath, setOriginalImgPath] = useState(
    createBuilderFormik.values.logo?.length > 0
      ? createBuilderFormik.values?.logo
      : null
  );
  const [isImagePresent, setIsImagePresent] = useState(false);
  const [dateValue, setDateValue] = React.useState("");

  const checkAndGetData = async () => {
    if (lastPart === "new") {
      setIsNew(true);
    } else {
      //get the data
      setIsLoading(true);
      setIsNew(false);
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await AdminApi.getBuilder(lastPart, auth.token);
          if (response) {
            setIsLoading(false);
            createBuilderFormik.setValues(response);
            setSelectedStates(createBuilderFormik.values.operatingStates || []);
            setSelectedCities(createBuilderFormik.values.operatingCities || []);
            setIsImagePresent(response.logo?.length > 0 ? true : false);
            setDateValue(moment(response.businessSince).utc());
            console.log(createBuilderFormik.values);
            return true;
          } else {
            setErr({ error: response.data.data, show: true });
            return false;
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        console.log("Error loading data");
      }
    }
  };

  const MultiSelectCitiesHandle = (e) => {
    createBuilderFormik.setFieldValue("operatingCities", e);
  };
  const MultiSelectStatesHandle = (e) => {
    createBuilderFormik.setFieldValue("operatingStates", e);
  };

  useEffect(() => {
    checkAndGetData();
  }, [location]);

  return (
    <>
      <h1 className="dark:text-white text-2xl font-semibold">
        Builder Settings
      </h1>
      {isLoading ? (
        <span className="loader"></span>
      ) : (
        <>
          <form
            onSubmit={createBuilderFormik.handleSubmit}
            encType="multipart/form-data"
            className="content flex lg:flex-row flex-col gap-4 justify-between mt-5"
          >
            <div className="info lg:w-1/3 w-full">
              <div className="profile p-8 dark:bg-gray-800 rounded-lg shadow-md mb-4">
                <div className="relative">
                  <SingleFileUpload
                    form={createBuilderFormik}
                    setImageChange={setIsImagePresent}
                    isImagePresent={isImagePresent}
                    imagePath={createBuilderFormik.values.logo}
                    fieldValue={"logo"}
                    fetching={isLoading}
                    buttonText={
                      isImagePresent
                        ? "Change Builder Logo"
                        : "Upload Builder Logo"
                    }
                  />
                </div>
                <div className="mt-4">
                  <h1 className="dark:text-white font-semibold text-2xl mb-2">
                    {createBuilderFormik.values.name}
                  </h1>
                  <p className="mb-4 dark:text-para text-para text-sm">
                    {createBuilderFormik.values.about}
                  </p>
                </div>
              </div>
              <div className="p-8 dark:bg-gray-800 rounded-lg shadow-md">
                <div className="flex gap-4 mb-3">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label
                        htmlFor="contactPerson.name"
                        value="Contact Person Name"
                        className="font-semibold"
                      />
                    </div>
                    <TextInput
                      icon={FaUser}
                      id="contactPerson.name"
                      type="text"
                      placeholder="Enter Contact Person Name"
                      required={true}
                      value={createBuilderFormik.values.contactPerson.name}
                      onChange={createBuilderFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex gap-4 mb-3">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label
                        htmlFor="contactPerson.email"
                        value="Contact Person Email"
                        className="font-semibold"
                      />
                    </div>
                    <TextInput
                      icon={MdEmail}
                      id="contactPerson.email"
                      type="text"
                      placeholder="Contact Person Email"
                      required={true}
                      value={createBuilderFormik.values.contactPerson.email}
                      onChange={createBuilderFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex gap-4 mb-3">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label
                        htmlFor="contactPerson.mobile"
                        value="Contact Person Phone Number"
                        className="font-semibold"
                      />
                    </div>
                    <TextInput
                      icon={FaMobile}
                      id="contactPerson.mobile"
                      type="text"
                      placeholder="Contact Person Mobile Number"
                      required={true}
                      value={createBuilderFormik.values.contactPerson.mobile}
                      onChange={createBuilderFormik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="full-info lg:w-2/3 w-full">
              <div className="p-8 dark:bg-gray-800 rounded-lg shadow-md">
                <h1 className="dark:text-white font-semibold text-2xl mb-4">
                  Builder Profile
                </h1>
                <div className=" flex flex-col gap-4">
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label htmlFor="buildername" value="Builder Name" />
                      </div>
                      <TextInput
                        id="name"
                        type="text"
                        placeholder="Enter Builder Name"
                        required={true}
                        value={createBuilderFormik.values.name}
                        onChange={createBuilderFormik.handleChange}
                      />
                      {createBuilderFormik.errors.name ? (
                        <p className="text-xs text-red-600 ">
                          {createBuilderFormik.errors.name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="since"
                          // value={`In business since: ${getDate}`}
                        />
                      </div>
                      <div>
                        {/* <DatePickerDefault /> */}
                        <div className="mb-2">
                          <Label
                            htmlFor="businessSince"
                            value="Business Since"
                          />
                        </div>
                        {/* <ThemeProvider theme={darkTheme}>
                      <CssBaseline />
                      <BasicDatePicker />
                    </ThemeProvider> */}
                        <DatePickerDefault
                          defaultValue={
                            createBuilderFormik.values.businessSince
                          }
                          handleDateChange={(newValue) => {
                            createBuilderFormik.setFieldValue(
                              "businessSince",
                              newValue.format("MM/DD/YYYY")
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 mt-10">
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label htmlFor="description" value="About Builder" />
                      </div>
                      <Textarea
                        id="about"
                        placeholder="Suncity group is a pioneer..."
                        className="text-sm"
                        required={true}
                        rows={4}
                        value={createBuilderFormik.values.about}
                        onChange={createBuilderFormik.handleChange}
                      />
                    </div>
                    {createBuilderFormik.errors.about ? (
                      <p className="text-xs text-red-600 ">
                        {createBuilderFormik.errors.about}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex gap-4 mt-2 w-full">
                    {/* <div className="flex-1"> */}
                    {/* <div className="mb-2 block">
                        <Label
                          htmlFor="operatingStates"
                          value="Operating States"
                        />
                      </div>
                      <MultiSelect
                        data={States}
                        defaultValue={
                          createBuilderFormik.values.operatingStates
                        }
                        handleChange={MultiSelectStatesHandle}
                      />
                    </div>
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="operatingCities"
                          value="Operating Cities"
                        />
                      </div>

                      <MultiSelect
                        disabled={
                          !createBuilderFormik.values.operatingStates?.length
                        }
                        data={Cities}
                        defaultValue={
                          createBuilderFormik.values.operatingCities
                        }
                        handleChange={MultiSelectCitiesHandle}
                      />
                    </div> */}
                    <StateCitySelector
                      className="w-full"
                      selectedStates={selectedStates}
                      setSelectedStates={setSelectedStates}
                      selectedCities={selectedCities}
                      setSelectedCities={setSelectedCities}
                      city={true}
                      locality={false}
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-4 items-center mt-5">
                <div className="flex-1 submit">
                  <Button
                    type="submit"
                    onSubmit={createBuilderFormik.handleSubmit}
                    fullSized
                    disabled={fetching}
                  >
                    {fetching ? (
                      <Loading />
                    ) : isNew ? (
                      "Create new Builder"
                    ) : (
                      "Update Builder"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default UploadBuilderContainer;
