import React from "react";
import MainFooter from "../components/common/MainFooter";
import SubFooter from "../components/common/SubFooter";
import SinglePropertyContainer from "../components/Website/SingleProperty/SinglePropertyContainer";

const SingleProperty = () => {
  return (
    <>
      <SinglePropertyContainer />
      <SubFooter />
      <MainFooter />
    </>
  );
};

export default SingleProperty;
