import React, { useEffect, useState } from "react";
import AdminApi from "../../../../api/AdminApi";
import { FaDollyFlatbed, FaExpand, FaShower } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PriceConvert } from "../../../../utils/PriceConvert";
import { BaseSetting } from "../../../../utils/settings";

const PropertiesByProject = ({ projectId }) => {
  console.log(projectId);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const getPropertiesByProjectId = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getByProject(projectId);
        if (response) {
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  console.log(data);

  useEffect(() => {
    getPropertiesByProjectId(projectId);
  }, []);
  return (
    <>
      <div className="flex flex-wrap gap-1 md:gap-5 mt-5 bg-transparent">
        {data &&
          data.map((item, index) => {
            return (
              <div className=" flex-1 min-w-[40%] justify-between bg-transparent">
                <div className="md:max-w-sm bg-white rounded-lg shadow-defaultShadow dark:bg-gray-800 dark:border-gray-700">
                  <Link to={`/single-property/${item._id}`}>
                    <img
                      className="rounded-t-lg"
                      src={`${BaseSetting.ApiDomain}/file/images/${item.images[0]}`}
                      alt=""
                    />
                  </Link>
                  <div className="p-5">
                    <Link to={`/single-property/${item?._id}`}>
                      <h5 className="overflow-custom mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {item.name}{" "}
                        <span className="ml-3 text-sm font-normal text-gray-600">
                          {item.availableType === "Rent"
                            ? "For Rent"
                            : "For Buy Sale"}
                        </span>
                      </h5>
                      <p className="text-sm hover:text-blue-700 text-blue-600 dark:text-white">
                        ₹ {PriceConvert(item?.priceDetails.expectedPrice)}
                      </p>
                    </Link>
                    <p className="my-3 text-xs text-gray-700 dark:text-gray-400 max-h-[35px] overflow-hidden">
                      {item.description}
                    </p>
                    <div className="flex gap-4">
                      <p className="flex items-center gap-2 text-para text-xs">
                        <FaDollyFlatbed />{" "}
                        <span>{item?.details?.bathroom}</span>
                      </p>
                      <p className="flex items-center gap-2 text-para text-xs">
                        <FaShower /> {item?.details?.bathroom}
                      </p>
                      <p className="flex items-center gap-2 text-para text-xs">
                        <FaExpand /> {item?.superBuiltupArea} sq.ft
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PropertiesByProject;
