import { Alert, Button, Label } from "flowbite-react";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Countdown from "react-countdown";

import { changePassword, forgotPass } from "../../presenter/Auth/auth";
import Loading from "../../utils/Loading";
import { Link } from "react-router-dom";

const ForgotPasswordContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpReceived, setIsOtpReceived] = useState(false);
  const [isPassChanged, setIsPassChanged] = useState(false);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <>
          <p className="underline text-xs text-blue-500">Resend otp</p>
        </>
      );
    } else {
      // Render a countdown
      return (
        <span className="loading text-xs text-blue-500">
          Resend OTP in {seconds}
        </span>
      );
    }
  };

  const forgotPasswordFormik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required("Required")
        .min(10, "Minimum 10 digits")
        .max(10, "Max 10 digits"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const postUpdatedData = {
        mobile: values.mobile,
      };
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await forgotPass(postUpdatedData);
          console.log(response);
          if (response) {
            if (response.data.status === 0) {
              alert(response.data.data);
              setErr({ error: response.data.data, show: true });
            } else {
              setIsOtpReceived(true);
              forgotPasswordFormik.values.mobile = values.mobile;
            }
            setIsLoading(false);
          } else {
            setErr({ error: response.data.data, show: true });
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        alert("Error Updating Data");
        setErr({
          error: "CORS Error",
          show: true,
        });
      }
    },
  });

  const changePasswordFormik = useFormik({
    initialValues: {
      mobile: forgotPasswordFormik.values.mobile,
      otp: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string(),
      otp: Yup.string()
        .max(4, "must be max four characters")
        .min(4, "minimum 4 characters"),
      newPassword: Yup.string().min(4, "Minimum four characters"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const postUpdatedData = {
        mobile: forgotPasswordFormik.values.mobile,
        otp: values.otp,
        newPassword: values.newPassword,
      };
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await changePassword(postUpdatedData);
          if (response) {
            if (response.data.status === 0) {
              alert(response.data.data);
              setErr({ error: response.data.data, show: true });
            } else {
              alert("Succesfully Changed!!");
              setIsPassChanged(true);
            }
          } else {
            setErr({ error: response.data.data, show: true });
          }
          setIsLoading(false);
        } else {
          alert("You're offline");
        }
      } catch (error) {
        setIsLoading(false);
        setErr({
          error: "CORS Error",
          show: true,
        });
        alert("OTP Form: Error Updating Data");
      }
    },
  });

  return (
    <>
      <div className="flex justify-center mt-6 mx-auto">
        <div className="hidden md:block image flex-1 w-1/2 h-screen">
          <img
            className="h-full"
            src="https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            alt="img"
          />
        </div>
        <div className="px-6 lg:px-8 h-[100vh] flex-1 w-full md:w-1/2">
          <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            Forgot Password
          </h3>
          <form
            className="flex flex-col gap-4"
            onSubmit={
              isOtpReceived
                ? changePasswordFormik.handleSubmit
                : forgotPasswordFormik.handleSubmit
            }
          >
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="mobile"
                  value="Enter Your Mobile Number to change your password"
                />
              </div>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={forgotPasswordFormik.values.mobile}
                onChange={forgotPasswordFormik.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="992838XXXX"
                shadow={true}
                required
                // disabled={!err.show}
              />
              {forgotPasswordFormik.errors.mobile ? (
                <p className="text-xs text-red-600 ">
                  {forgotPasswordFormik.errors.mobile}
                </p>
              ) : null}
            </div>

            {isOtpReceived && (
              <>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label htmlFor="otp" value="Enter OTP" />
                    </div>
                    <input
                      type="text"
                      id="otp"
                      value={changePasswordFormik.values.otp}
                      onChange={changePasswordFormik.handleChange}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="OTP"
                      shadow={true}
                      required
                    />
                    {changePasswordFormik.errors.otp ? (
                      <p className="text-xs text-red-600 ">
                        {forgotPasswordFormik.errors.otp}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label htmlFor="otp" value="Enter New Password" />
                    </div>
                    <input
                      type="password"
                      id="newPassword"
                      value={changePasswordFormik.values.newPassword}
                      onChange={changePasswordFormik.handleChange}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="new-password"
                      shadow={true}
                      required
                    />
                    {changePasswordFormik.errors.newPassword ? (
                      <p className="text-xs text-red-600 ">
                        {forgotPasswordFormik.errors.newPassword}
                      </p>
                    ) : null}
                  </div>
                </div>
                <Countdown date={Date.now() + 30000} renderer={renderer} />
              </>
            )}
            <div className="flex gap-4 mt-4">
              <div className="flex-1 w-full">
                <Button
                  type="submit"
                  onSubmit={
                    isOtpReceived
                      ? changePasswordFormik.handleSubmit
                      : forgotPasswordFormik.handleSubmit
                  }
                  disabled={isLoading}
                >
                  {isLoading ? <Loading /> : ""}
                  Verify
                </Button>
              </div>
            </div>
          </form>
          <div className="mt-3">
            {err.show ? (
              <div className="mt-2 text-[#da232aff] text-sm">
                {err.error.message ? err.error.message : err.error}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mt-3">
            {isPassChanged && (
              <>
                <Alert color="success" withBorderAccent={true}>
                  <span>
                    <span className="font-medium">
                      Your password was successfully changed.
                    </span>{" "}
                    Login with new password
                  </span>
                </Alert>
              </>
            )}
          </div>
          <div className="mt-8">
            <Link
              to={"/login"}
              // onClick={toggleForm}
              className="underline text-sm dark:text-white hover:underline hover:cursor-pointer"
            >
              Login using OTP
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordContainer;
