import React, { useState } from "react";
import { useSelector } from "react-redux";
import { changePassword, forgotPass } from "../../../presenter/Auth/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Label, TextInput } from "flowbite-react";
import { GiSave } from "react-icons/gi";
import Loading from "../../../utils/Loading";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const auth = useSelector((state) => state.auth.authData);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isPassChange, setIsPassChange] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const passChangeFormik = useFormik({
    initialValues: {
      mobile: auth.user.mobile,
      otp: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(4, "otp must be at least 4 characters")
        .max(4, "otp must be max 4 Characters or less")
        .required("Required"),
      newPassword: Yup.string()
        .min(4, "Password must be at least 4 characters")
        .max(20, "Password must be max 20 Characters or less")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const postUpdatedData = {
        mobile: values.mobile,
        otp: values.otp,
        newPassword: values.newPassword,
      };
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await changePassword(postUpdatedData);
          if (response) {
            setIsPassChange(true);
            setIsLoading(false);
            toast("Password Changed Succesfully");
            setIsOtpSent(false);
            return true;
          } else {
            setIsLoading(false);
            setErr({ error: response.data.data, show: true });
            return false;
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        alert("Error Updating Data");
      }
    },
  });

  const sendOtp = async () => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        setIsLoading(true);
        const response = await forgotPass({ mobile: auth.user.mobile });
        if (response) {
          console.log(response);
          setIsLoading(false);
          setIsOtpSent(true); // this will enable the otp and newpassword inputs
          return true;
        } else {
          setIsLoading(false);
          setErr({ error: response.data.data, show: true });
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      alert("Error Updating Data");
    }
  };
  return (
    <>
      <form onSubmit={passChangeFormik.handleSubmit}>
        <div className="p-8 dark:bg-gray-800 rounded-lg shadow-md mt-6">
          <h1 className="dark:text-white font-semibold text-2xl mb-4">
            Password information
          </h1>
          {isPassChange && (
            <p className="text-sm dark:text-white">
              {" "}
              You can now login with new password
            </p>
          )}
          <div className=" flex flex-col gap-4">
            <div className="">
              <div className="">
                <div className="mb-2 block">
                  <Label htmlFor="mobile" value="Your mobile number" />
                </div>
                <TextInput
                  disabled={true}
                  id="mobile"
                  type="text"
                  placeholder=""
                  value={passChangeFormik.values.mobile}
                />
              </div>
            </div>
            <div className="flex justify-start">
              <Button
                color={"white"}
                size={"sm"}
                className="hover:bg-blue-50 text-mainBlue"
                onClick={sendOtp}
                disabled={isLoading}
              >
                {isLoading ? <Loading /> : ""}
                Send OTP
              </Button>
            </div>
            {isOtpSent && (
              <>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label htmlFor="otp" value="Enter OTP" />
                    </div>
                    <TextInput
                      onChange={passChangeFormik.handleChange}
                      value={passChangeFormik.values.otp}
                      name="otp"
                      id="otp"
                      type="text"
                      placeholder="enter 4 digit otp you received on your registered mobile number"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label htmlFor="newPassword" value="Confirm password" />
                    </div>
                    <TextInput
                      onChange={passChangeFormik.handleChange}
                      name="newPassword"
                      id="newPassword"
                      type="text"
                      placeholder="Type your new password here"
                      value={passChangeFormik.values.newPassword}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <p className="dark:text-white text-sm">
                      Password requirements:
                    </p>
                    <p className="text-para text-sm mb-1">
                      Ensure that these requirements are met:
                    </p>
                    <ul className="dark:text-para text-xs pl-4 ">
                      <li>At least 5 characters </li>
                      <li>At least one lowercase character</li>
                      <li>
                        Inclusion of at least one special character, e.g., ! @ #
                        ?
                      </li>
                      <li>Shouln't contain your name</li>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <Button
                    color={"info"}
                    type="submit"
                    onClick={passChangeFormik.handleSubmit}
                  >
                    {isLoading ? <Loading /> : ""}
                    <GiSave className="mr-3" />
                    Change Password
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
      <div className="mt-3">
        {err.show ? (
          <div className="mt-2 text-[#da232aff] text-sm">{err.error}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ChangePassword;
