import React, { useState } from "react";
import { BaseSetting } from "../../../utils/settings";
import { RiUserFollowFill } from "react-icons/ri";

const SingleFileUpload = ({
  isImagePresent,
  imagePath,
  setImageChange,
  form,
  fieldValue,
  fetching,
  buttonText,
}) => {
  console.log(isImagePresent, imagePath, fieldValue, fetching, buttonText);
  const [state, setState] = useState(false);
  const [isImgPresent, setIsImgPresent] = useState(isImagePresent);

  const removeExistingImage = () => {
    if (window.confirm(`Are you sure you want to delete this image?`)) {
      // setImgArrUpdate((prev) => prev.filter((el, i) => i !== index)); // filter by index
      form.setFieldValue(fieldValue, "");
      setState(false);
    }
  };

  const handleChange = (e) => {
    setIsImgPresent(false);
    setImageChange(true);
    let imageFile = e.target.files[0];
    form.setFieldValue(fieldValue, imageFile);

    let imageFilePreview = URL.createObjectURL(e.target.files[0]);
    setState(imageFilePreview);

    console.log(form.values);
  };

  return (
    <>
      <div className="relative flex justify-center w-36" key={0}>
        {isImgPresent || state ? (
          <>
            <img
              src={
                isImgPresent
                  ? `${BaseSetting.ApiDomain}/file/images/${imagePath}`
                  : state
              }
              alt="img"
              className="flex-1 rounded-xl h-auto"
            />
            {/* <Tooltip placement="top-end" content="Remove this image">
              <IoRemoveCircle
                id={0}
                className="hover:cursor-pointer absolute top-0 right-0 bg-white rounded-2xl w-5 h-5"
                onClick={removeExistingImage}
              />
            </Tooltip> */}
          </>
        ) : (
          <RiUserFollowFill className="flex w-36 h-auto dark:text-gray-400" />
        )}
      </div>
      <div className="max-w-md mt-4" id="fileUpload">
        <div class="relative w-4/5 h-8 max-w-xs dark:bg-slate-500 bg-gray-100 rounded-lg shadow-inner">
          <input
            className="hidden dark:text-white w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="file_input_help"
            type="file"
            id="images"
            name="images"
            accept="image/*"
            onChange={handleChange}
          />
          <label
            html="images"
            htmlFor="images"
            class="z-20 flex flex-col-reverse items-center justify-center w-full h-full cursor-pointer"
          >
            <p class="z-10 text-xs font-light text-center dark:text-white text-gray-500">
              {buttonText}
            </p>
          </label>
        </div>
        <p
          class="mt-1 text-xs text-gray-500 dark:text-gray-300"
          id="file_input_help"
        >
          {state && <p>PNG, JPG or GIF (Best. 800x800px).</p>}
        </p>
      </div>

      {/* <div className="options justify-between shadow-defaultShadow text-gray-900 rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800 border-box pb-4">
        <div className="flex gap-4 mb-5">
          <div class="flex-1 font-sans ">
            <div class="flex flex-col items-center justify-center w-full h-auto dark:bg-gray-800 bg-white sm:rounded-lg">
              <div class="mt-5 mb-5 text-center">
                <h2 class="text-2xl font-semibold mb-2 dark:text-white">
                  Add photos to get 5X more responses
                </h2>
                <p class="text-xs text-gray-500 dark:text-para">
                  90% tenants contact on properties with photos.
                </p>
              </div>
              <div class="relative w-4/5 h-32 max-w-xs mb-5 bg-gray-100 rounded-lg shadow-inner">
                <input
                  multiple="multiple"
                  id="images"
                  name="images"
                  accept="image/*"
                  className="hidden w-100"
                  type="file"
                  onChange={handleChange}
                />
                <label
                  html="images"
                  htmlFor="images"
                  class="z-20 flex flex-col-reverse items-center justify-center w-full h-full cursor-pointer"
                >
                  <p class="z-10 text-xs font-light text-center text-gray-500">
                    Drag & Drop your files here
                  </p>
                  <svg
                    class="z-10 w-8 h-8 text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                  </svg>
                </label>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 justify-self-end w-full mt-4">
              {state.length > 0 &&
                state.map((item, index) => {
                  return (
                    <>
                      <div className="flex" key={index}>
                      </div>
                      <img src={item.images} alt="img" className="h-52" />
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="flex gap-2 mt-6 ml-3">
          <Button
            disabled={fetching || form.values.images.length === 0}
            className=""
            color={"gray"}
            onClick={() => removeCurrentImages()}
          >
            <CgRemove className="mr-2" /> Remove Images
          </Button>
          <Button
            disabled={fetching || form.values.images.length === 0}
            type="submit"
            onClick={form.handleSubmit}
          >
            {fetching ? <Loading  /> : ""}
            <BsUpload className="mr-2" /> Upload Images
          </Button>
        </div>
      </div> */}
    </>
  );
};

export default SingleFileUpload;
