import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ThemeContext } from "../../contexts/ThemeContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CssBaseline, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePickerDefault = ({ defaultValue, handleDateChange }) => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="w-full h-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={defaultValue}
              inputFormat="DD-MM-YYYY"
              onChange={(newValue) => {
                newValue.format("DD-MM-YYYY");
                handleDateChange(newValue);
              }}
              mask="__-__-____"
              name="date"
              renderInput={(params) => (
                <TextField {...params} className="h-6 dark:text-white" />
              )}
            />
          </LocalizationProvider>
        </div>
      </ThemeProvider>
    </>
  );
};

export default DatePickerDefault;
