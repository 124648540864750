import { Button, Label, Textarea } from "flowbite-react";
import React from "react";
import Amenities from "../../../common/Amenities/Amenities";
import Loading from "../../../../utils/Loading";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const AmenitiesForProperty = ({ form, fetching, handleButtonClick }) => {
  return (
    <>
      <h2 className=" dark:text-white mb-5 font-semibold text-xl text-blue-600 border-b border-gray-700-800 pb-5">
        Provide additional details about your property
      </h2>
      <div className="options justify-between">
        <div className="flex gap-4 mb-5">
          <div className="flex-1">
            <div className="mb-2 block">
              <Label htmlFor="waterSupply" value="Water Supply" />
            </div>
            <div className="flex">
              <select
                id="details.waterSupply"
                value={form.values.details.waterSupply}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled value={null}>
                  Water Supply
                </option>
                <option value={"Corporation"}>Corporation</option>
                <option value={"Borewell"}>Borewell</option>
                <option value={"Both"}>Both</option>
              </select>
            </div>
          </div>
          <div className="flex-1">
            <div className="mb-2 block">
              <Label htmlFor="bathroom" value="Bathrooms" />
            </div>
            <div className="flex">
              <select
                id="details.bathroom"
                value={form.values.details.bathroom}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled value={null}>
                  No. of Bathrooms
                </option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
            </div>
          </div>
          <div className="flex-1">
            <div className="mb-2 block">
              <Label htmlFor="balcony" value="Balcony" />
            </div>
            <div className="flex">
              <select
                id="details.balcony"
                value={form.values.details.balcony}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled value={null}>
                  No. of Balconies
                </option>
                <option value={Number(1)}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex gap-4 mb-5">
          {/* <div className="flex-1">
            <div className="mb-2 block">
              <Label
                htmlFor="propertyShower"
                value="Who will show the property"
              />
            </div>
            <div className="flex">
              <select
                id="details.propertyShower"
                value={form.values.details.propertyShower}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled value={null}>
                  Property Shower
                </option>
                <option value={"NeedHelp"}>Need Help</option>
                <option value={"I"}>I will show the property</option>
                <option value={"Neighbours"}>Neighbours</option>
                <option value={"Friends"}>Friends</option>
                <option value={"Tenants"}>Tenants</option>
              </select>
            </div>
          </div> */}
          <div className="flex-1">
            <div className="mb-2 block">
              <Label htmlFor="powerBackup" value="Power Backup" />
            </div>
            <div className="flex">
              <select
                id="details.powerBackup"
                value={form.values.details.powerBackup}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled value={null}>
                  Power Backup
                </option>
                <option value={"Full"}>Full</option>
                <option value={"Partial"}>Partial</option>
                <option value={"None"}>None</option>
              </select>
            </div>
          </div>
        </div>
        <Amenities form={form} />
        {/*
         *********************** Amenities child component will take the form *************************
         */}

        <div className="flex gap-6 mb-5 mt-10">
          <p className="dark:text-white text-para text-sm">
            Add Directions Tip for your buyers
          </p>
          <div className="w-full">
            <Textarea
              id="details.directionTipDesc"
              value={form.values.details.directionTipDesc}
              onChange={form.handleChange}
              required={true}
              rows={5}
              className="text-sm"
              placeholder="Add Directions Tip for your buyers"
            ></Textarea>
          </div>
        </div>
        <div className="flex gap-4 mt-10 mb-5 items-center">
          <Button
            disabled={fetching}
            onClick={() => handleButtonClick("resale")}
            className="flex items-end"
            color={"gray"}
          >
            {fetching ? <Loading /> : ""}
            <FaArrowLeft className="text-sm mr-2" />
            Back
          </Button>
          <Button
            disabled={fetching}
            onClick={() => handleButtonClick("gallery")}
            className="flex items-end"
          >
            {fetching ? <Loading /> : ""}
            Next to Gallery
            <FaArrowRight className="text-sm ml-2" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default AmenitiesForProperty;
