import React from "react";
import { useLocation } from "react-router";
import { CityContext } from "../../../contexts/CityContext";
import SimilarProperties from "../../Website/PropertyViewTypes/SimilarProperties/SimilarProperties";
import Breadcrumb from "../Breadcrumb";

const PropertiesInRow = () => {
  const { city, setCity } = React.useContext(CityContext);
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop();
  console.log(lastPart);

  const filterOptions = {
    city: lastPart ? lastPart : city,
  };
  return (
    <>
      <div className="w-full md:max-w-5xl mx-auto mt-10 md:mt-10 pt-5 md:pt-10">
        <div className="my-4">
          <Breadcrumb first={`Home`} second={`Location`} third={lastPart} />
        </div>
        <div className="flex items-center justify-center text-center ">
          <div className="">
            <h1 className="text-3xl text-stone-800 mb-5 font-semibold dark:text-white">
              Properties in {lastPart}
            </h1>
            <p className="text-para text-sm md:px-52 dark:text-white">
              These are the latest properties in this city. You can create the
              list using the “latest listing shortcode” and show items by
              specific categories.
            </p>
          </div>
        </div>
        <SimilarProperties filterOptions={filterOptions} />
      </div>
    </>
  );
};

export default PropertiesInRow;
