import { Footer, Sidebar } from "flowbite-react";
import React from "react";
import { BsLock } from "react-icons/bs";
import {
  FaBuilding,
  FaHome,
  FaLeaf,
  FaPeopleCarry,
  FaUser,
  FaUserCheck,
  FaCity,
} from "react-icons/fa";
import { HiChartPie, HiHeart, HiHome, HiUser, HiUsers } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Role } from "../../state/interface";

const SidebarDashboard = ({ isSidebarOpen }) => {
  const Auth = useSelector((state) => state.auth.authData);
  const AuthRole = Auth.user.role;

  const signout = () => {
    alert("Sign out");
  };

  return (
    <>
      <div
        className={`rounded-none scrollbar-hide top-[75px] fixed z-10 h-screen overflow-auto bg-white text-[#565656] ${
          isSidebarOpen ? "w-56" : "!hidden sm:hidden w-auto"
        } md:flex lg:flex xl:flex flex-col dashboard-sidebar`}
      >
        <Sidebar aria-label="Dashboard Sidebar" className="max-w-full h-screen">
          <Sidebar.ItemGroup className="rounded-none">
            <Sidebar.Items>
              {AuthRole === Role.ADMIN ? (
                <>
                  <Sidebar.ItemGroup className="rounded-none">
                    <Link to={"/"}>
                      <Sidebar.Logo
                        href="#"
                        img="https://flowbite.com/application-ui/demo/images/logo.svg"
                        imgAlt="Flowbite logo"
                      >
                        MIT Homes
                      </Sidebar.Logo>
                    </Link>
                    <Sidebar.Item icon={HiChartPie}>
                      <Link to={"/dashboard"}>
                        {isSidebarOpen && <span>Dashboard</span>}
                      </Link>
                    </Sidebar.Item>
                    <Sidebar.Collapse icon={FaUser} label="My Profile">
                      <Sidebar.Item href="#" icon={HiUser}>
                        <Link to={"/dashboard/profile"}>Profile</Link>
                      </Sidebar.Item>{" "}
                      <Sidebar.Item href="#" icon={BsLock}>
                        <Link to={"/dashboard/change-password"}>
                          Change Password
                        </Link>
                      </Sidebar.Item>
                      <Sidebar.Item href="#" icon={HiHeart}>
                        <Link to={"/dashboard/wishlist"}>Wishlist</Link>
                      </Sidebar.Item>
                    </Sidebar.Collapse>

                    <Footer.Divider />
                    <p className="dark:text-white">User management</p>
                    <Sidebar.Collapse icon={FaUserCheck} label="Users">
                      <Sidebar.Item>
                        <Link to={"/dashboard/admin/allusers"}>All Users</Link>
                      </Sidebar.Item>
                      <Sidebar.Item href="#">
                        <Link to={"/dashboard/admin/adduser"}>Add User</Link>
                      </Sidebar.Item>
                    </Sidebar.Collapse>
                    <Footer.Divider />
                    <Sidebar.Item icon={FaCity}>
                      <Link to={"/dashboard/admin/addLocalities"}>
                        Add Places
                      </Link>
                    </Sidebar.Item>
                    <p className="dark:text-white">Property Management</p>
                    <Sidebar.Collapse icon={FaHome} label="Properties">
                      <Link to={"/dashboard/admin/all-properties"}>
                        <Sidebar.Item>All Properties</Sidebar.Item>
                      </Link>
                      <Link to={"/dashboard/reseller/all-properties"}>
                        <Sidebar.Item>My Properties</Sidebar.Item>
                      </Link>
                      <Link to={"reseller/upload-property/new"}>
                        <Sidebar.Item href="#">Add New Property</Sidebar.Item>
                      </Link>
                    </Sidebar.Collapse>
                    <Sidebar.Collapse icon={FaBuilding} label="Projects">
                      <NavLink
                        to={"/dashboard/admin/all-projects"}
                        activeClassName="active"
                      >
                        <Sidebar.Item>All Projects</Sidebar.Item>
                      </NavLink>
                      <Link to={"/dashboard/admin/upload-project/new"}>
                        <Sidebar.Item href="#">Add New Project</Sidebar.Item>
                      </Link>
                    </Sidebar.Collapse>
                    <Sidebar.Collapse icon={HiUsers} label="Builders Manage">
                      <Link to={"/dashboard/admin/all-builders"}>
                        <Sidebar.Item>All Builders</Sidebar.Item>
                      </Link>
                      <Link to={"/dashboard/admin/upload-builder/new"}>
                        <Sidebar.Item href="#">Add New Builder</Sidebar.Item>
                      </Link>
                    </Sidebar.Collapse>
                    <Footer.Divider />
                    <p className="dark:text-white">Leads Management</p>
                    <Sidebar.Collapse icon={FaLeaf} label="Leads">
                      <Sidebar.Item>
                        <Link to={"/dashboard/admin/all-leads"}>All Leads</Link>
                      </Sidebar.Item>
                      <Sidebar.Item href="#">
                        <Link to={"/dashboard/reseller/leads"}>
                          Reseller Leads
                        </Link>
                      </Sidebar.Item>
                    </Sidebar.Collapse>
                    <Footer.Divider />
                    <p className="dark:text-white">Listing Requests</p>
                    <Sidebar.Collapse icon={FaPeopleCarry} label="Help Needed">
                      <Sidebar.Item>
                        <Link to={"/dashboard/admin/all-listings"}>
                          All Listings
                        </Link>
                      </Sidebar.Item>
                    </Sidebar.Collapse>
                    <Footer.Divider />
                    <p className="dark:text-white">Website Update</p>
                    <Sidebar.Collapse icon={FaHome} label="Static Pages">
                      <Sidebar.Item href="#">Home</Sidebar.Item>
                      <Sidebar.Item href="#">About</Sidebar.Item>
                      <Sidebar.Item href="#">Contact Us</Sidebar.Item>
                    </Sidebar.Collapse>
                  </Sidebar.ItemGroup>
                </>
              ) : (
                <>
                  <Sidebar.ItemGroup className="rounded-none">
                    <Link to={"/"}>
                      <Sidebar.Logo
                        href="#"
                        img="https://flowbite.com/application-ui/demo/images/logo.svg"
                        imgAlt="MIT Homes"
                      >
                        MIT Homes
                      </Sidebar.Logo>
                    </Link>

                    <Link to={"/dashboard/reseller"}>
                      <Sidebar.Item href="#" icon={HiChartPie}>
                        {isSidebarOpen && <span>Dashboard</span>}
                      </Sidebar.Item>
                    </Link>
                    <Sidebar.Collapse icon={HiUser} label="Profile">
                      <Link to={"/dashboard/profile"}>
                        {" "}
                        <Sidebar.Item href="#">My Profile</Sidebar.Item>
                      </Link>
                      <Link to={"/dashboard/change-password"}>
                        {" "}
                        <Sidebar.Item href="#">Change Password</Sidebar.Item>
                      </Link>
                    </Sidebar.Collapse>
                    <Footer.Divider />
                    <Sidebar.Collapse icon={HiHome} label="Properties">
                      <Link to={"/dashboard/reseller/upload-property/new"}>
                        <Sidebar.Item href="#">Upload Property</Sidebar.Item>
                      </Link>
                      <Link to={"/dashboard/reseller/all-properties"}>
                        <Sidebar.Item href="#">My Properties</Sidebar.Item>
                      </Link>
                      <Link to={"/dashboard/reseller/leads"}>
                        <Sidebar.Item href="#">Leads</Sidebar.Item>
                      </Link>
                    </Sidebar.Collapse>
                  </Sidebar.ItemGroup>
                </>
              )}
            </Sidebar.Items>
          </Sidebar.ItemGroup>
        </Sidebar>
      </div>
    </>
  );
};

export default SidebarDashboard;
