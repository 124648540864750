import { Badge, Button, Label, TextInput, Tooltip } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Cities, States } from "../../../types/State";
import { UpdateProfileWithoutPass } from "../../../presenter/Auth/auth";
import UserApi from "../../../api/UserApi";
import Loading from "../../../utils/Loading";
import { toast } from "react-toastify";
import SingleFileUpload from "../../common/FileUpload/SingleFileUpload";
import ResellerApi from "../../../api/ResellerApi";

const Profile = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.authData);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isImagePresent, setIsImagePresent] = useState(
    auth.user.profilePic?.length > 0 ? true : false
  );
  const [originalImgPath, setOriginalImgPath] = useState(auth.user.profilePic);
  const [imageChange, setImageChange] = useState(false);

  console.log(imageChange);

  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  const profileFormik = useFormik({
    initialValues: {
      name: auth.user.name,
      email: auth.user.email,
      mobile: auth.user.mobile,
      profilePic: "",
      address: {
        city: auth.user.address ? auth.user.address.city : "",
        state: auth.user.address ? auth.user.address.state : "",
      },
      // password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(20, "Name must be max 20 Characters or less")
        .required("Required"),
      email: Yup.string().email().required("Required"),
      mobile: Yup.string()
        .min(10, "Minimum")
        .max(10, "Mobile number should be 10 characters")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("images", values.profilePic);
      setIsLoading(true);
      const postUpdatedData = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        profilePic: "",
        address: {
          city: values.address.city,
          state: values.address.state,
        },
      };

      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          //lets say you changed the image
          if (imageChange) {
            const imageResponse = await ResellerApi.addImages(
              formData,
              auth.token
            );
            if (imageResponse?.length > 0) {
              postUpdatedData.profilePic = imageResponse[0];
              const response = await UpdateProfileWithoutPass(
                auth.id,
                auth.token,
                postUpdatedData
              );
              if (response) {
                toast("Success: Profile Picture Updated!");
                setIsLoading(false);
              } else {
                setErr({ error: response.data.data, show: true });
              }
            }
          } else {
            //image not changed, therefore binding the fetched image path only, which was saved in originalImgPath
            postUpdatedData.profilePic = originalImgPath;
            const response = await UpdateProfileWithoutPass(
              auth.id,
              auth.token,
              postUpdatedData
            );
            if (response) {
              // resetForm();
              toast("Success: Profile Updated!");
              setIsLoading(false);
            } else {
              setErr({ error: response.data.data, show: true });
            }
          }
        } else {
          setIsLoading(false);
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        alert("Error Updating Data");
      }
    },
  });

  const getMyProfile = async () => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        const response = await UserApi.getMe(auth.token);
        console.log(response);
        if (response) {
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getMyProfile();
  }, []);

  return (
    <>
      <h1 className="dark:text-white text-2xl font-semibold">User Settings</h1>
      <form onSubmit={profileFormik.handleSubmit} encType="multipart/form-data">
        <div className="content flex lg:flex-row flex-col gap-4 justify-between mt-5">
          <div className="info lg:w-1/3 w-full">
            <div className="profile p-8 dark:bg-gray-800 rounded-lg shadow-md mb-4 relative">
              <div className="relative">
                <SingleFileUpload
                  form={profileFormik}
                  setImageChange={setImageChange}
                  isImagePresent={isImagePresent}
                  imagePath={auth.user.profilePic}
                  fieldValue={"profilePic"}
                  fetching={isLoading}
                  buttonText={
                    isImagePresent ? "Change Profile Pic" : "Upload Profile Pic"
                  }
                />
              </div>

              <div className="mt-4">
                <h1 className="dark:text-white font-semibold text-2xl mb-2">
                  {auth.user.name}
                </h1>
                <p className="mb-4 dark:text-para text-para">
                  Real Estate Enthusiast
                </p>
                {/* <Link to={"#"}>
                  <Button color={"info"}>
                    <FaUpload className="mr-3" />
                    Change Picture
                  </Button>
                </Link> */}
              </div>
            </div>
            <div className="timezone p-8 dark:bg-gray-800 rounded-lg shadow-md">
              <h1 className="dark:text-white font-semibold text-2xl mb-2 flex items-center">
                Language & Time
                <Badge color="info" className="ml-4 inline-block">
                  coming soon
                </Badge>
              </h1>

              <div className="flex-1 mt-4">
                <div className="mb-2 block">
                  <label
                    for="countries"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Language
                  </label>
                </div>
                <div className="flex">
                  <select
                    disabled
                    id="countries"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>English (US)</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
              </div>
              <div className="flex-1 mt-4">
                <div className="mb-2 block">
                  <label
                    for="countries"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Timezone
                  </label>
                </div>
                <div className="flex">
                  <select
                    disabled
                    id="countries"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>+5:30 Indian Standard Time</option>
                    <option value="US"></option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="full-info lg:w-2/3 w-full">
            <div className="p-8 dark:bg-gray-800 rounded-lg shadow-md">
              <h1 className="dark:text-white font-semibold text-2xl mb-4">
                Profile Settings
              </h1>
              <div className=" flex flex-col gap-4">
                <div className="flex gap-4">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label htmlFor="name" value="Your Name" />
                    </div>
                    <TextInput
                      id="name"
                      type="text"
                      placeholder="Your Name"
                      required={true}
                      value={profileFormik.values.name}
                      onChange={profileFormik.handleChange}
                      // disabled={isLoading}
                    />
                    {profileFormik.errors.name ? (
                      <p className="text-xs text-red-600 ">
                        {profileFormik.errors.name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label htmlFor="email" value="Your Email" />
                    </div>
                    <TextInput
                      disabled={true}
                      id="email"
                      type="email"
                      placeholder="manish@moveintoday.com"
                      required={true}
                      value={profileFormik.values.email}
                      onChange={profileFormik.handleChange}
                    />
                    {profileFormik.errors.email ? (
                      <p className="text-xs text-red-600 ">
                        {profileFormik.errors.email}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex-1">
                    <div className="mb-2 flex items-center">
                      <Label htmlFor="mobile" value="Your Phone" />
                      <div className="ml-2">
                        {/* <span class="bg-green-500 text-white text-xs font-medium inline-flex items-center px-2.5 py-0 rounded-full mr-2 dark:bg-green-500 dark:text-white border border-green-500">
                        <HiCheck className="mr-1"/>
                        verified
                      </span> */}
                        <Tooltip content="Mobile number is verified">
                          <Badge color="success" size="sm" icon={HiCheck} />
                        </Tooltip>
                      </div>
                    </div>
                    <TextInput
                      disabled={true}
                      id="mobile"
                      type="text"
                      placeholder="Mobile Number"
                      required={true}
                      value={profileFormik.values.mobile}
                      onChange={profileFormik.handleChange}
                    />
                    {profileFormik.errors.mobile ? (
                      <p className="text-xs text-red-600 ">
                        {profileFormik.errors.mobile}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <label
                        for="address.state"
                        class=" mb-2 flex text-sm font-medium text-gray-900 dark:text-white"
                      >
                        State
                        {/* <Badge color="info" className="inline ml-4">
                          coming soon
                        </Badge> */}
                      </label>
                    </div>
                    <div className="flex">
                      <select
                        id="address.state"
                        value={profileFormik.values.address.state}
                        onChange={profileFormik.handleChange}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {States.map((state) => {
                          return (
                            <option value={state.value}>{state.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <label
                        for="countries"
                        class="block mb-2 flex text-sm font-medium text-gray-900 dark:text-white"
                      >
                        City
                      </label>
                    </div>
                    <div className="flex">
                      <select
                        id="address.city"
                        value={profileFormik.values.address.city}
                        onChange={profileFormik.handleChange}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Cities.map((state) => {
                          return (
                            <option value={state.value}>{state.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 mt-4 items-center justify-between">
                  <Button
                    disabled={isLoading}
                    type="submit"
                    onSubmit={profileFormik.handleSubmit}
                    color={"white"}
                    size={"sm"}
                    className="hover:border-blue-500 hover:border-b-4 bg-blue-50 text-mainBlue"
                  >
                    {isLoading ? <Loading /> : ""}
                    Update Profile
                  </Button>
                  <p
                    className="text-mainBlue text-sm hover:cursor-pointer hover:text-white hover:border-b-2"
                    onClick={() => navigate("/dashboard/change-password")}
                  >
                    Change password?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="mt-3">
        {err.show ? (
          <div className="mt-2 text-[#da232aff] text-sm">{err.error}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Profile;
