import { Badge, Button, Dropdown, Tooltip } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
import { HiCheck, HiDocument, HiHome } from "react-icons/hi";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import lottieHomeDark from "../../../../assets/json/BLACK-home-repair-orange.json";
import { IoLocation } from "react-icons/io5";
import { FaBuilding, FaPhotoVideo } from "react-icons/fa";
import { BsBox } from "react-icons/bs";
import PropertyDetails from "./PropertyDetails";
import LocalityDetails from "./LocalityDetails";
import ResaleDetails from "./ResaleDetails";
import Gallery from "./Gallery";
import AdditionalDetails from "./AdditionalDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import AdminApi from "../../../../api/AdminApi";
import { toast } from "react-toastify";
import { GoogleAutoCompleteContext } from "../../../../contexts/GoogleAutoCompleteData";
import moment from "moment";
import { CgClose } from "react-icons/cg";
import ResellerApi from "../../../../api/ResellerApi";
import AmenitiesForProperty from "./AmenitiesForProperty"; // contains common Amenities component

const UploadPropertyContainer = () => {
  const navigate = useNavigate();
  const Auth = useSelector((state) => state.auth.authData);
  const initialState = {
    stats: true,
    services: false,
    faq: false,
  };
  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  const { locationData, setLocationData } = useContext(
    GoogleAutoCompleteContext
  );

  const [dateValue, setDateValue] = React.useState("");
  const location = useLocation();
  const urlEndPath = location.pathname.split("/").pop();
  const [isNew, setIsNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const auth = useSelector((state) => state.auth.authData);

  const [projectID, setProjectID] = useState([]);

  const [propertyDetails, setPropertyDetails] = useState(true);
  const [localityDetails, setLocalityDetails] = useState(false);
  const [resaleDetails, setResaleDetails] = useState(false);
  const [amenityDetails, setAmenityDetails] = useState(false);
  const [galleyDetails, setGalleryDetails] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState(false);
  const [imgArrUpdate, setImgArrUpdate] = useState([]);

  const setAvailableDate = (dateValue) => {
    setDateValue(dateValue);
  };

  const PropertyValidationSchema = Yup.object({
    // // Not required as Name & description are generated automatically.
    // name: Yup.string()
    //   .min(3, "Name must be at least 3 characters long")
    //   .max(300, "Name must be max 20 Characters or less")
    //   .required("Name must be present"),
    // description: Yup.string()
    //   .min(10, "Property Description must be at least 10 characters long")
    //   .max(3000, "Description can maximum contain 3000 characters or less")
    //   .required("Proprty Description must be present"),
    availableType: Yup.string()
      .min(1, "Please select one option")
      .required("Please select one option"),
    // projectId: Yup.string()
    //   .min(1, "Please select one option")
    //   .required("Please select one option"),
    // projectId: Yup.when("projectNotListed", {
    //   is: true,
    //   then: Yup.string().notRequired(),
    //   otherwise: Yup.string()
    //     .min(1, "Please select one option")
    //     .required("Please select one option"),
    // }),
    projectNotListed: Yup.boolean(),
    type: Yup.string()
      .min(1, "Please select one option")
      .required("Please select one option"),
    bhkType: Yup.string()
      .min(1, "Please select one option")
      .required("Please select one option"),
    superBuiltupArea: Yup.number()
      .min(10, "Please enter correct Plot Area")
      .required("Please enter correct Plot Area"),
    builtUpArea: Yup.number()
      .min(10, "Please enter correct Plot Area")
      .required("Please enter correct Plot Area"),
    carpetArea: Yup.number()
      .min(10, "Please enter correct Plot Area")
      .required("Please enter correct Plot Area"),
    propertyAge: Yup.string()
      .min(1, "Please select one option")
      .required("Please select one option"),
    floorType: Yup.array().required("Please select one option"),
    localityDetails: Yup.object({
      city: Yup.string()
        .min(1, "Please select one option")
        .required("Please select one option"),
      street: Yup.string()
        .min(10, "Adress must be complete")
        .required("Please write your property address properly"),
    }),
    priceDetails: Yup.object({
      expectedPrice: Yup.string()
        .min(4, "Please enter correct expected price")
        .required("This can't be empty"),
    }),
    details: Yup.object({
      furnishingType: Yup.string()
        .min(1, "Please select one option")
        .required("Please select one option"),
      waterSupply: Yup.string()
        .min(1, "Please select one option")
        .required("Please select one option"),
      bathroom: Yup.string()
        .min(1, "Please select one option")
        .required("Please select one option"),
      balcony: Yup.string()
        .min(1, "Please select one option")
        .required("Please select one option"),
    }),
    amenities: Yup.object({}),
  });

  const createPropertyFormik = useFormik({
    // enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      images: "",
      featured: false,
      name: "",
      description: "",
      availableType: "",
      type: "",
      propertyType: "",
      projectId: "",
      //New option added here
      projectNotListed: false,
      customProjectName: "",

      bhkType: "",
      superBuiltupArea: 0, // in sq.ft
      builtUpArea: 0, // in sq.ft
      carpetArea: 0, // in sq.ft
      propertyAge: "",
      facing: "", // direction
      floorType: [],
      floor: "",
      totalFloor: 0,
      location: "",
      availableDate: "",
      meta: "", // bedroom, bathroom, car-parking
      amenities: {
        waterSupplyAllTime: false,
        powerBackup: false,
        cctv: false,
        basketBallCourt: false,
        tennisCourt: false,
        theaterRoom: false,
        cafeteria: false,
        library: false,
        yogaRoom: false,
        snooker: false,
        indoorGames: false,
        spa: false,
        businessMeetingRooms: false,
        guestRooms: false,
        banquetHall: false,
        carWash: false,
        privateLift: false,
        seniorCitizenCorner: false,
        cricketArea: false,
        squashCourt: false,
        jacuzzi: false,
        skatingRing: false,
        gym: false,
        swimmingPool: false,
        intercom: false,
        shoppingCenter: false,
        clubHouse: false,
        lift: false,
        playArea: false,
        gasPipeline: false,
        gatedSecurity: false,
        fireSafety: false,
        park: false,
        internetProvider: false,
        laundry: false,
        modularKitchen: false,
        smokeDetection: false,
      },
      localityDetails: {
        city: "",
        street: "",
        locality:
          locationData !== undefined
            ? locationData.address[0].formatted_address
            : "",
        lat: locationData !== undefined ? locationData.coord.lat : "",
        lng: locationData !== undefined ? locationData.coord.lng : "",
        other: "",
      },
      priceDetails: {
        expectedPrice: 0,
        negotiable: false,
        underLoan: false,
      },
      details: {
        waterSupply: "",
        bathroom: 0,
        balcony: 0,
        propertyShower: "",
        powerBackup: "",
        directionTipDesc: "",
        kitchenType: "",
        furnishingType: "",
        parking: "",
        propDesc: "",
      },
    },
    validationSchema: PropertyValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      //Name formation logic
      const nameLogicValue = `${values.bhkType} ${values.type} with Carpet Area ${values.carpetArea} in ${values.localityDetails.city} for ${values.availableType}`;
      console.log(nameLogicValue);
      values.name = nameLogicValue;
      // createPropertyFormik.setFieldValue(
      //   "name",
      //   nameLogicValue
      // );

      const formData = new FormData();
      for (let i = 0; i < values.images.length; i++) {
        formData.append("images", values.images[i]);
      }
      // formData.append("images", values.images);  //for single image file only 👈
      console.log(values);
      setFetching(true);
      const postUpdatedData = {
        images: "",
        featured: values.featured,
        name: values.name,
        type: values.type,
        propertyType: values.propertyType,
        projectId: values.projectId,
        projectNotListed: values.projectNotListed,
        customProjectName: values.customProjectName,
        availableType: values.availableType,
        bhkType: values.bhkType,
        superBuiltupArea: values.superBuiltupArea, // in sq.ft
        builtUpArea: values.builtUpArea, // in sq.ft
        carpetArea: values.carpetArea, // in sq.ft
        propertyAge: values.propertyAge,
        facing: values.facing, // direction
        floorType: values.floorType,
        floor: values.floor,
        totalFloor: values.totalFloor,
        location: values.location,
        description: values.description,
        // availableDate: values.availableDate,
        availableDate: dateValue,
        meta: values.meta, // bedroom, bathroom, car-parking
        amenities: {
          waterSupplyAllTime: values.amenities.waterSupplyAllTime,
          powerBackup: values.amenities.powerBackup,
          cctv: values.amenities.cctv,
          basketBallCourt: values.amenities.basketBallCourt,
          tennisCourt: values.amenities.tennisCourt,
          theaterRoom: values.amenities.theaterRoom,
          cafeteria: values.amenities.cafeteria,
          library: values.amenities.library,
          yogaRoom: values.amenities.yogaRoom,
          snooker: values.amenities.snooker,
          indoorGames: values.amenities.indoorGames,
          spa: values.amenities.spa,
          businessMeetingRooms: values.amenities.businessMeetingRooms,
          guestRooms: values.amenities.guestRooms,
          banquetHall: values.amenities.banquetHall,
          carWash: values.amenities.carWash,
          privateLift: values.amenities.privateLift,
          seniorCitizenCorner: values.amenities.seniorCitizenCorner,
          cricketArea: values.amenities.cricketArea,
          squashCourt: values.amenities.squashCourt,
          jacuzzi: values.amenities.jacuzzi,
          skatingRing: values.amenities.skatingRing,
          gym: values.amenities.gym,
          swimmingPool: values.amenities.swimmingPool,
          intercom: values.amenities.intercom,
          shoppingCenter: values.amenities.shoppingCenter,
          clubHouse: values.amenities.clubHouse,
          lift: values.amenities.lift,
          playArea: values.amenities.playArea,
          gasPipeline: values.amenities.gasPipeline,
          gatedSecurity: values.amenities.gatedSecurity,
          fireSafety: values.amenities.fireSafety,
          park: values.amenities.park,
          internetProvider: values.amenities.internetProvider,
          laundry: values.amenities.laundry,
          modularKitchen: values.amenities.modularKitchen,
          smokeDetection: values.amenities.smokeDetection,
        },
        localityDetails: {
          city: values.localityDetails.city,
          locality:
            locationData !== undefined
              ? locationData.address[0].formatted_address
              : values.localityDetails.locality,
          street: values.localityDetails.street,
          lat:
            locationData !== undefined && locationData.coord !== ""
              ? locationData.coord.lat
              : values.localityDetails.lat,
          lng:
            locationData !== undefined && locationData.coord !== ""
              ? locationData.coord.lng
              : values.localityDetails.lng,
          other: values.localityDetails.other,
        },
        priceDetails: {
          expectedPrice: values.priceDetails.expectedPrice,
          negotiable: values.priceDetails.negotiable,
          underLoan: values.priceDetails.underLoan,
        },
        details: {
          waterSupply: values.details.waterSupply,
          bathroom: values.details.bathroom,
          balcony: values.details.balcony,
          propertyShower: values.details.propertyShower,
          powerBackup: values.details.powerBackup,
          directionTipDesc: values.details.directionTipDesc,
          kitchenType: values.details.kitchenType,
          furnishingType: values.details.furnishingType,
          parking: values.details.parking,
          propDesc: values.details.propDesc,
        },
      };

      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          //To create a new Property
          if (isNew) {
            const imageResponse = await ResellerApi.addImages(
              formData,
              auth.token
            );
            if (imageResponse.length > 0) {
              postUpdatedData.images = imageResponse;
              const response = await AdminApi.createProperty(
                postUpdatedData,
                auth.token
              );
              if (response) {
                resetForm();
                setFetching(false);
                toast("Success: Property Created!");
                navigate(
                  `${
                    Auth.user.role === "admin"
                      ? "/dashboard/admin/all-properties"
                      : "/dashboard/reseller/all-properties"
                  }`
                );
              } else {
                setErr({ error: response.data.data, show: true });
              }
            } else {
              setFetching(false);
              alert(
                "Please add image add to the property from Gallery Tab!! 👇"
              );
            }
          }
          //To update existing property
          else {
            //lets say you added new images to the existing project
            if (values.images.length > 0) {
              const imageResponse = await ResellerApi.addImages(
                formData,
                auth.token
              );
              const combinedImages = [...imageResponse, ...imgArrUpdate];
              postUpdatedData.images = combinedImages;
              const response = await AdminApi.updateProperty(
                urlEndPath,
                postUpdatedData,
                auth.token
              );
              if (response) {
                // resetForm();
                toast("Success: Property Updated!");
                setFetching(false);
                navigate(
                  `${
                    Auth.user.role === "admin"
                      ? "/dashboard/admin/all-properties"
                      : "/dashboard/reseller/all-properties"
                  }`
                );
              } else {
                setErr({ error: response.data.data, show: true });
              }
            } else {
              //just for updating current images like removed one of them
              postUpdatedData.images = imgArrUpdate;
              const response = await AdminApi.updateProperty(
                urlEndPath,
                postUpdatedData,
                auth.token
              );
              if (response) {
                // resetForm();
                toast("Success: Property Updated!");
                setFetching(false);
                navigate(
                  `${
                    Auth.user.role === "admin"
                      ? "/dashboard/admin/all-properties"
                      : "/dashboard/reseller/all-properties"
                  }`
                );
              } else {
                setErr({ error: response.data.data, show: true });
              }
            }
            toast.warning("Uploading Property, Please Wait");
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        alert("Error Updating Data");
      }
    },
  });

  const checkAndGetData = async () => {
    if (urlEndPath === "new") {
      console.log("------------NEW PROPERTY ADD-----------------");
      createPropertyFormik.resetForm();
      setIsNew(true);
    } else {
      //get the data
      setIsLoading(true);
      setIsNew(false);
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await AdminApi.getProperty(urlEndPath, auth.token);
          if (response) {
            setIsLoading(false);
            createPropertyFormik.setValues(response); //this will set the form values automatically
            createPropertyFormik.setFieldValue("images", []);
            setImgArrUpdate(response.images);
            if (
              response.availableDate !== "" &&
              response.availableDate !== undefined
            ) {
              setDateValue(moment(response.launchedDate).utc());
              console.log(response.availableDate);
            }

            return true;
          } else {
            setErr({ error: response.data.data, show: true });
            return false;
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        console.log("Error loading data");
      }
    }
  };

  const getProjectList = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        const response = await AdminApi.getAllProjects(auth.token);
        if (response) {
          setIsLoading(false);
          // form.setFieldValue("projectId", response);
          setProjectID(response);
          return true;
        } else {
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      console.log(error);
      console.log("Error loading data");
    }
  };

  async function askforApproval() {
    if (
      window.confirm(
        `Are you sure you want to submit this Property for Approval:\r ${createPropertyFormik.values.name}?`
      )
    ) {
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await AdminApi.sendApproveRequest(
            //need change
            createPropertyFormik.values._id, //propertyid
            Auth.token
          );
          if (response) {
            toast("Success: Property Submitted for Approval!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
          } else {
            setErr({ error: response.data.data, show: true });
            setIsLoading(false);
            return false;
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        setIsLoading(false);
        console.log("Error loading data");
      }
    }
  }

  const removeExistingImage = (index) => {
    if (window.confirm(`Are you sure you want to delete this image?`)) {
      setImgArrUpdate((prev) => prev.filter((el, i) => i !== index)); // filter by index
    }
  };

  const handleButtonClick = (details) => {
    createPropertyFormik.validateForm();
    if (createPropertyFormik.errors) {
      console.log(createPropertyFormik.errors);
    }
    setPropertyDetails(details === "property");
    setLocalityDetails(details === "locality");
    setResaleDetails(details === "resale");
    setAmenityDetails(details === "amenity");
    setGalleryDetails(details === "gallery");
    setAdditionalDetails(details === "additional");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    checkAndGetData();
    getProjectList();
  }, [urlEndPath]);

  // useEffect(() => {
  //   console.log(createPropertyFormik, "createPropertyFormik");
  //   // createPropertyFormik.validate();
  // }, []);

  const isGaleryDisabled = () => {
    return (
      [
        "availableType",
        "projectId",
        "type",
        "bhkType",
        "superBuiltupArea",
        "builtUpArea",
        "carpetArea",
        "propertyAge",
        "facing",
        "floorType",
      ].filter((key) => {
        return createPropertyFormik.errors[key];
      }).length > 0 ||
      ["city", "street"].filter((key) => {
        return createPropertyFormik.errors?.["localityDetails"]?.[key];
      }).length > 0 ||
      Boolean(
        createPropertyFormik.errors?.["priceDetails"]?.["expectedPrice"] ||
          createPropertyFormik.errors?.["details"]?.["furnishingType"]
      )
    );
  };

  return (
    <>
      <div className="flex gap-4 mb-2 mt-4 items-center">
        <h1 className="dark:text-white text-xl ">
          {isNew ? "Upload a new Property" : "Update Property"}:{" "}
          <span className="font-semibold">
            {createPropertyFormik.values.name}
          </span>
        </h1>
        {isNew ? (
          <> </>
        ) : (
          <>
            {createPropertyFormik.values.adminApproval ? (
              <>
                <Tooltip content="Property is verified by Admin">
                  <Badge color="success" size="sm" icon={HiCheck} />
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip content="Property is not verified, Please Submit for Approval.">
                  <Badge
                    onClick={askforApproval}
                    color="failure"
                    size="sm"
                    icon={CgClose}
                    className="hover:cursor-pointer"
                  />
                </Tooltip>
              </>
            )}
          </>
        )}
      </div>
      <p className="dark:text-para text-xs mb-8">
        This section is to upload/update a specific property. You can make
        changes to your property here easily.
      </p>
      <form
        className="content block md:flex gap-6 justify-between mt-5"
        form={createPropertyFormik}
        encType="multipart/form-data"
      >
        <div className="mb-3 md:m-0 md:block tabs flex-3 md:p-4  rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
          <div className="block md:flex items-center border-lime-700">
            <div className="md:hidden propertyDropDown">
              <Dropdown
                label={
                  propertyDetails
                    ? "1. Property Details"
                    : localityDetails
                    ? "2. Locality Details"
                    : resaleDetails
                    ? "3. Resale Details"
                    : amenityDetails
                    ? "4. Amenities Details"
                    : galleyDetails
                    ? "5. Gallery Details"
                    : additionalDetails
                    ? "6. Additional Details"
                    : "Select a tab"
                }
                className="w-full"
                fullSized={true}
              >
                <Dropdown.Item>
                  <Button
                    color={`${propertyDetails ? "light" : "info"}`}
                    className={`w-full mb-4`}
                    onClick={() => handleButtonClick("property")}
                  >
                    <HiHome className="mr-3 h-4 w-4" /> Property Details
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    color={`${localityDetails ? "light" : "info"}`}
                    disabled={isLoading}
                    className="w-full mb-4"
                    onClick={() => handleButtonClick("locality")}
                  >
                    <IoLocation className="mr-3 h-4 w-4" /> Locality Details
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    color={`${resaleDetails ? "light" : "info"}`}
                    className="w-full mb-4"
                    disabled={isLoading}
                    onClick={() => handleButtonClick("resale")}
                  >
                    <FaBuilding className="mr-3 h-4 w-4" /> Resale Details
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    color={`${amenityDetails ? "light" : "info"}`}
                    className="w-full mb-4"
                    disabled={isLoading}
                    onClick={() => handleButtonClick("amenity")}
                  >
                    <BsBox className="mr-3 h-4 w-4" /> Amenities
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    color={`${galleyDetails ? "light" : "info"}`}
                    className="w-full mb-4"
                    disabled={isLoading}
                    onClick={() => handleButtonClick("gallery")}
                  >
                    <FaPhotoVideo className="mr-3 h-4 w-4" /> Gallery
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    color={`${additionalDetails ? "light" : "info"}`}
                    className="w-full mb-4"
                    disabled={isLoading}
                    onClick={() => handleButtonClick("additional")}
                  >
                    <HiDocument className="mr-3 h-4 w-4" /> Additional Details
                  </Button>
                </Dropdown.Item>
              </Dropdown>
            </div>
            <div className="hidden md:block flex-col">
              <Button
                color={`${propertyDetails ? "light" : "info"}`}
                className={`w-full mb-4`}
                onClick={() => handleButtonClick("property")}
              >
                <HiHome className="mr-3 h-4 w-4" /> Property Details
              </Button>
              <Button
                color={`${localityDetails ? "light" : "info"}`}
                disabled={
                  isLoading ||
                  [
                    "availableType",
                    "projectId",
                    "type",
                    "bhkType",
                    "superBuiltupArea",
                    "builtUpArea",
                    "carpetArea",
                    "propertyAge",
                    "facing",
                    "floorType",
                  ].filter((key) => {
                    return createPropertyFormik.errors[key];
                  }).length > 0
                }
                className="w-full mb-4"
                onClick={() => handleButtonClick("locality")}
              >
                <IoLocation className="mr-3 h-4 w-4" /> Locality Details
              </Button>
              <Button
                color={`${resaleDetails ? "light" : "info"}`}
                className="w-full mb-4"
                disabled={
                  isLoading ||
                  ["city", "street"].filter((key) => {
                    return createPropertyFormik.errors?.["localityDetails"]?.[
                      key
                    ];
                  }).length > 0
                }
                onClick={() => handleButtonClick("resale")}
              >
                <FaBuilding className="mr-3 h-4 w-4" /> Resale Details
              </Button>
              <Button
                color={`${amenityDetails ? "light" : "info"}`}
                className="w-full mb-4"
                disabled={
                  isLoading ||
                  Boolean(
                    createPropertyFormik.errors?.["priceDetails"]?.[
                      "expectedPrice"
                    ] ||
                      createPropertyFormik.errors?.["details"]?.[
                        "furnishingType"
                      ]
                  )
                }
                onClick={() => handleButtonClick("amenity")}
              >
                <BsBox className="mr-3 h-4 w-4" /> Amenities
              </Button>
              <Button
                color={`${galleyDetails ? "light" : "info"}`}
                className="w-full mb-4"
                disabled={isLoading || isGaleryDisabled()}
                onClick={() => handleButtonClick("gallery")}
              >
                <FaPhotoVideo className="mr-3 h-4 w-4" /> Gallery
              </Button>
              {/* <Button
                color={`${additionalDetails ? "light" : "info"}`}
                className="w-full mb-4"
                disabled={isLoading}
                onClick={() => handleButtonClick("additional")}
              >
                <HiDocument className="mr-3 h-4 w-4" /> Additional Details
              </Button> */}
            </div>
          </div>
        </div>

        {/* *********************** CONTENT **************/}
        <div className="flex-1 p-4 rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
          <div className="content">
            <div>
              {propertyDetails ? (
                <PropertyDetails
                  isNew={isNew}
                  form={createPropertyFormik}
                  fetching={fetching}
                  projectID={projectID}
                  handleButtonClick={handleButtonClick}
                  validationSchema={PropertyValidationSchema}
                />
              ) : localityDetails ? (
                <LocalityDetails
                  form={createPropertyFormik}
                  fetching={fetching}
                  handleButtonClick={handleButtonClick}
                  validationSchema={PropertyValidationSchema}
                />
              ) : resaleDetails ? (
                <ResaleDetails
                  fetching={fetching}
                  form={createPropertyFormik}
                  setAvailableDate={setAvailableDate}
                  dateValue={dateValue}
                  handleButtonClick={handleButtonClick}
                />
              ) : amenityDetails ? (
                <AmenitiesForProperty
                  form={createPropertyFormik}
                  fetching={fetching}
                  handleButtonClick={handleButtonClick}
                />
              ) : galleyDetails ? (
                <Gallery
                  form={createPropertyFormik}
                  imgArrUpdate={imgArrUpdate}
                  removeExistingImage={removeExistingImage}
                  isNew={isNew}
                  fetching={fetching}
                  handleButtonClick={handleButtonClick}
                />
              ) : additionalDetails ? (
                <AdditionalDetails
                  form={createPropertyFormik}
                  fetching={fetching}
                  handleButtonClick={handleButtonClick}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/*************** Right Sidebar for ads/promotions */}
        <div className="hidden md:block md:flex-3 max-w-min p-4 rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
          <div className="p-1">
            <h1 className="dark:text-white text-xl font-semibold text-center">
              Ads Space
            </h1>
            <div className="lottie">
              <Player
                autoplay
                loop
                src={lottieHomeDark}
                style={{ height: "100%", width: "200px", color: "blue" }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
            </div>
            <h1 className="text-base font-semibold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
              Beautiful home in making
            </h1>
          </div>
        </div>
      </form>
    </>
  );
};

export default UploadPropertyContainer;
