import { headers, post, put } from "../../api/index";
import { emptyAuth, setAuth } from "../../state/auth/Actions";
import { Auth } from "../../state/auth/interface";
import { clearError, setError } from "../../state/error/actions";
// import { Role } from "../../state/interface";
import { BaseSetting } from "../../utils/settings";
// import { getDoctorDetails } from "../DashBoard/Doctor";
//commented code needs refinement
import UserApi from "../../api/UserApi";
import { toast } from "react-toastify";
export interface Register {
  mobile: number;
  name: string;
  role: string;
  email: string;
}

export interface RegisterWithPass extends Register {
  password: string;
}
export interface VerifyOTP {
  otp: number;
  mobile: number;
}
export interface Login {
  mobile: string;
  email: string;
  password: string;
}

export interface ChangePassword {
  otp: number;
  mobile: number;
  newPassword: string;
}
/**
 * Not using this API now, its without Mobile Number Verification
 */
export const SignUpWithPass = async (req: Register) => {
  clearError("authError");
  return await post(BaseSetting.userApiDomain + "/register", req);
};

/***
 *1. Register Steps:
 */
// 1a. First register with details
export const SignUpWithOTP = async (req: Register) => {
  clearError("authError");
  return await post(BaseSetting.userApiDomain + "/generateRegisterOTP", req);
};

// 1b.Second verify the OTP, only after verifying the OTP, user will get registered
export const VerifySignUpOTP = async (req: VerifyOTP) => {
  try {
    clearError("authError");
    const response = await post(
      BaseSetting.userApiDomain + "/OTPRegistration",
      req
    );
    console.log(response);
    if (response.data.status === 1) {
      console.log(response.headers["x-auth-token"]);
      const userData = await UserApi.getMe(
        // response.data.data,
        response.headers["x-auth-token"]
      );
      console.log(userData._id);
      const { role, mobile, name, _id: id, email, profilePic, address } = userData;
      const data: Auth = {
        isAuthenticated: true,
        user: {
          name,
          mobile,
          id,
          email,
          role,
          profilePic,
          address: {
            city: address.city,
            state: address.state
          }
        },
        id: response.data.data,
        token: response.headers["x-auth-token"] as string,
      };
      setAuth(data);
      // if (role === Role.RESELLER) {
      //   // getDoctorDetails(id, data.token);
      // }
      return true;
    } else {
      setError(
        {
          error: response.data.data as string,
          show: true,
        },
        "authError"
      );
    }
  } catch (error) {
    setError(
      {
        error: error as string,
        show: true,
      },
      "authError"
    );
  }
};

/***
 * Login APIs
 */
//1. LoginWithEmail/Mobile and Password
export const SignInWithPass = async (req: Login) => {
  emptyAuth();

  try {
    clearError("authError");
    const response = await post(BaseSetting.userApiDomain + "/auth", req);
    console.log(response);
    const token = response?.headers["x-auth-token"];

    if (response.data.status === 1) {
      // const userData = await UserApi.getByUserId(response.data.data, token);
      const userData = await UserApi.getMe(token);
      console.log(userData);
      const { role, mobile, name, _id: id, email, profilePic, address } = userData;
      const data: Auth = {
        isAuthenticated: true,
        user: {
          name,
          mobile,
          id,
          email,
          role,
          profilePic,
          address: {
            city: address ? address.city : "",
            state: address ? address.state : ""
          }
        },
        id: response.data.data,
        token: response.headers["x-auth-token"] as string,
      };
      setAuth(data);
      toast(`Welcome ${data.user.name}`);
      // if (role === Role.RESELLER) {
      //   // getDoctorDetails(id, data.token);
      // }
      return true;
    } else {
      setError(
        {
          error: response.data.data as string,
          show: true,
        },
        "authError"
      );
      return true;
    }
  } catch (error) {
    setError(
      {
        error: error as string,
        show: true,
      },
      "authError"
    );
    return true;
  }
};

//2. Login With Mobile OTP ->
// 2a. Generate OTP
export const GenerateOTP = async (mobile: string) => {
  clearError("authError");
  return await post(BaseSetting.userApiDomain + "/generateOTP", mobile);
};

// 2b. Verify OTP
export const SubmitVerifyOTP = async (req: VerifyOTP) => {
  try {
    clearError("authError");
    const response = await post(BaseSetting.userApiDomain + "/authOTP", req);
    console.log(response);
    if (response.data.status === 1) {
      const userData = await UserApi.getMe(
        // response.data.data,
        response.headers["x-auth-token"]
      );
      console.log(userData._id);
      const { role, mobile, name, _id: id, email, profilePic, address } = userData;
      const data: Auth = {
        isAuthenticated: true,
        user: {
          name,
          mobile,
          id,
          email,
          role,
          profilePic,
          address: {
            city: address ? address.city : "",
            state: address ? address.state : "",
          }
        },
        id: response.data.data,
        token: response.headers["x-auth-token"] as string,
      };
      setAuth(data);
      // if (role === Role.RESELLER) {
      //   // getDoctorDetails(id, data.token);
      // }
      return true;
    } else {
      setError(
        {
          error: response.data.data as string,
          show: true,
        },
        "authError"
      );
      return false;
    }
  } catch (error) {
    setError(
      {
        error: error as string,
        show: true,
      },
      "authError"
    );
  }
};

//3. Forgot Password Process ->
// 3a. First generate otp on mobile number
export const forgotPass = async (mobile: string) => {
  clearError("authError");
  return await post(BaseSetting.userApiDomain + "/forgotPass", mobile);
};

// 3b. Verify otp and change password along the way
export const changePassword = async (req: ChangePassword) => {
  clearError("authError");
  return await post(BaseSetting.userApiDomain + "/changePassword", req);
};

export const UpdateProfileWithoutPass = async (
  id: string,
  token: string,
  data: any
) => {
  try {
    const response = await put(BaseSetting.userApiDomain + "/" + id, data, {
      ...headers,
      ["x-auth-token"]: token,
    });
    if (response.data.status === 1) {
      const { role, mobile, name, _id: id, email, profilePic, address } = response.data.data;
      const data: Auth = {
        isAuthenticated: true,
        user: {
          name,
          mobile,
          id,
          email,
          role,
          profilePic,
          address: {
            city: address ? address.city : "",
            state: address ? address.state: "",
          },
        },
        id: id,
        token: token,
      };
      setAuth(data);
      return true;
    } else {
      setError(
        {
          error: response.data.data as string,
          show: true,
        },
        "authError"
      );
      return true;
    }
  } catch (error) {
    setError(
      {
        error: error as string,
        show: true,
      },
      "authError"
    );
    return true;
  }
};
