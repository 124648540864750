import React, { useState, useEffect } from "react";
import MapStyles from "../../common/GoogleMap/MapStyles";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { Link } from "react-router-dom";

const MyMapComponent = ({ allCoord }) => {
  console.log(allCoord);
  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const center = {
    lat: 26.92098,
    lng: 75.79422,
  };
  const zoom = 13;
  const { theme, setTheme } = React.useContext(ThemeContext);
  const libraries = ["places"];
  console.log(theme);
  const [mapOptions, setMapOptions] = useState();
  const mapStyles = {
    dark: MapStyles.Dark.DarkMapWithAllOptions,
    light: MapStyles.Light.LightWithAllOptions,
  };

  useEffect(() => {
    setMapOptions({
      styles: mapStyles[theme],
    });
    console.log(`changing to ${theme}`);
  }, [theme]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_ALL_KEY,
    libraries: ["places"],
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(zoom);
    setMap(map);

    //new code
    const bounds = new window.google.maps.LatLngBounds();
    allCoord.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
    //ends here
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const markerOnLoad = (marker) => {
    console.log("Marker: ", marker);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      // center={center}
      zoom={zoom}
      onLoad={onLoad}
      // onUnmount={onUnmount}
      options={mapOptions}
      onClick={() => setActiveMarker(null)}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        {allCoord.map(({ id, name, position, city, propertyLink }) => (
          <Marker
            key={id}
            position={position}
            label={name}
            onClick={() => handleActiveMarker(id)}
            labelStyle={{
              backgroundColor: "yellow",
              fontSize: "32px",
              padding: "16px",
            }}
          >
            {activeMarker === id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div className="text-mainBlue">
                  <Link
                    className="text-mainBlue underline font-semibold"
                    to={`/single-property/${propertyLink}`}
                  >
                    {name}
                  </Link>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(MyMapComponent);
