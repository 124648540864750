import React, { useEffect, useState } from "react";
import AdminApi from "../../../../api/AdminApi";
import { useSelector } from "react-redux";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import Loading from "../../../../utils/Loading";

const ListingRequests = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);
  const AuthData = useSelector((state) => state.auth.authData);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const getAllUsers = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getAllListings(AuthData.token);
        console.log(response);
        if (response) {
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  console.log(data);

  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <>
      {data ? (
        <>
          <div className="p-6 rounded-lg bg-white shadow-defaultShadow dark:bg-gray-800">
            <div className="flex justify-between mb-6 items-center">
              <h1 className="dark:text-white text-xl font-bold">
                Property Upload Requests
              </h1>
              <Link to={"/dashboard/admin/all-listings"}>
                <Button
                  color={"white"}
                  size={"sm"}
                  className="hover:bg-blue-50 text-mainBlue"
                >
                  View All
                </Button>
              </Link>
            </div>

            <div className="flex">
              <ul class="flex-1 divide-y divide-gray-200 dark:divide-gray-700">
                {data &&
                  data.splice(0, 5).map((item) => {
                    return (
                      <>
                        <li class="py-4 sm:pb-4">
                          <div class="flex items-center space-x-4">
                            <div class="flex-shrink-0">
                              <img
                                alt="userimg"
                                class="w-8 h-8 rounded-full"
                                src="https://flowbite.com/application-ui/demo/images/users/neil-sims.png"
                              />
                            </div>
                            <div class="flex-1 min-w-0">
                              <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                {item.userId.name}
                              </p>
                              <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                                {item.userId.email}
                              </p>
                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {item.userId.mobile}
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ListingRequests;
