import React from 'react'
import UploadProjectContainer from '../../../../components/Dashboard/Admin/UploadProject/UploadProjectContainer';

const UploadProject = () => {
  return (
    <>
        <UploadProjectContainer />
    </>
  )
}

export default UploadProject;