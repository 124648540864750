import { Button, Card } from "flowbite-react";
import React, { useState } from "react";
import AdminApi from "../../../api/AdminApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoHandRight } from "react-icons/io5";
import Loading from "../../../utils/Loading";
import { useNavigate } from "react-router";

const ListingButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const Auth = useSelector((state) => state.auth.authData);
  const navigate = useNavigate();

  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  async function requestForListing() {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        setIsLoading(true);
        if (Auth.isAuthenticated) {
          const response = await AdminApi.requestForListing(Auth.token);
          if (response) {
            toast("Our Team will soon connect with you within 24 Hours", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
          } else {
            setErr({ error: response.data.data, show: true });
            setIsLoading(false);
            return false;
          }
        } else {
          toast.warning("Please login first", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/login");
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  }
  return (
    <>
      <div className="flex gap-4 mt-10 mb-2">
        <Card className="flex-1">
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Don’t want to fill all the details? Let us help you!
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            We offer the service to upload property photos, making sure to
            upload all the details on our platform correctly.
          </p>
          <Button onClick={requestForListing}>
            {isLoading ? <Loading /> : ""}
            I am Interested
            <IoHandRight className="ml-2" />
          </Button>
        </Card>
      </div>
      <div className="mt-3">
        {err.show ? (
          <div className="mt-2 text-[#da232aff] text-sm">
            {err.error.message ? err.error.message : err.error}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ListingButton;
