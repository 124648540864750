import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const MultiSelect = ({ data, defaultValue, handleChange, disabled }) => {
  const animatedComponents = makeAnimated();
  return (
    <>
      <Select
        closeMenuOnSelect={true}
        components={animatedComponents}
        defaultValue={defaultValue}
        isMulti
        options={data}
        theme={(theme) => ({
          ...theme,
          borderRadius: "5px",
        })}
        classNames={{
          control: (state) =>
            state.isFocused ? "border-red-600" : "border-grey-300",
        }}
        className="my-react-select-container w-full"
        classNamePrefix="my-react-select"
        onChange={(e) => handleChange(e)}
        isDisabled={disabled}
      />
    </>
  );
};

export default MultiSelect;
