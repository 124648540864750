import React, { useEffect, useState } from "react";
import MultipleImagesUpload from "../../../common/FileUpload/MultipleImagesUpload";
import { BaseSetting } from "../../../../utils/settings";
import { useLocation } from "react-router";
import { IoRemoveCircle } from "react-icons/io5";
import { Button, Tooltip } from "flowbite-react";
import { FaSave } from "react-icons/fa";

const Gallery = ({
  form,
  imgArrUpdate,
  removeExistingImage,
  isNew,
  fetching,
}) => {
  console.log(form);
  const location = useLocation();

  const [currentImages, setCurrentImages] = useState([]);

  useEffect(() => {
    if (isNew) {
      setCurrentImages([...form.values.images]);
    } else {
    }
  }, [location]);

  return (
    <>
      <h2 className=" dark:text-white mb-5 font-semibold text-xl text-blue-600 border-b border-gray-700-800 pb-5">
        Gallery Details
      </h2>
      <div className="mt-2 mb-6">
        <p className="dark:text-white text-sm text-gray-500">
          {isNew
            ? `Please upload building pictures: `
            : `Existing photos of the project:`}
        </p>
        <>
          <div className="flex gap-3 items-center shadow-lg">
            {imgArrUpdate.length > 0 &&
              imgArrUpdate.map((item, index) => {
                return (
                  <>
                    <div
                      className="flex mt-2 mb-4 items-center relative p-4"
                      key={index}
                    >
                      <img
                        src={`${BaseSetting.ApiDomain}/file/images/${item}`}
                        alt="img"
                        className="flex-1 w-36 h-auto"
                      />
                      <Tooltip placement="top-end" content="Remove this image">
                        <IoRemoveCircle
                          id={index}
                          className="hover:cursor-pointer absolute top-0 right-0 bg-white rounded-2xl w-5 h-5"
                          onClick={() => removeExistingImage(index)}
                        />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
          </div>
        </>
      </div>
      <MultipleImagesUpload form={form} fetching={fetching} />
      {isNew ? (
        <></>
      ) : (
        <>
          <Button
            color={`success`}
            className="w-full mt-4"
            disabled={fetching}
            onClick={form.handleSubmit}
          >
            <FaSave className="mr-3 h-4 w-4" /> Save All
          </Button>
        </>
      )}
    </>
  );
};

export default Gallery;
