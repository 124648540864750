import React from 'react'
import AllLeadsContainer from '../../../../components/Dashboard/Admin/AllLeads/AllLeadsContainer';

const AllLeads = () => {
  return (
    <>
        <AllLeadsContainer />
    </>
  )
}

export default AllLeads;