import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals.js';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext.js';
import { CityProvider } from './contexts/CityContext';
import { GoogleAutoCompleteProvider } from './contexts/GoogleAutoCompleteData';
import { AdvanceSearchProvider } from './components/Website/SearchTabs/AdvanceSearchContext';
import ScrollToTop from './utils/ScrollToTop';
import { LocalityProvider } from './contexts/LocalityContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <CityProvider>
        <LocalityProvider>
        <BrowserRouter>
        <ScrollToTop />
        <GoogleAutoCompleteProvider>
          <AdvanceSearchProvider>
          <App />
          </AdvanceSearchProvider>
        </GoogleAutoCompleteProvider>
        </BrowserRouter>
        </LocalityProvider>
      </CityProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
