import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { Button, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import { FaDownload, FaLocationArrow, FaPlus } from "react-icons/fa";
import { CSVLink } from "react-csv";
import Loading from "../../../../utils/Loading";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import AdminApi from "../../../../api/AdminApi";
import { differenceBy } from "lodash";
import { toast } from "react-toastify";

const AllBuildersContainer = () => {
  const Auth = useSelector((state) => state.auth.authData);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  console.log(selectedRows);
  const { theme, setTheme } = React.useContext(ThemeContext);
  const [data, setData] = useState([]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    async function handleDelete() {
      if (
        window.confirm(
          `Are you sure you want to delete Builder:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        try {
          if (navigator.onLine) {
            setErr({
              error: "",
              show: false,
            });
            const response = await AdminApi.deleteBuilder(
              selectedRows[0]._id,
              Auth.token
            );
            if (response) {
              toast("Success: Builder Deleted!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setIsLoading(false);
              setToggleCleared(!toggleCleared);
              setSelectedRows([]);
              setData(differenceBy(data, selectedRows, "name"));
            } else {
              setErr({ error: response.data.data, show: true });
              setIsLoading(false);
              return false;
            }
          } else {
            alert("You`re offline");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Error loading data");
        }
      }
    }

    return (
      <Button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);

  // createTheme creates a new theme named solarized that overrides the build in dark theme
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "#2aa198",
      },
      background: {
        default: "rgb(31 41 55 / 1)",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "rgb(55 65 81/1)",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  // A super simple expandable component.
  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const columns = [
    {
      name: "Builder Name",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Contact Person",
      sortable: true,
      selector: (row) => row.contactPerson.name,
    },
    {
      name: "Contact Phone",
      sortable: true,
      selector: (row) => row.contactPerson.mobile,
    },
    // {
    //   name: "About",
    //   selector: (row) => row.about,
    // },
    {
      name: "Business Since",
      sortable: true,
      selector: (row) => moment(row.businessSince).format("ll"),
    },
    {
      name: `Location`,
      selector: (row) => (
        <>
          <div className="flex gap-1 items-center">
            <FaLocationArrow />
            {row.operatingCities.map((item) => {
              return (
                <>
                  <h1 className="dark:text-white"> {item.label}, </h1>
                </>
              );
            })}
          </div>
        </>
      ),
    },
    {
      name: "Actions",
      grow: 2,
      selector: (row) => (
        <>
          <div className="flex gap-2">
            <Link
              className="flex gap-2"
              to={`/dashboard/admin/upload-builder/${row._id}`}
            >
              <Button color={"light"} size={"sm"}>
                <MdEdit className="mr-1" />
                Edit
              </Button>
            </Link>
          </div>
        </>
      ),
    },
  ];

  const getAllUsers = async () => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getAllBuilders(Auth.token);
        console.log(response);
        if (response) {
          setIsLoading(false);
          const filteredItems = response.filter(
            (item) =>
              item.name &&
              item.name.toLowerCase().includes(filterText.toLowerCase())
          );
          setData(filteredItems);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getAllUsers();
  }, []);

  const rowDisabledCriteria = (row) => row.type === "Admin";

  const filteredItems = data
    ? data.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : "";

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextInput
          id="username"
          type="text"
          placeholder="Search for Builders"
          required={true}
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const downloadCSV = () => {
    console.log("clicked");
  };

  return (
    <div className="users-list">
      <h1 className="dark:text-white text-2xl font-semibold mb-4">
        All Builders{" "}
      </h1>
      {!isLoading ? (
        <>
          <div className="afterLoad">
            <div className="users-option flex justify-between items-center mb-6">
              <div className="left flex justify-between items-center">
                <div className="search-box w-96 border-r border-darkBorder pr-4">
                  {subHeaderComponentMemo}
                </div>
                {selectedRows.length > 0 ? (
                  <>
                    <div className="flex ml-3">{contextActions}</div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="right flex gap-3">
                <Link to={"/dashboard/admin/upload-builder/new"}>
                  <Button color={"info"}>
                    <FaPlus className="mr-2 font-bold" />
                    Add Builder
                  </Button>
                </Link>
                <CSVLink
                  data={data}
                  filename={"my-file.csv"}
                  className="btn btn-primary"
                  target="_blank"
                >
                  <Button color={"gray"}>
                    <FaDownload className="mr-2 font-bold" />
                    Export Builders List
                  </Button>
                </CSVLink>
              </div>
            </div>

            <div className="data-table">
              <div className="data-loaded">
                <DataTable
                  title=""
                  striped={true}
                  columns={columns}
                  data={filteredItems}
                  pagination
                  // paginationResetDefaultPage={resetPaginationToggle}
                  customStyles={customStyles}
                  // subHeader
                  // subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  selectableRows
                  selectableRowsSingle={true}
                  // expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  theme={theme}
                  className={"font-medium"}
                  selectableRowDisabled={rowDisabledCriteria}
                  // defaultSortFieldId={1}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  fixedHeader={true}
                  responsive={true}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-center h-full">
            <h1 className="loading text-2xl dark:text-white block">Loading</h1>
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default AllBuildersContainer;
