//Redux Imports
import { persistStore } from "redux-persist";
import crossBrowserListener from "./utils/redux-persist-listner";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store from "./state/store";

import { CookiesProvider } from "react-cookie";
import AppRouter from "./routes";
import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBarProgress from "react-topbar-progress-indicator";
import { ThemeContext } from "./contexts/ThemeContext";

const App = () => {
  console.log(process.env, window.location.hostname);
  const { theme, setTheme } = React.useContext(ThemeContext);
  TopBarProgress.config({
    barColors: {
      0: theme == "light" ? "#6875F5" : "#fff",
      "1.0": theme == "light" ? "#E74694" : "#fff",
    },
    shadowBlur: 5,
    barThickness: 5,
  });
  const persistConfig = {
    key: "root",
    storage,
    stateReconciler: hardSet,
  };
  const persistor = persistStore(store);
  store.persistor = persistor;

  window.addEventListener(
    "storage",
    crossBrowserListener(store, persistConfig)
  );

  return (
    <>
      {process.env.NODE_ENV === "production" &&
      window.location.hostname === "moveintoday.com" ? (
        <>
          <h1 className="text-3xl flex items-center justify-center">
            Move In Today Coming Soon
          </h1>
        </>
      ) : (
        <Provider store={store}>
          <PersistGate
            loading={
              <div className="flex items-center justify-center h-screen text-2xl bg-white dark:bg-black dark:text-white">
                <span className="loader"></span>
                <TopBarProgress barThickness={3} />
              </div>
            }
            persistor={persistor}
          >
            <CookiesProvider>
              <ToastContainer />

              <AppRouter />
            </CookiesProvider>
          </PersistGate>
        </Provider>
      )}
    </>
  );
};
export default App;
