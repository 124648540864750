const PropertyType = [
    {
        value: 'flat',
        label: 'Flat',
    },    
    {
        value: 'villa',
        label: 'Villa',
    },
    {
        value: 'plot',
        label: 'Plot',
    },
    {
        value: 'Mixed',
        label: 'Mixed'
    }
];

enum PropertyRole {
    APARTMENT = "Apartment",
    FLAT = "Flat",
    VILLA = "Villa",
    HOUSE = "House"
  }

export {PropertyType, PropertyRole};