import React from 'react'
import ResellerDashboardConatiner from '../../../components/Dashboard/Reseller/ResellerDashboardConatiner';

const ResellerDashboard = () => {
  return (
    <>
        <ResellerDashboardConatiner />
    </>
  )
}

export default ResellerDashboard;