import { Footer } from "flowbite-react";
import React from "react";
import { Link } from "react-router-dom";

const SubFooter = () => {
  return (
    <>
      <Footer.Divider />
      <div className="mt-12 max-w-5xl mx-auto mb-12">
        <Footer bgDark={false} className="shadow-defaultShadow">
          <div className="w-full">
            <div className="grid w-full grid-cols-2 gap-4 py-8 px-6 md:grid-cols-4">
              <div className="">
                <Footer.Title title="Location" />
                <Footer.LinkGroup col={true}>
                  <Footer.Link href="#">
                    <Link to={`/property-types/location/Jaipur`}>Properties in Jaipur</Link>
                  </Footer.Link>
                  <Footer.Link href="#">
                  <Link to={`/property-types/location/Mumbai`}>Properties in Mumbai</Link>
                  </Footer.Link>
                  <Footer.Link href="#">
                  <Link to={`/property-types/location/Chandigarh`}>Properties in Chandigarh</Link>
                  </Footer.Link>
                  <Footer.Link href="#">
                  <Link to={`/property-types/location/Delhi`}>Properties in Delhi</Link>
                  </Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.Title title="Projects" />
                <Footer.LinkGroup col={true}>
                  <Footer.Link href="#">
                  <Link to={`/project/648a60c162d9ef8d73fdcf77`}>Florenza Elites</Link>
                  </Footer.Link>
                  <Footer.Link href="#">XYZ</Footer.Link>
                  <Footer.Link href="#">ABC</Footer.Link>
                  <Footer.Link href="#">Lorem Ipsum</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.Title title="legal" />
                <Footer.LinkGroup col={true}>
                  <Footer.Link href="#">Privacy Policy</Footer.Link>
                  <Footer.Link href="#">Licensing</Footer.Link>
                  <Footer.Link href="#">Terms & Conditions</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.Title title="download" />
                <Footer.LinkGroup col={true}>
                  <Footer.Link href="#">iOS</Footer.Link>
                  <Footer.Link href="#">Android</Footer.Link>
                  <Footer.Link href="#">Windows</Footer.Link>
                  <Footer.Link href="#">MacOS</Footer.Link>
                </Footer.LinkGroup>
              </div>
            </div>
          </div>
        </Footer>
      </div>
    </>
  );
};

export default SubFooter;
