const StatesJSON: any = {
  AndamanNicobar: "Andaman and Nicobar Islands",
  AndraPradesh: "Andhra Pradesh",
  ArunachalPradesh: "Arunachal Pradesh",
  Assam: "Assam",
  Bihar: "Bihar",
  Chandigarh: "Chandigarh",
  Chhattisgarh: "Chhattisgarh",
  DadraHaveli: "Dadra and Nagar Haveli",
  DamanDiu: "Daman and Diu",
  Delhi: "Delhi",
  Goa: "Goa",
  Gujarat: "Gujarat",
  Haryana: "Haryana",
  HimachalPradesh: "Himachal Pradesh",
  JammuKashmir: "Jammu and Kashmir",
  Jharkhand: "Jharkhand",
  Karnataka: "Karnataka",
  Kerala: "Kerala",
  Ladakh: "Ladakh",
  Lakshadweep: "Lakshadweep",
  MadhyaPradesh: "Madhya Pradesh",
  Maharashtra: "Maharashtra",
  Manipur: "Manipur",
  Meghalaya: "Meghalaya",
  Mizoram: "Mizoram",
  Nagaland: "Nagaland",
  Odisha: "Odisha",
  Puducherry: "Puducherry",
  Punjab: "Punjab",
  Rajasthan: "Rajasthan",
  Sikkim: "Sikkim",
  TamilNadu: "Tamil Nadu",
  Telangana: "Telangana",
  Tripura: "Tripura",
  UttarPradesh: "Uttar Pradesh",
  Uttarakhand: "Uttarakhand",
  WestBengal: "West Bengal",
};

const CityJSON: any = {
  Pune: "Pune",
  Jaipur: "Jaipur",
  Mumbai: "Mumbai",
  Indore: "Indore",
  Jodhpur: "Jodhpur",
  Udaipur: "Udaipur",
  Ajmer: "Ajmer",
  Delhi: "Delhi",
  Gurgaon: "Gurgaon",
  Noida: "Noida",
  Bangalore: "Bangalore",
};

const StateKeys = Object.keys(StatesJSON);
const States = StateKeys.map((index) => {
  return {
    value: index,
    label: StatesJSON[index],
  };
});

const CityKeys = Object.keys(CityJSON);
const Cities = CityKeys.map((index) => {
  return {
    value: index,
    label: CityJSON[index],
  };
});

// const allCitiesfromStateList = (states) => {
//   states.map((state) => {
//     return {
//       value: citystate.value,
//       label: CityJSON[index],
//     };
//   });
// };

export { States, Cities };
