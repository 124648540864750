import React, { useEffect, useState } from "react";
import AdminApi from "../../../../api/AdminApi";
import moment from "moment";

const MiniBuilderProfile = ({ builderId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const getBuilderById = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getBuilder(builderId);
        if (response) {
          setIsLoading(false);
          console.log(moment(data.businessSince).unix());
          console.log(new Date().getTime());
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  console.log(data);

  useEffect(() => {
    getBuilderById();
  }, []);
  return (
    <>
      {data && (
        <>
          <div class="m-4 md:m-0 md:max-w-sm bg-white border border-gray-200 rounded-lg shadow-defaultShadow dark:bg-gray-800 dark:border-gray-700">
              <img
                class="rounded-t-lg"
                src={data.logo ? data.logo : ""}
                alt=""
              />
            <div class="p-5">
                <h5 class="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {data.name}
                </h5>
                <p className="dark:text-gray-400 text-sm mb-2">Since {moment(data.businessSince).format('ll')}</p>
              <p class="mb-3 text-sm text-gray-700 dark:text-gray-400">
                {data.about}
              </p>
              <div className="">
                <h1 className="dark:text-white font-semibold mb-2">Headquarter Office</h1>
                <p className="dark:text-gray-400 text-sm">{data.headquarter ? data.headquarter : "Not Mentioned"}</p>
              </div>
              <div className="">
                <h1 className="dark:text-white font-semibold mb-2 mt-3">Operating Cities:</h1>
                <div className="flex gap-2">
                  {data.operatingCities.map((city) => {
                    return (
                      <>
                        <p className="dark:text-gray-400 text-sm">{city.value}</p>
                      </>
                    );
                  })}
                </div>
              </div>

              {/* <Link to={`/builder/${data._id}`}
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Know About Builder
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MiniBuilderProfile;
