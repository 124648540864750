import React from 'react'
import AllBuildersContainer from '../../../../components/Dashboard/Admin/AllBuilders/AllBuildersContainer';

const AllBuilders = () => {
  return (
    <>
      <AllBuildersContainer />
    </>
  )
}

export default AllBuilders;