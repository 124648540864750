import React, { useEffect } from "react";
import { FaDollyFlatbed, FaExpand, FaShower, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PriceConvert } from "../../../../utils/PriceConvert";
import { BaseSetting } from "../../../../utils/settings";

const FeaturedProperties = ({ data, isLoading }) => {
  const featuredProperties =
    data.length > 0 && data.filter((item) => item.featured === true);
  console.log(featuredProperties);

  useEffect(() => {}, []);

  return (
    <>
      <div className=" mx-auto dark:text-white dark:bg-gray-800 pt-10 md:pt-12">
        <div className="flex items-center justify-center text-center ">
          <div className="">
            <h1 className="text-3xl text-stone-800 mb-5 font-semibold dark:text-white">
              Featured Properties
            </h1>
            <p className="text-para flex justify-start gap-2 items-center text-sm md:px-52 dark:text-white">
              These are the featured properties in your area, trending{" "}
              <FaStar className="ml-3 text-2xl text-yellow-300" />
            </p>
          </div>
        </div>
        <div className="flex container mx-auto mt-5 md:mt-10">
          <div className="drop-shadow-md mx-auto flex flex-wrap justify-between gap-14 px-10 py-9">
            {data.length > 0 &&
              featuredProperties.slice(0, 5).map((item, index) => {
                return (
                  <>
                    <Link
                      className="flex-1"
                      key={item.id}
                      to={`/single-property/${item._id}`}
                    >
                      <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <a href="#">
                          <img
                            class="rounded-t-lg hoverTransition"
                            src={`${BaseSetting.ApiDomain}/file/images/${item.images[0]}`}
                            alt="product"
                          />
                        </a>
                        <div class="px-5 pb-5 pt-4">
                          <a href="#">
                            <h5 class="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                              {item.name}
                            </h5>
                          </a>
                          <div class="flex items-center mt-2.5 mb-5 w-full">
                            <svg
                              aria-hidden="true"
                              class="w-5 h-5 text-yellow-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>First star</title>
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <svg
                              aria-hidden="true"
                              class="w-5 h-5 text-yellow-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Second star</title>
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <svg
                              aria-hidden="true"
                              class="w-5 h-5 text-yellow-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Third star</title>
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <svg
                              aria-hidden="true"
                              class="w-5 h-5 text-yellow-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Fourth star</title>
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <svg
                              aria-hidden="true"
                              class="w-5 h-5 text-yellow-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Fifth star</title>
                              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                              {item.type}
                            </span>
                          </div>
                          <div class="flex items-center justify-between">
                            <span class="text-2xl font-bold text-gray-900 dark:text-white">
                              {PriceConvert(item.priceDetails?.expectedPrice)}
                            </span>
                            <a
                              href="#"
                              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              View
                            </a>
                          </div>
                          <div className="flex md:flex-wrap flex-nowrap gap-5 mt-3">
                            <p className="flex items-center gap-1 text-para text-xs">
                              <FaDollyFlatbed className="text-base" />{" "}
                              <span className="text-sm">{item.bhkType}</span>
                            </p>
                            <p className="flex items-center gap-1 text-para text-xs">
                              <FaShower className="text-base" />
                              <span className="text-sm">
                                {item.details?.bathroom}
                              </span>
                            </p>
                            <p className="flex items-center gap-1 text-para text-xs">
                              <FaExpand className="text-base" />
                              <span className="text-sm">
                                {item?.superBuiltupArea}{" "}
                                <span className="text-xs">sqft2</span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedProperties;
