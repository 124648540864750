import React, { useEffect, useState, createContext } from 'react';

export const AdvanceSearchContext = createContext();

export const AdvanceSearchProvider = ({ initialData, children }) => {
    const [searchData, setSearchData] = useState([]);
    return (
      <AdvanceSearchContext.Provider value={{ searchData, setSearchData }}>
        {children}
      </AdvanceSearchContext.Provider>
    );
  };