import React, { useEffect, useState } from "react";
import AdminApi from "../../../api/AdminApi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Loading from "../../../utils/Loading";
import Slider from "../SingleProperty/Slider";
import Breadcrumb from "../../common/Breadcrumb";
import { Link } from "react-router-dom";
import { HiLocationMarker } from "react-icons/hi";
import { Button } from "flowbite-react";
import { BsCalendar, BsDownload, BsPrinter, BsShare } from "react-icons/bs";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import MiniFeaturedProperties from "../PropertyViewTypes/MiniFeaturedProperties/MiniFeaturedProperties";
import MapWithOptions from "../SingleProperty/MapWithOptions";
import moment from "moment";
import { GiTvTower } from "react-icons/gi";
import { IoHomeSharp } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import Features from "../SingleProperty/Features";
import PropertiesByProject from "../PropertyViewTypes/PropertiesByProject/PropertiesByProject";
import MiniBuilderProfile from "../PropertyViewTypes/MiniBuilderProfile/MiniBuilderProfile";

const SingleProjectContainer = () => {
  const [shareShow, setShareShow] = useState(false);
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop();
  console.log(lastPart);
  const Auth = useSelector((state) => state.auth.authData);
  const [data, setData] = useState(false);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const getProjectById = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getProject(lastPart, Auth.token);
        if (response) {
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getProjectById();
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <div className="flex mt-12 mx-auto items-center justify-center">
            <Loading />{" "}
            <h1 className="loading ml-2 dark:text-white">Loading</h1>
          </div>
        </>
      ) : (
        <>
          <div className="mt-3">
            {err.show ? (
              <div className="mt-2 text-[#da232aff] text-sm">
                {err.error.message ? err.error.message : err.error}
              </div>
            ) : (
              ""
            )}
          </div>
          {data && (
            <div className="single-property-main-container">
              <Slider data={data} />
              <div className="md:max-w-5xl mt-6 mx-auto">
                <div className="main-details-div">
                  {/*
                   ********** Heading section of property with share buttons
                   */}
                  <div className="headings-section mb-0 md:mb-9 p-6 pt-0 md:p-0">
                    <Breadcrumb />
                    <div className="block md:flex justify-between items-center">
                      <div className="block title flex-1">
                        <h1 className=" dark:text-white text-4xl font-bold">
                          {data.name}
                        </h1>
                        <Link
                          to={"/google-map-link"}
                          className="flex gap-2 mt-3 hover:text-mainBlue dark:text-white"
                        >
                          <HiLocationMarker className="relative top-1" />
                          <p>
                            {data.localityDetails.street},{" "}
                            {data.localityDetails.area},{" "}
                            {data.localityDetails.city},{" "}
                            {data.localityDetails.pincode}
                          </p>
                        </Link>
                      </div>
                      <div className="price">
                        <h1 className="mt-4 md:m-0 text-xl md:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                          {/* ₹ {PriceConvert(data.priceDetails.expectedPrice)} */}
                        </h1>
                        <div className="widgets flex gap-2 mt-3">
                          <Button
                            pill={true}
                            size={"sm"}
                            color={"gray"}
                            onClick={() => setShareShow(!shareShow)}
                          >
                            <BsShare className="mr-2" />
                            Share
                          </Button>

                          <Button
                            pill={true}
                            size={"sm"}
                            color={"gray"}
                            onClick={() => window.print()}
                          >
                            <BsPrinter className="mr-2" />
                            Print
                          </Button>
                        </div>
                        {shareShow ? (
                          <>
                            <div className="flex gap-2 mt-3">
                              <div>
                                <FacebookShareButton
                                  url={"https://www.moveintoday.com"}
                                  quote={"Dummy text!"}
                                  hashtag="#muo"
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                              </div>
                              <div>
                                <WhatsappShareButton
                                  url={"https://www.moveintoday.com"}
                                  quote={"Dummy text!"}
                                  hashtag="#muo"
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="mt-3">
                          {err.show && err.error !== undefined ? (
                            <div className="mt-2 text-[#da232aff] text-sm">
                              {err.error.message
                                ? "Invalid Property Id"
                                : err.error}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="details-section">
                    <div className="md:flex justify-between items-start flex-nowrap">
                      <div className="w-full md:w-2/3 mr-6">
                        <div className="overview">
                          <div className="overview shadow-defaultShadow p-6 mb-8 dark:bg-gray-800">
                            <h1 className="text-lg hover:to-blue-500 mb-3 font-semibold dark:text-white">
                              Overview
                            </h1>
                            <div className="short-desc flex flex-wrap md:flex-nowrap gap-2 justify-between text-sm items-end dark:text-white">
                              <div className="flex md:block">
                                <h5 className="font-semibold mb-1 mr-2">
                                  Available Date
                                </h5>
                                <p className="flex items-baseline gap-2">
                                  <BsCalendar />{" "}
                                  {moment(data.launchedDate).format("ll")}
                                </p>
                              </div>
                              <div className="flex md:block">
                                <h5 className="font-semibold mb-1 mr-2">
                                  Total Units
                                </h5>
                                <p className="flex items-baseline gap-2">
                                  <IoHomeSharp />
                                  {data.totalUnits}
                                </p>
                              </div>
                              <div className="flex md:block">
                                <h5 className="font-semibold mb-1 mr-2">
                                  Total Towers
                                </h5>
                                <p className="flex items-baseline gap-2">
                                  <GiTvTower /> {data.totalTowers} Towers
                                </p>
                              </div>
                              <div className="flex md:block">
                                <h5 className="mr-2 font-semibold mb-1">
                                  Rera Registration Number
                                </h5>
                                <p className="flex items-baseline gap-2">
                                  <CgNotes /> {data.reraRegistration}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
                            <h1 className=" mb-3 font-bold dark:text-white">
                              Description
                            </h1>
                            <p className="text-para text-sm dark:text-white">
                              {data.description}
                            </p>
                            <h1 className="mt-7 mb-1 font-bold dark:text-white">
                              Documents
                            </h1>
                            <div className="downloads">
                              <Link to="#">
                                {" "}
                                <p className="text-para text-sm mb-1 dark:text-white ">
                                  Energetic-Certificate-PDF10{" "}
                                  <BsDownload className="inline" />{" "}
                                </p>
                              </Link>
                              <Link to="#">
                                {" "}
                                <p className="text-para text-sm mb-1 dark:text-white ">
                                  Energetic-Certificate-PDF10{" "}
                                  <BsDownload className="inline" />
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="addressAndDetails">
                          <>
                            <div className="p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
                              <h1 className="mb-5 font-bold dark:text-white">
                                Address
                              </h1>
                              <div className="flex flex-wrap justify-between gap-2">
                                <p className="text-sm w-[32%] font-semibold dark:text-white">
                                  Address:{" "}
                                  <span className=" text-para font-normal dark:text-white">
                                    {data.localityDetails.street}
                                  </span>
                                </p>
                                <p className="text-sm w-[32%] font-semibold dark:text-white">
                                  City:{" "}
                                  <span className=" text-para font-normal dark:text-white">
                                    {data.localityDetails.city}
                                  </span>
                                </p>
                                <p className="text-sm w-[32%] font-semibold dark:text-white">
                                  Area:{" "}
                                  <span className=" text-para font-normal dark:text-white">
                                    {data.localityDetails.area}
                                  </span>
                                </p>
                                <p className="text-sm w-[32%] font-semibold dark:text-white">
                                  State/County:{" "}
                                  <span className=" text-para font-normal dark:text-white">
                                    {data.localityDetails.state}
                                  </span>
                                </p>
                                <p className="text-sm w-[32%] font-semibold dark:text-white">
                                  Zip:{" "}
                                  <span className=" text-para font-normal dark:text-white">
                                    {data.localityDetails.pincode}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        </div>
                        <div className="similar properties p-4 md-p-0">
                          <h1 className="text-xl font-bold dark:text-white mb-2">
                            Properties under this Project:
                          </h1>
                          {/* <SimilarProperties dummyData={dummyData} /> */}
                          <PropertiesByProject projectId={lastPart} />
                        </div>
                        <div className="locationOnMap mt-5 p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
                          <h1 className="mb-5 font-bold dark:text-white">
                            Location on Map
                          </h1>
                          <div className="w-full h-128">
                            <MapWithOptions
                              center={{
                                lat: Number(data.localityDetails.lat),
                                lng: Number(data.localityDetails.lng),
                              }}
                              name={data.name}
                              zoom={13}
                            />
                          </div>
                        </div>

                        <Features data={data} />
                      </div>

                      {/*
                       *************** Right Sidebar Starts here *****************
                       */}
                      <div className="flex-1">
                        <div className="mb-4">
                          <MiniBuilderProfile builderId={data.builderId} />
                        </div>
                        <MiniFeaturedProperties projectId={lastPart} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SingleProjectContainer;
