import React, { useEffect, useState } from "react";
import MainFooter from "../../common/MainFooter";
import Stats from "../../common/Stats";
import SubFooter from "../../common/SubFooter";
import Testimonial from "../../common/Testimonial";
import HomeBanner from "./HomeBanner";
import SearchTabs from "../SearchTabs/SearchTabs";
import { useSelector } from "react-redux";
import AdminApi from "../../../api/AdminApi";
import FeaturedProperties from "../PropertyViewTypes/FeaturedProperties/FeaturedProperties";
import TopBarProgress from "react-topbar-progress-indicator";

const HomeContainer = () => {
  const Auth = useSelector((state) => state.auth.authData);
  const [data, setData] = useState("");
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const getAllProperties = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getAllProperties(Auth.token);
        console.log(response);
        if (response.length > 0) {
          setIsLoading(false);
          const approvedOnly = response.filter(
            (item) => item.adminApproval === true
          );
          setData(approvedOnly);
        } else {
          if (response.name === "AxiosError") {
            setErr({
              error: "Axios Error - Network Issue. Please try again",
              show: true,
            });
          } else {
            setErr({ error: response.data.data, show: true });
            setIsLoading(false);
            return false;
          }
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getAllProperties();
  }, []);
  return (
    <>
      {err.show ? (
        <>
          <div className="text-center text-2xl dark:text-white mt-10">
            {" "}
            {err.error ? err.error : err.error.message}
          </div>
        </>
      ) : (
        <>
          {isLoading ? (
            <TopBarProgress barThickness={2} />
          ) : (
            <>
              <HomeBanner />
              <div className="flex -mt-12 items-center mb-20 justify-center ">
                <div className="w-full md:w-3/4">
                  <SearchTabs />
                </div>
              </div>

              <FeaturedProperties data={data} isLoading={isLoading} />
              {/* <PropertiesByArea data={data} isLoading={isLoading}/> */}
              {/* <LatestProperties data={data} isLoading={isLoading} />
              <PropertiesByCategory data={data} isLoading={isLoading} /> */}
              <Stats />
              <Testimonial />
              <SubFooter />
              <MainFooter />
            </>
          )}{" "}
        </>
      )}
    </>
  );
};

export default HomeContainer;
