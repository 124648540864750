import React from 'react'
import AllProjectsContainer from '../../../../components/Dashboard/Admin/AllProjects/AllProjectsContainer';

const AllProjects = () => {
  return (
    <>
        <AllProjectsContainer />
    </>
  )
}

export default AllProjects;