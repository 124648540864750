import React, { useState, useContext, Fragment, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Combobox, Transition } from "@headlessui/react";
import { GoogleAutoCompleteContext } from "../../../contexts/GoogleAutoCompleteData";
import { BsCheck } from "react-icons/bs";
import { CgChevronDown } from "react-icons/cg";
const libraries = ["places"];

const GoogleAutoComplete = ({ center, address }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_ALL_KEY,
    libraries: ["places"],
  });

  if (!isLoaded)
    return (
      <>
        <div>Loading...</div>
      </>
    );
  return <Map center={center} address={address} />;
};

const Map = ({ center, address }) => {
  const { locationData, setLocationData } = useContext(
    GoogleAutoCompleteContext
  );
  center =
    center !== undefined && center !== ""
      ? center
      : { lat: 26.9124336, lng: 75.7872709 };
  const [selected, setSelected] = useState(null); //{lat: Number, lng: Number}
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    setSelected(center);
  }, []);

  const handleMapClick = (event) => {
    // Extract latitude and longitude from the click event
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setLocationData({
      coord: { lat, lng },
      address: locationData.address,
    });
    console.log(lat, lng, "dd");
    // Set the selected location based on the click event
    setSelected({ lat, lng });
  };

  return (
    <>
      <div className="places-container">
        <PlaceAutoComplete
          setSelected={setSelected}
          center={center}
          address={address}
        />
      </div>

      <GoogleMap
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_ALL_KEY,
          libraries: ["places"],
          id: "google-map-script",
        }}
        zoom={13}
        center={center}
        mapContainerClassName="map-container"
        mapContainerStyle={containerStyle}
        onClick={handleMapClick}
      >
        {selected && (
          <Marker
            position={selected !== null ? selected : center}
            draggable
            onDragEnd={handleMapClick}
          />
        )}
      </GoogleMap>
    </>
  );
};

const PlaceAutoComplete = ({ setSelected, center, address }) => {
  const { locationData, setLocationData } = useContext(
    GoogleAutoCompleteContext
  );
  console.log(locationData);
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    setLocationData({
      coord: { lat, lng },
      address: results,
    });
  };
  return (
    <>
      <>
        <div className="my-3 z-50">
          <Combobox onChange={handleSelect}>
            <div className="relative mt-1">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  displayValue={(value) => value}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  disabled={!ready}
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 h-12"
                  placeholder="Type your project google map location"
                  name="googlelocation"
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <CgChevronDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                // afterLeave={() => setQuery("")}
              >
                <Combobox.Options
                  hold={true}
                  className="z-auto mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {status === "OK" &&
                    data.map(({ place_id, description }) => (
                      <Combobox.Option
                        key={place_id}
                        value={description}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? "bg-teal-600 text-white" : "text-gray-900"
                          }`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {description}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <BsCheck
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </>

      {/* <Combobox onChange={handleSelect}>
        <Combobox.Input
          displayValue={(value) => value}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="block mb-5 w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
          placeholder="Type your project google map location"
          name="googlelocation"
          // as="..."
        />
        <Combobox.Options hold={true}>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <Combobox.Option key={place_id} value={description}>
                {description}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </Combobox> */}
    </>
  );
};

export default GoogleAutoComplete;
