import React from 'react'
import { FaFacebook, FaInstagram, FaMedium, FaTwitter } from 'react-icons/fa';

const SocialIcons = () => {
  return (
    <>
        <div className='flex gap-3 justify-start items-center'>
            <p className='bg-lightGray p-1 rounded-full'>
                <FaFacebook className='text-slate-500 w-5 h-5'/>
            </p>
            <p className='bg-lightGray p-1 rounded-full'>
                <FaTwitter className='text-slate-500 w-5 h-5'/>
            </p>
            <p className='bg-lightGray p-1 rounded-full'>
                <FaInstagram className='text-slate-500 w-5 h-5'/>
            </p>
            <p className='bg-lightGray p-1 rounded-full'>
                <FaMedium className='text-slate-500 w-5 h-5'/>
            </p>
        </div>
    </>
  )
}

export default SocialIcons;