const Locality = [
    {
        value: 'entirecity',
        label: 'Entire City',
    },    
    {
        value: 'malviyanagar',
        label: 'Malviya Nagar',
    },
    {
        value: 'cscheme',
        label: 'C-Scheme',
    },
    {
        value: 'banipark',
        label: 'Banipark',
    },
    {
        value: 'mansarovar',
        label: 'Mansarovar',
    },
];

const PropertyType = [
    {
        value: 'all',
        label: 'All'
    },
    {
        value: 'villa',
        label: 'Villa',
    },
    {
        value: 'flat',
        label: 'Flats',
    },
    {
        value: 'house',
        label: 'Independent House',
    },
    {
        value: 'apartment',
        label: 'Apartments',
    },
];

export {Locality, PropertyType};