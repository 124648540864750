import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { Button } from "flowbite-react";
import Loading from "../../../../utils/Loading";
import moment from "moment";
import AdminApi from "../../../../api/AdminApi";
import { toast } from "react-toastify";

const ListingRequestsContainer = () => {
  const Auth = useSelector((state) => state.auth.authData);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  console.log(selectedRows);
  const { theme, setTheme } = React.useContext(ThemeContext);
  const [data, setData] = useState([]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    async function markListingRequestComplete() {
      if (
        window.confirm(
          `Are you sure you want to complete this listing request and close it?:\r ${selectedRows.map(
            (r) => r._id
          )}?`
        )
      ) {
        try {
          if (navigator.onLine) {
            setErr({
              error: "",
              show: false,
            });
            const response = await AdminApi.listingRequestComplete(
              selectedRows[0]._id,
              Auth.token
            );
            if (response) {
              toast("Success: Listing Request Closed!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setIsLoading(false);
              setToggleCleared(!toggleCleared);
              setSelectedRows([]);
              //   setData(differenceBy(data, selectedRows, "_id"));
              getAllListings();
            } else {
              setErr({ error: response.data.data, show: true });
              setIsLoading(false);
              return false;
            }
          } else {
            alert("You`re offline");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Error loading data");
        }
      }
    }

    return (
      <Button
        key="request"
        onClick={markListingRequestComplete}
        color={"purple"}
        // style={{ backgroundColor: "red" }}
        icon
      >
        Close Request, Mark Complete!
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);

  // createTheme creates a new theme named solarized that overrides the build in dark theme
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "#2aa198",
      },
      background: {
        default: "rgb(31 41 55 / 1)",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "rgb(55 65 81/1)",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  // A super simple expandable component.
  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const columns = [
    {
      name: "User Name",
      sortable: true,
      selector: (row) => (row.userId ? row.userId.name : "No User ID Found"),
    },
    {
      name: "User Email",
      sortable: true,
      selector: (row) => (row.userId ? row.userId.email : "No Email Found"),
    },
    {
      name: "User Mobile",
      sortable: true,
      selector: (row) => (row.userId ? row.userId.mobile : "No Mobile Found"),
    },
    {
      name: "Completed",
      sortable: true,
      selector: (row) => String(row.completed),
    },
    {
      name: "Listing Id",
      sortable: true,
      selector: (row) => row._id,
    },

    {
      name: "Create On",
      sortable: true,
      selector: (row) => moment(row.createdAt).format("ll"),
    },

    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <>
    //       <div className="flex gap-2">
    //         <Link
    //           className="flex gap-2"
    //           to={`/dashboard/reseller/upload-property/${row._id}`}
    //         >
    //           <Button color={"light"} size={"sm"}>
    //             <MdEdit className="mr-1" />
    //             Edit
    //           </Button>
    //         </Link>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  const getAllListings = async () => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getAllListings(Auth.token); // need change
        console.log(response);
        if (response) {
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getAllListings();
  }, []);

  const rowDisabledCriteria = (row) => row.completed === true;

  const filteredItems = data
    ? data.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : "";

  //   const subHeaderComponentMemo = React.useMemo(() => {
  //     const handleClear = () => {
  //       if (filterText) {
  //         setResetPaginationToggle(!resetPaginationToggle);
  //         setFilterText("");
  //       }
  //     };

  //     return (
  //       <>
  //         <TextInput
  //           id="username"
  //           type="text"
  //           placeholder="Search Properties"
  //           required={true}
  //           onChange={(e) => setFilterText(e.target.value)}
  //           onClear={handleClear}
  //           filterText={filterText}
  //         />
  //       </>
  //     );
  //   }, [filterText, resetPaginationToggle]);

  const downloadCSV = () => {
    console.log("clicked");
  };

  return (
    <div className="users-list">
      <h1 className="dark:text-white text-2xl font-semibold mb-2">
        Listing Requests
      </h1>
      <p className="dark:text-para text-xs mb-4">
        These are the users who need help to upload their properties. Contact
        them to resolve their issues.
      </p>
      {!isLoading ? (
        <>
          <div className="afterLoad">
            <div className="users-option flex justify-between items-center mb-6">
              <div className="left flex justify-between items-center">
                {/* <div className="search-box w-96 border-r border-darkBorder pr-4">
                  {subHeaderComponentMemo}
                </div> */}
                {selectedRows.length > 0 ? (
                  <>
                    <div className="flex ml-3">{contextActions}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="data-table">
              <div className="data-loaded">
                {data.length > 0 ? (
                  <DataTable
                    title=""
                    columns={columns}
                    data={data}
                    pagination
                    // paginationResetDefaultPage={resetPaginationToggle}
                    customStyles={customStyles}
                    // subHeader
                    // subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    selectableRows
                    selectableRowsSingle={true}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    theme={theme}
                    className={"font-medium"}
                    selectableRowDisabled={rowDisabledCriteria}
                    // defaultSortFieldId={1}
                    contextActions={contextActions}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    fixedHeader
                    responsive={true}
                  />
                ) : (
                  <>
                    <h1 className="dark:text-white">
                      No Listing Requests so far
                    </h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-center h-full">
            <h1 className="loading text-2xl dark:text-white block">Loading</h1>
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default ListingRequestsContainer;
