import { Button, Card, Modal, Tooltip } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { FaDollyFlatbed, FaExpand, FaShower } from "react-icons/fa";
import { HiHeart, HiOutlineExclamationCircle } from "react-icons/hi";
import { IoLocation } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ResellerApi from "../../../api/ResellerApi";
import AdminApi from "../../../api/AdminApi";
import { toast } from "react-toastify";
import Loading from "../../../utils/Loading";
import { BaseSetting } from "../../../utils/settings";

const WishlistComp = () => {
  const Auth = useSelector((state) => state.auth.authData);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopUpActive, setIsPopUpActive] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState("");
  const dummyData = [
    {
      id: 0,
      propertyAreaImage: "https://flowbite.com/docs/images/blog/image-1.jpg",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 1,
      propertyAreaImage:
        "https://wpresidence.net/wp-content/uploads/2021/08/jersey_city.jpg",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 2,
      propertyAreaImage:
        "https://wpresidence.net/wp-content/uploads/2021/08/jersey_city.jpg",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 3,
      propertyAreaImage:
        "https://wpresidence.net/wp-content/uploads/2021/08/jersey_city.jpg",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 4,
      propertyAreaImage:
        "https://wpresidence.net/wp-content/uploads/2021/08/jersey_city.jpg",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 5,
      propertyAreaImage:
        "https://wpresidence.net/wp-content/uploads/2021/08/jersey_city.jpg",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
  ];
  const [propertyData, setPropertyData] = useState([]);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const getWishlist = async () => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        const response = await ResellerApi.getFavourite(Auth.token);
        if (response) {
          async function fetchPokemons() {
            const promises = [];
            for (const element of response) {
              const result = AdminApi.getProperty(
                element.propertyId,
                Auth.token
              );
              promises.push(result);
            }
            const results = await Promise.all(promises);
            setPropertyData(results);
          }
          fetchPokemons();
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  const removeFavourite = async (selected) => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        setIsLoading(true);
        const response = await ResellerApi.removeFavourite(
          selected,
          Auth.token
        );
        console.log(response);
        if (response.status === 1) {
          console.log(propertyData);
          const returnArr = propertyData.filter(
            (item) => item._id !== selected
          );
          setPropertyData(returnArr);
          toast("Property removed from Wishlist!");
          setIsPopUpActive(() => !isPopUpActive);
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsPopUpActive(() => !isPopUpActive);
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      setIsPopUpActive(() => !isPopUpActive);
      console.log("Error loading data");
    }
  };

  const deletePopup = (id) => {
    console.log(id);
    setSelected(id);
    setIsPopUpActive(() => !isPopUpActive);
  };

  const onClose = () => {
    setIsPopUpActive(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getWishlist();
  }, []);

  return (
    <>
      <h1 className="dark:text-white text-2xl font-semibold flex items-center">
        <span>Wishlist Properties</span>{" "}
        <HiHeart className="text-pink-500 ml-2" />
      </h1>
      <div className="flex lg:flex-row flex-col gap-4 justify-between mt-5">
        <div className="flex justify-between w-full flex-wrap gap-4">
          {isLoading ? (
            <>
              <div className="loading dark:text-white">
                Loading <Loading />
              </div>
            </>
          ) : (
            <>
              {propertyData.length > 0 ? (
                propertyData.map((index) => {
                  return (
                    <div className="w-auto flex-1 justify-between w-[32%] shadow-defaultShadow">
                      <Card
                        imgAlt="Meaningful alt text for an image that is not purely decorative"
                        imgSrc={`${BaseSetting.ApiDomain}/file/images/${index.images[0]}`}
                        className=""
                      >
                        <Link to={`/single-property/${index._id}`}>
                          <p className="text-para flex gap-1 items-center">
                            <IoLocation /> {index.localityDetails.city}
                          </p>

                          <h3 className="mb-1 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {index.name}
                          </h3>
                          <h3 className="text-blue-700 mb-1">
                            ₹ {`${index.priceDetails.expectedPrice}`}
                          </h3>
                          <p className="text-sm text-para dark:text-gray-400 mb-2">
                            {index.description}
                          </p>
                        </Link>
                        <div className="flex ">
                          <div className="flex gap-4 flex-1">
                            <p className="flex items-center gap-1 text-para text-xs">
                              <FaDollyFlatbed /> <span>{index.bhkType}</span>
                            </p>
                            <p className="flex items-center gap-1 text-para text-xs">
                              <FaShower /> {index.details.bathroom}
                            </p>
                            <p className="flex items-center gap-1 text-para text-xs">
                              <FaExpand /> {index.builtUpArea} ft2
                            </p>
                          </div>
                          {!isLoading && (
                            <div className="flex-1 justify-end flex">
                              <Tooltip
                                content="Remove Favourite?"
                                placement="bottom"
                              >
                                <Button
                                  color={"gray"}
                                  size={"sm"}
                                  onClick={() => deletePopup(index._id)}
                                >
                                  <MdDelete />
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  );
                })
              ) : (
                <>
                  <h1 className="dark:text-white">
                    You haven't added any property to your Wishlist.
                  </h1>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        show={isPopUpActive}
        size="md"
        popup={isPopUpActive}
        onClose={onClose}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to remove this property from your
              Favourites?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => removeFavourite(selected)}>
                {isLoading ? <Loading /> : ""} Yes, I'm sure
              </Button>
              <Button color="gray" onClick={onClose}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WishlistComp;
