import React, { useEffect, useState } from "react";
import AdminApi from "../../../api/AdminApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../../utils/Loading";

const LeadsButton = ({ propertyId }) => {
  const AuthData = useSelector((state) => state.auth.authData);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const navigate = useNavigate();

  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const createLeadForProperty = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.createLead(propertyId, AuthData.token);
        console.log(response);
        if (response) {
          setIsLoading(false);
          setData(response);
          toast("The owner has been notified for your interest in Property");
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };
  useEffect(() => {
    // createLeadForProperty();
  }, []);
  return (
    <>
      <div
        class="hoverTransition w-full p-4 text-gray-500 border bg-white rounded-lg shadow-defaultShadow dark:bg-gray-800 dark:text-gray-400"
        role="alert"
      >
        <div class="block md:flex">
          <div class="ml-3 text-sm font-normal">
            <h1 class="mb-3 text-base font-semibold text-gray-900 dark:text-white">
              Hi {AuthData ? AuthData.user?.name : `Stranger`}
            </h1>
            <div class="mb-4 text-sm font-normal">
              Thanks for visiting this property, click to get details
            </div>
            <p
              onClick={
                AuthData.isAuthenticated
                  ? createLeadForProperty
                  : () => navigate("/login")
              }
              className="inline-flex px-2.5 py-2.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 hover:cursor-pointer focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
            >
              {isLoading ? <Loading /> : ""}
              {AuthData.isAuthenticated
                ? "Get Property Details"
                : "Login to get details"}
            </p>
          </div>
        </div>
      </div>
      {data && (
        <h1 className="dark:text-white text-xs mt-3">
          You must have received received a text message from MIT Homes team
          with Property Details.
        </h1>
      )}
    </>
  );
};

export default LeadsButton;
