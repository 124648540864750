/**
 * Basic Setting Variables Define
 */
export const BaseSetting = {
    name: 'MIT Homes Real Estate',
    displayName: 'My Admin Panel',
    appVersion: '0.0.2',
    domain: 'moveintoday.in',
    localApiDomain: 'http://localhost:3800/api',
    adminApiDomain: 'https://mit-homes.onrender.com/api/admin',
    resellerApiDomain: 'https://mit-homes.onrender.com/api/reseller',
    userApiDomain: 'https://mit-homes.onrender.com/api/user',
    ApiDomain: 'https://mit-homes.onrender.com/api',
    imageDomain: 'S3 Domain URL',
    protocol: 'https',
    defaultLanguage: 'en',
    languageSupport: ['en', 'khm'],
  };
  