import { del, get, headers, post, put } from "./index.js";
import { BaseSetting } from "../utils/settings.js";
const apis = {

  addImages: async(data, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/file/uploadFiles/', data, {
            ...headers,
            'x-auth-token': token,
            'Content-Type': 'multipart/form-data',
        })
        return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
        return false
    }
  },
     
  getFavourite: async(token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/reseller/whishlist`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  }, 

  addFavourite: async(id, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/reseller/whishlist/addFavourite/' + id, {},{
            ...headers,
            'x-auth-token': token
        })
        return response.data;
    } catch (error) {
        return false
    }
  },

  removeFavourite: async(id, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/reseller/whishlist/removeFavourite/' + id, {},{
          ...headers,
          'x-auth-token': token
        })
        return response.data;
    } catch (error) {
        return false
    }
  },
};

export default apis;
