import React, { useState, useEffect } from "react";
import Select from "react-select";
import stateApis from "../../../../api/statesApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const CityCrudComponent = () => {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [newCityName, setNewCityName] = useState("");
  const auth = useSelector((state) => state.auth.authData);

  useEffect(() => {
    const fetchStates = async () => {
      const statesData = await stateApis.getAllStates(auth?.token);
      if (statesData) {
        setStates(statesData);
      }
    };

    fetchStates();
  }, []);

  const fetchCities = async (stateId) => {
    const citiesData = await stateApis.getCityByState(stateId, auth?.token);
    if (citiesData) {
      setCities(citiesData.cities);
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    fetchCities(selectedOption?.value);
  };

  const handleAddCity = async () => {
    if (!selectedState || !newCityName) return;

    try {
      await stateApis.createCity(newCityName, selectedState.value, auth?.token);
      setNewCityName("");
      toast(`${newCityName} added`);
      fetchCities(selectedState.value);
    } catch (error) {
      console.error("Error adding city", error);
    }
  };

  const handleDeleteCity = async () => {
    if (!selectedState || !selectedCity) return;

    try {
      await stateApis.deleteCity(selectedCity.value, auth?.token);
      setSelectedCity(null);
      setNewCityName("");
      toast(`${selectedCity.label} deleted`);
      fetchCities(selectedState.value);
    } catch (error) {
      console.error("Error deleting city", error);
    }
  };

  return (
    <div className="w-[70%] mx-auto mt-8 p-4 bg-gray-100 rounded shadow">
      <h1 className="text-2xl font-bold mb-4">Add/Delete City</h1>
      <Select
        options={states.map((state) => ({
          value: state._id,
          label: state.name,
        }))}
        value={selectedState}
        onChange={handleStateChange}
        isClearable
        className="mb-4"
      />
      <Select
        options={cities.map((city) => ({
          value: city._id,
          label: city.name,
        }))}
        value={selectedCity}
        onChange={(value) => setSelectedCity(value)}
        isClearable
        className="mb-4"
      />
      <div className="flex space-x-4">
        <input
          type="text"
          placeholder="New City Name"
          value={newCityName}
          onChange={(e) => setNewCityName(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
        <button
          onClick={handleAddCity}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add
        </button>
        <button
          onClick={handleDeleteCity}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default CityCrudComponent;
