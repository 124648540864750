import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { Badge, Button, Dropdown, TextInput, Tooltip } from "flowbite-react";
import { Link } from "react-router-dom";
import { FaDownload, FaPlus } from "react-icons/fa";
import { CSVLink } from "react-csv";
import Loading from "../../../../utils/Loading";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import AdminApi from "../../../../api/AdminApi";
import { differenceBy } from "lodash";
import { toast } from "react-toastify";
import { HiCheck } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { BsEye, BsStarFill, BsStarHalf } from "react-icons/bs";
import { Role } from "../../../../state/interface";

const AllPropertiesContainer = () => {
  const Auth = useSelector((state) => state.auth.authData);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  console.log(selectedRows);
  const { theme, setTheme } = React.useContext(ThemeContext);
  const [data, setData] = useState([]);
  const [filteredArr, setFilteredArr] = useState([]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    async function handleDelete() {
      if (
        window.confirm(
          `Are you sure you want to delete Property:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        try {
          if (navigator.onLine) {
            setErr({
              error: "",
              show: false,
            });
            const response = await AdminApi.deleteProperty(
              selectedRows[0]._id,
              Auth.token
            );
            if (response) {
              toast("Success: Property Deleted!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setIsLoading(false);
              setToggleCleared(!toggleCleared);
              setSelectedRows([]);
              setData(differenceBy(data, selectedRows, "name"));
            } else {
              setErr({ error: response.data.data, show: true });
              setIsLoading(false);
              return false;
            }
          } else {
            alert("You`re offline");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Error loading data");
        }
      }
    }
    async function handleApprove(boolean) {
      if (
        window.confirm(
          `Are you sure you want to ${
            boolean ? `approve` : `disapprove`
          } this Property:\r ${selectedRows.map((r) => r.name)}?`
        )
      ) {
        try {
          if (navigator.onLine) {
            setErr({
              error: "",
              show: false,
            });
            setIsLoading(true);
            const response = await AdminApi.updateProperty(
              selectedRows[0]._id,
              { adminApproval: boolean },
              Auth.token
            );
            if (response) {
              toast(
                `Success: Property ${
                  boolean ? `Approved!` : `Disapproved`
                }! It will be ${
                  boolean ? `visible` : `not be visible`
                } on the website now.`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setIsLoading(false);
              setToggleCleared(!toggleCleared);
              setSelectedRows([]);
              // setData(differenceBy(data, selectedRows, "adminApproval"));
              getAllProperties();
            } else {
              setErr({ error: response.data.data, show: true });
              setIsLoading(false);
              return false;
            }
            setIsLoading(false);
          } else {
            alert("You`re offline");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Error loading data");
        }
      }
    }
    async function handleFeatured(boolean) {
      if (
        window.confirm(
          `Are you sure you want to ${
            boolean ? ` feature ` : ` remove from featured list`
          } this Property:\r ${selectedRows.map((r) => r.name)}?`
        )
      ) {
        try {
          if (navigator.onLine) {
            setErr({
              error: "",
              show: false,
            });
            setIsLoading(true);
            const response = await AdminApi.updateProperty(
              selectedRows[0]._id,
              { featured: boolean },
              Auth.token
            );
            if (response) {
              toast(
                `Success: Property ${
                  boolean
                    ? ` is featured now!`
                    : ` is removed from Featured list!`
                }`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setIsLoading(false);
              setToggleCleared(!toggleCleared);
              setSelectedRows([]);
              // setData(differenceBy(data, selectedRows, "adminApproval"));
              getAllProperties();
            } else {
              setErr({ error: response.data.data, show: true });
              setIsLoading(false);
              return false;
            }
            setIsLoading(false);
          } else {
            alert("You`re offline");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Error loading data");
        }
      }
    }

    const buyOrRent = (e) => {
      if (e.target.value === "All") {
        setFilteredArr(data);
      } else {
        const filteredData = data.filter((item) => {
          // return e.target.value.includes(item.availableType)
          return item.availableType.includes(e.target.value);
        });
        setFilteredArr(filteredData);
      }
    };

    // const featuredOrNot = (e) => {
    //   console.log(e.target.value);
    //   if (e.target.value === "All") {
    //     setFilteredArr(data);
    //   } else if (e.target.value === true) {
    //     const filteredData = data.filter((item) => {
    //       // return e.target.value.includes(item.availableType)
    //       return item.featured === true;
    //     });
    //     setFilteredArr(filteredData);
    //   } else if (e.target.value === false) {
    //     const filteredData = data.filter((item) => {
    //       // return e.target.value.includes(item.availableType)
    //       return item.featured === false;
    //     });
    //     console.log(filteredData);
    //     setFilteredArr(filteredData);
    //   }
    // };
    const featuredOrNot = (e) => {
      console.log(e.target.value);
      let filteredData;

      if (e.target.value === "All") {
        setFilteredArr(data);
      } else {
        filteredData = data.filter(
          (item) => item.featured === (e.target.value === "true" ? true : false)
        );
        console.log(filteredData);
        setFilteredArr(filteredData);
      }
    };

    const approvedOrNot = (e) => {
      console.log(e.target.value);
      let filteredData;

      if (e.target.value === "All") {
        setFilteredArr(data);
      } else {
        filteredData = data.filter(
          (item) =>
            item.adminApproval === (e.target.value === "true" ? true : false)
        );
        console.log(filteredData);
        setFilteredArr(filteredData);
      }
    };

    return (
      <>
        <div className="flex gap-2 items-center">
          {selectedRows.length > 0 ? (
            selectedRows.map((r) => {
              console.log(r);
              return (
                <div className="flex gap-2 items-center">
                  <Button
                    key="delete"
                    onClick={handleDelete}
                    style={{ backgroundColor: "red" }}
                    icon
                  >
                    Delete
                  </Button>
                  {Auth.user.role === Role.ADMIN && (
                    <>
                      <Button
                        key="approve"
                        onClick={() => handleApprove(!r.adminApproval)}
                        color={"dark"}
                        icon
                      >
                        {isLoading ? <Loading /> : ""}
                        {r.adminApproval
                          ? `Disapprove Property`
                          : `Approve Property`}
                      </Button>
                      <Tooltip
                        content={`${
                          r.featured
                            ? `Remove from featured properties`
                            : `Add to featured properties`
                        }`}
                      >
                        <Button
                          key="featured"
                          onClick={() => handleFeatured(!r.featured)}
                          color={"dark"}
                          icon
                        >
                          {isLoading ? <Loading /> : ""}
                          {r.featured ? <BsStarHalf /> : <BsStarFill />}
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <>
              <div className="flex gap-2 items-center">
                <Dropdown
                  label="Buy/Rent"
                  placement="auto"
                  title="Buy or Rent"
                  dismissOnClick={true}
                  pill={true}
                  size={"xs"}
                >
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="buy"
                        type="radio"
                        value="Sale"
                        name="filterBuyorRent"
                        onClick={buyOrRent}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="buy"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        Buy
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="rent"
                        type="radio"
                        value="Rent"
                        name="filterBuyorRent"
                        onClick={buyOrRent}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="rent"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        Rent
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="all"
                        type="radio"
                        value="All"
                        name="filterBuyorRent"
                        onClick={buyOrRent}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="buy"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        All
                      </label>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
                <Dropdown
                  label="Featured"
                  placement="auto"
                  title="Featured or Not"
                  dismissOnClick={true}
                  pill={true}
                  size={"xs"}
                >
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="featured"
                        type="radio"
                        value={true}
                        name="featuredOrNot"
                        onClick={featuredOrNot}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="buy"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        Featured
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="notFeatured"
                        type="radio"
                        value={false}
                        name="featuredOrNot"
                        onClick={featuredOrNot}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="notFeatured"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        Not Featured
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="all"
                        type="radio"
                        value="All"
                        name="featuredOrNot"
                        onClick={featuredOrNot}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="all"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        All
                      </label>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
                <Dropdown
                  label="Approved"
                  placement="auto"
                  title="Approved or not"
                  dismissOnClick={true}
                  pill={true}
                  size={"xs"}
                >
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="approved"
                        type="radio"
                        value={true}
                        name="approvedOrNot"
                        onClick={approvedOrNot}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="buy"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        Approved
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="notApproved"
                        type="radio"
                        value={false}
                        name="approvedOrNot"
                        onClick={approvedOrNot}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="notFeatured"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        Not Approved
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                      <input
                        id="all"
                        type="radio"
                        value="All"
                        name="approvedOrNot"
                        onClick={approvedOrNot}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="all"
                        class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        All
                      </label>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </>
    );
  }, [data, selectedRows, toggleCleared]);

  // createTheme creates a new theme named solarized that overrides the build in dark theme
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "#2aa198",
      },
      background: {
        default: "rgb(31 41 55 / 1)",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "rgb(55 65 81/1)",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  // A super simple expandable component.
  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const columns = [
    {
      name: "Property Name",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Type",
      sortable: true,
      selector: (row) => row.type,
    },
    {
      name: "City",
      sortable: true,
      selector: (row) => row.localityDetails.city,
    },
    {
      name: "Ownership",
      sortable: true,
      selector: (row) => row.availableType,
    },
    {
      name: "Price",
      sortable: true,
      selector: (row) => row.priceDetails.expectedPrice,
    },
    {
      name: "Carpet Area",
      sortable: true,
      selector: (row) => row.carpetArea,
    },
    {
      name: "Approved",
      sortable: true,
      selector: (row) => (
        <>
          <div className="flex items-center gap-2">
            <p className="dark:text-white">
              {row.adminApproval === true ? "Yes" : "No"}
            </p>
            <Tooltip
              content={
                row.adminApproval === true
                  ? "Property is verified by Admin"
                  : "Property is not verified by Admin"
              }
            >
              <Badge
                color={row.adminApproval === true ? "success" : "failure"}
                size="sm"
                icon={row.adminApproval === true ? HiCheck : CgClose}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
    {
      name: "Create On",
      sortable: true,
      selector: (row) => moment(row.createdAt).format("ll"),
    },

    {
      name: "Actions",
      grow: 2,
      selector: (row) => (
        <>
          <div className="flex flex-nowrap gap-2 items-center">
            <div className="flex-1">
              <Tooltip content="View Property">
                <Link className="flex gap-2" to={`/single-property/${row._id}`}>
                  <Button color={"light"} size={"sm"}>
                    <BsEye className="mr-1" />
                  </Button>
                </Link>
              </Tooltip>
            </div>
            <div className="flex-1">
              <Tooltip content="Edit Property">
                <Link
                  className="flex gap-2"
                  to={`/dashboard/reseller/upload-property/${row._id}`}
                >
                  <Button color={"light"} size={"sm"}>
                    <MdEdit className="mr-1" />
                    Edit
                  </Button>
                </Link>
              </Tooltip>
            </div>
          </div>
        </>
      ),
    },
  ];

  const getAllProperties = async () => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getAllProperties(Auth.token);
        console.log(response);
        if (response) {
          setIsLoading(false);
          const filteredItems = response.filter(
            (item) =>
              item.name &&
              item.name.toLowerCase().includes(filterText.toLowerCase())
          );
          setData(filteredItems);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getAllProperties();
  }, []);

  // const rowDisabledCriteria = (row) => row.type === "Admin";

  // const filteredItems = data
  //   ? data.filter(
  //       (item) =>
  //         item.name &&
  //         item.name.toLowerCase().includes(filterText.toLowerCase())
  //     )
  //   : "";

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextInput
          id="username"
          type="text"
          placeholder="Search Properties"
          required={true}
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const downloadCSV = () => {
    console.log("clicked");
  };

  return (
    <div className="users-list">
      <h1 className="dark:text-white text-2xl font-semibold mb-4">
        All Properties
      </h1>
      {!isLoading ? (
        <>
          <div className="afterLoad">
            <div className="users-option flex justify-between items-center mb-6">
              <div className="left flex justify-between items-center">
                <div className="search-box w-96 border-r border-gray-400 pr-4">
                  {subHeaderComponentMemo}
                </div>
                {selectedRows.length > 0 ? (
                  <>
                    <div className="flex ml-3">{contextActions}</div>
                  </>
                ) : (
                  <div className="flex ml-3">{contextActions}</div>
                )}
              </div>

              <div className="right flex gap-3">
                <Link to={"/dashboard/reseller/upload-property/new"}>
                  <Button color={"info"}>
                    <FaPlus className="mr-2 font-bold" />
                    Add Property
                  </Button>
                </Link>
                <CSVLink
                  data={data}
                  filename={"my-file.csv"}
                  className="btn btn-primary"
                  target="_blank"
                >
                  <Button color={"gray"}>
                    <FaDownload className="mr-2 font-bold" />
                    Export Prperties
                  </Button>
                </CSVLink>
              </div>
            </div>

            <div className="data-table">
              <div className="data-loaded">
                <DataTable
                  title=""
                  columns={columns}
                  data={filteredArr.length > 0 ? filteredArr : data}
                  pagination
                  // paginationResetDefaultPage={resetPaginationToggle}
                  customStyles={customStyles}
                  // subHeader
                  // subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  selectableRows
                  selectableRowsSingle={true}
                  // expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  theme={theme}
                  className={"font-medium"}
                  // selectableRowDisabled={rowDisabledCriteria}
                  // defaultSortFieldId={1}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  fixedHeader
                  responsive={true}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-center h-full">
            <h1 className="loading text-2xl dark:text-white block">Loading</h1>
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default AllPropertiesContainer;
