import { del, get, headers, post } from "./index.js";
import { BaseSetting } from "../utils/settings.js";

const stateApis = {
  getAllStates: async (token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + "/state/", {
        ...headers,
        "x-auth-token": token,
      });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },

  getCityByState: async (stateId, token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/state/${stateId}`, {
        ...headers,
        "x-auth-token": token,
      });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },

  getCityByName: async (cityName) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/city/getByName/${cityName}`, {
        ...headers,
      });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },

  getLocalityByCity: async (cityId, token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/city/${cityId}`, {
        ...headers,
        "x-auth-token": token,
      });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },
  getLocalityByCityId: async (cityId, token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/city/getByCity${cityId}`, {
        ...headers,
        // "x-auth-token": token,
      });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },
  getAllCities: async () => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/city`, {
        ...headers,
        // "x-auth-token": token,
      });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },
  createLocality: async (locality, cityId, token) => {
    try {
      const response = await post(
        BaseSetting.ApiDomain + `/locality`,
        { name: locality, cityId },
        {
          ...headers,
          "x-auth-token": token,
        }
      );
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },
  deleteLocality: async (localityId, token) => {
    try {
      const response = await del(
        BaseSetting.ApiDomain + `/locality/${localityId}`,
        {
          ...headers,
          "x-auth-token": token,
        }
      );
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },
  deleteCity: async (cityId, token) => {
    try {
      const response = await del(BaseSetting.ApiDomain + `/city/${cityId}`, {
        ...headers,
        "x-auth-token": token,
      });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },
  createCity: async (city, stateId, token) => {
    try {
      const response = await post(
        BaseSetting.ApiDomain + `/city`,
        { name: city, stateId },
        {
          ...headers,
          "x-auth-token": token,
        }
      );
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return false;
    }
  },
};

export default stateApis;
