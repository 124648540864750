import { Button, Label, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AdminApi from "../../../../api/AdminApi";

const AddUserContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const Auth = useSelector((state) => state.auth.authData);

  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  console.log(err);

  const addUserFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
      role: "reseller",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(20, "Name must be max 20 Characters or less")
        .required("Required"),
      email: Yup.string().email().required("Required"),
      mobile: Yup.string()
        .min(10, "Minimum 10 characters")
        .max(10, "Mobile number should be 10 characters")
        .required("Required"),
      password: Yup.string()
        .max(20, "Password must be min 4 characters and max 20 characters")
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const postUpdatedData = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        password: values.password,
        role: values.role,
      };

      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await AdminApi.createUser(
            postUpdatedData,
            Auth.token
          );
          // const response = await SignUpWithPass(postUpdatedData);
          console.log(response);
          if (response) {
            if (response.status === 1) {
              resetForm();
              toast("User Created SuccessFully");
            } else {
              setErr({ error: response.data, show: true });
            }
            setIsLoading(false);
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        alert("Error Updating Data");
      }
    },
  });
  return (
    <>
      <div className="full-info w-full">
        <form onSubmit={addUserFormik.handleSubmit}>
          <div className="p-8 dark:bg-gray-800 rounded-lg shadow-md">
            <h1 className="dark:text-white font-semibold text-2xl mb-4">
              Create User
            </h1>
            <div className=" flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="flex-1">
                  <div className="mb-2 block">
                    <Label htmlFor="name" value="Name" />
                  </div>
                  <TextInput
                    id="name"
                    type="text"
                    placeholder="User Name"
                    required={true}
                    value={addUserFormik.values.name}
                    onChange={addUserFormik.handleChange}
                  />
                  {addUserFormik.errors.name ? (
                    <p className="text-xs text-red-600 ">
                      {addUserFormik.errors.name}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex-1">
                  <div className="mb-2 block">
                    <Label htmlFor="email" value="User Email" />
                  </div>
                  <TextInput
                    id="email"
                    type="email"
                    placeholder="User Email"
                    required={true}
                    value={addUserFormik.values.email}
                    onChange={addUserFormik.handleChange}
                  />
                  {addUserFormik.errors.email ? (
                    <p className="text-xs text-red-600 ">
                      {addUserFormik.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="flex-1">
                  <div className="mb-2 block items-center">
                    <Label htmlFor="mobile" value="User Phone No." />
                  </div>
                  <TextInput
                    id="mobile"
                    type="text"
                    placeholder="User Mobile"
                    required={true}
                    value={addUserFormik.values.mobile}
                    onChange={addUserFormik.handleChange}
                  />
                  {addUserFormik.errors.mobile ? (
                    <p className="text-xs text-red-600 ">
                      {addUserFormik.errors.mobile}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <div className="flex-1">
                  <div className="mb-2 block items-center">
                    <Label htmlFor="password" value="User Password" />
                  </div>
                  <TextInput
                    id="password"
                    type="password"
                    placeholder="Password"
                    required={true}
                    value={addUserFormik.values.password}
                    onChange={addUserFormik.handleChange}
                  />
                  {addUserFormik.errors.password ? (
                    <p className="text-xs text-red-600 ">
                      {addUserFormik.errors.password}
                    </p>
                  ) : null}
                </div>
                <div className="flex-1">
                  <div className="mb-2 block items-center">
                    <Label htmlFor="password" value="Role Type" />
                  </div>
                  <div className="flex">
                    <select
                      id="role"
                      value={addUserFormik.values.role}
                      onChange={addUserFormik.handleChange}
                      // onBlur={addUserFormik.handleBlur}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="reseller">Reseller</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <div className="flex-1 submit">
                  <Button type="submit" onSubmit={addUserFormik.handleSubmit}>
                    Create new user
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-3">
              {err.show ? (
                <div className="mt-2 text-[#da232aff] text-sm">
                  {err.error.message ? err.error.message : err.error}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUserContainer;
