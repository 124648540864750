import React from "react";
import HomeContainer from "../components/Website/HomeContainer.jsx/HomeContainer";

const Home = () => {
  return (
    <div>
      <HomeContainer />
    </div>
  );
};

export default Home;
