import React from "react";
import {  BsPhone } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { HiMail } from "react-icons/hi";

const ContactDetailsWithMap = () => {
  return (
    <div className="w-full">
      <div className="map-info">
        <div className="map relative h-144 w-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameborder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabindex="0"
            title="googlemapcontact"
          ></iframe>
        </div>
        <div className="absolute top-52 p-9 left-28 bg-white dark:bg-gray-800 dark:text-white">
          <h1 className="text-xl mb-6 font-bold">How to find us</h1>
          <div className="content font-medium mb-6">
            <div className="phone flex gap-2 items-center mb-3">
              <p className="mr-2 p-1 bg-lightGray dark:bg-gray-400 rounded-full border-white">
                <BsPhone className="text-slate-700 dark:text-white" />
              </p>
              <p className="text-sm">
                <a href="tel:+919928382340" className="hover:text-blue">
                  9928382340
                </a>
              </p>
            </div>
            <div className="phone flex gap-2 items-center mb-3">
              <p className="mr-2 p-1 bg-lightGray rounded-full">
                <HiMail className="text-slate-700" />
              </p>
              <p className="text-sm">
                <a href="mailto:yogesh@tej.life" className="hover:text-blue">
                  yogesh@tej.life
                </a>
              </p>
            </div>
            <div className="phone flex gap-2 items-center mb-3">
              <p className="mr-2 p-1 bg-lightGray rounded-full">
                <FaHome className="text-slate-700" />
              </p>
              <p className="text-sm">
                4th Floor, DCM, Ajmer Road, Jaipur, Rajasthan, 302021
              </p>
            </div>
          </div>
          <h1 className="text-xl mb-5 font-bold dark:text-white">Opening Hours</h1>
          <div className="content font-medium">
            <div className="flex gap-2 items-center justify-between mb-3">
              <p className="mr-2 text-slate-700 text-sm dark:text-white">Monday - Friday</p>
              <p className="text-sm text-slate-700 dark:text-white">10:00 AM to 7:00 PM </p>
            </div>
            <div className="flex gap-2 items-center mb-3 justify-between">
              <p className="mr-2 text-slate-700 text-sm dark:text-white">Saturday</p>
              <p className="text-sm text-slate-700 dark:text-white">10:00 AM to 2:00 PM </p>
            </div>
            <div className="flex gap-2 items-center mb-3 justify-between">
              <p className="mr-2 text-slate-700 text-sm dark:text-white">Sunday</p>
              <p className="text-sm text-slate-700 dark:text-white">Closed </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsWithMap;
