import React from "react";
import MainFooter from "../components/common/MainFooter";
import SubFooter from "../components/common/SubFooter";
import SingleProjectContainer from "../components/Website/SingleProject/SingleProjectContainer";

const SingleProject = () => {
  return (
    <>
      <SingleProjectContainer />
      <SubFooter />
      <MainFooter />
    </>
  );
};

export default SingleProject;
