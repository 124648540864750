import { Button } from "flowbite-react";
import React, { useState } from "react";
import { BsUpload } from "react-icons/bs";
import { CgRemove } from "react-icons/cg";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import Loading from "../../../utils/Loading";

const MultipleImagesUpload = ({ form, fetching }) => {
  const [state, setState] = useState([]);
  const handleChange = (e) => {
    console.log(e);
    let imageArr = [];
    let imageArrPreview = [];
    for (let i = 0; i < e.target.files.length; i++) {
      imageArrPreview.push({
        [e.target.name]: URL.createObjectURL(e.target.files[i]),
      });
      imageArr.push(e.target.files[i]);
    }
    console.log(imageArr);
    // form.setFieldValue("images", imageArr);
    form.setFieldValue("images", imageArr);
    setState(imageArrPreview);
  };
  const removeCurrentImages = () => {
    setState([]);
    form.setFieldValue("images", "");
  };

  return (
    <div className="options justify-between shadow-defaultShadow text-gray-900 rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800 border-box pb-4">
      <div className="flex gap-4 mb-5">
        <div class="flex-1 font-sans ">
          <div class="flex flex-col items-center justify-center w-full h-auto dark:bg-gray-800 bg-white sm:rounded-lg">
            <div class="mt-5 mb-5 text-center">
              <h2 class="text-2xl font-semibold mb-2 dark:text-white">
                Add photos to get 5X more responses
              </h2>
              <p class="text-xs text-gray-500 dark:text-para">
                90% tenants contact on properties with photos.
              </p>
            </div>
            <div class="relative w-4/5 h-32 max-w-xs mb-5 bg-gray-100 rounded-lg shadow-inner">
              {/* <input type="file" id="file-upload" class="hidden" /> */}
              <input
                multiple="multiple"
                id="images"
                name="images"
                accept="image/*"
                className="hidden w-100"
                type="file"
                onChange={handleChange}
                // onChange={imageUploadFormik.handleChange}
                // value={form.values.images}
              />
              <label
                html="images"
                htmlFor="images"
                class="z-20 flex flex-col-reverse items-center justify-center w-full h-full cursor-pointer"
              >
                <p class="z-10 text-xs font-light text-center text-gray-500">
                  Drag & Drop your files here
                </p>
                <svg
                  class="z-10 w-8 h-8 text-blue-600"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                </svg>
              </label>
            </div>
          </div>
          <div className="flex flex-wrap gap-2 justify-self-end w-full mt-4">
            {state.length > 0 &&
              state.map((item, index) => {
                return (
                  <>
                    <div className="flex" key={index}>
                      {/* {item} */}
                    </div>
                    <img src={item.images} alt="img" className="h-52" />
                  </>
                );
              })}
          </div>
        </div>
      </div>
      <div className="flex gap-2 mt-6 ml-3">
        <Button
          disabled={fetching || form.values.images.length === 0}
          className=""
          color={"gray"}
          onClick={() => removeCurrentImages()}
        >
          <CgRemove className="mr-2" /> Remove Images
        </Button>
        <Button
          disabled={fetching || form.values.images.length === 0}
          type="submit"
          onClick={form.handleSubmit}
        >
          {fetching ? <Loading /> : ""}
          <BsUpload className="mr-2" /> Finish Uploading
        </Button>
      </div>
    </div>
  );
};

export default MultipleImagesUpload;
