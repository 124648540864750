import React from 'react'
import AllUsersLoad from '../../../../components/Dashboard/Admin/AllUsers/AllUsersLoad';

const AllUsers = () => {
  return (
    <>
        <AllUsersLoad />
    </>
  )
}

export default AllUsers;