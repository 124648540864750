import React, { useState, useEffect } from "react";
import MapStyles from "../../common/GoogleMap/MapStyles";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { Button } from "flowbite-react";
import { FaHospital, FaSchool } from "react-icons/fa";
import { MdLocalAirport } from "react-icons/md";
import { GiBusStop, GiConfirmed } from "react-icons/gi";

const MapWithOptions = ({ center, zoom, name }) => {
  const [map, setMap] = React.useState(null);
  const { theme, setTheme } = React.useContext(ThemeContext);
  console.log(theme);
  const [mapOptions, setMapOptions] = useState();

  const mapStyles = {
    dark: MapStyles.Dark.DarkMapWithAllOptions,
    light: MapStyles.Light.LightWithAllOptions,
  };

  useEffect(() => {
    setMapOptions({
      styles: mapStyles[theme],
    });
    console.log(`changing to ${theme}`);
  }, [theme]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_ALL_KEY,
    libraries: ["places"],
  });

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(zoom);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const markerOnLoad = (marker) => {
    console.log("Marker: ", marker);
  };

  const setMapOptionsWithOption = (option) => {
    if (theme === "dark") {
      setMapOptions({
        styles: MapStyles.Dark[`DarkMapWith${option}`],
      });
    } else {
      setMapOptions({
        styles: MapStyles.Light[`LightMapWith${option}`],
      });
    }
  };

  const setHospital = () => {
    setMapOptionsWithOption("Hospitals");
  };

  const setBus = () => {
    setMapOptionsWithOption("BusStands");
  };

  const setAll = () => {
    setMapOptionsWithOption("AllOptions");
  };

  const setAirports = () => {
    setMapOptionsWithOption("Airports");
  };

  const setSchool = () => {
    setMapOptionsWithOption("Schools");
  };

  return (
    <>
      {isLoaded ? (
        <div className="w-full h-full">
          <div className="options">
            <p className="mb-4 dark:text-white text-para text-xs">
              Nearest <span className="">within 5kms</span>
            </p>
            <div className="flex overflow-scroll md:flex-wrap items-center gap-3 mb-3">
              <div>
                <Button outline={true} color={"dark"} onClick={setAll}>
                  <GiConfirmed className="mr-2" />
                  All
                </Button>
              </div>
              <div>
                <Button outline={true} color={"dark"} onClick={setHospital}>
                  <FaHospital className="mr-2" />
                  Hospital
                </Button>
              </div>
              <div>
                <Button outline={true} color={"dark"} onClick={setSchool}>
                  <FaSchool className="mr-2" />
                  School
                </Button>
              </div>
              <div>
                <Button outline={true} color={"dark"} onClick={setAirports}>
                  <MdLocalAirport className="mr-2" />
                  Airports
                </Button>
              </div>

              <div>
                <Button outline={true} color={"dark"} onClick={setBus}>
                  <GiBusStop className="mr-2" />
                  BusStop
                </Button>
              </div>
            </div>
          </div>
          <div className="map h-[80%]">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              // onUnmount={onUnmount}
              options={mapOptions}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <>
                <Marker
                  onLoad={markerOnLoad}
                  position={center}
                  label={name}
                  labelStyle={{
                    backgroundColor: "yellow",
                    fontSize: "32px",
                    padding: "16px",
                  }}
                />
              </>
            </GoogleMap>
          </div>
        </div>
      ) : (
        <>
          <h1>Map not available</h1>
        </>
      )}
    </>
  );
};

export default React.memo(MapWithOptions);
// export default MapWithOptions;
