import {
  Button,
  Checkbox,
  Label,
  Textarea,
  TextInput,
  Tooltip,
} from "flowbite-react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import AdminApi from "../../../../api/AdminApi";
import ResellerApi from "../../../../api/ResellerApi";
import Loading from "../../../../utils/Loading";
import { States } from "../../../../types/State";
import { toast } from "react-toastify";
import GoogleAutoComplete from "../../../common/GoogleMap/GoogleAutoComplete";
import { GoogleAutoCompleteContext } from "../../../../contexts/GoogleAutoCompleteData";
import { createTheme } from "@mui/material/styles";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import MultiSelect from "../../../common/MultiSelect";
import { PropertyType } from "../../../../types/PropertyTypes";
import DatePickerDefault from "../../../common/DatePickerDefault";
import { MdDelete } from "react-icons/md";
import { CgAdd } from "react-icons/cg";
import { Cities } from "../../../../types/State";
import FileUpload from "../../../common/FileUpload/MultipleImagesUpload";
import Amenities from "../../../common/Amenities/Amenities";
import { BaseSetting } from "../../../../utils/settings";
import { IoRemoveCircle } from "react-icons/io5";

/***
 * This Container is responsible for uploading a new project and updating the existing projects as well
 */
const UploadProjectContainer = () => {
  const navigate = useNavigate();

  const { theme, setTheme } = React.useContext(ThemeContext);
  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });
  const { locationData, setLocationData } = useContext(
    GoogleAutoCompleteContext
  );
  console.log(locationData);
  const location = useLocation();
  const urlEndPath = location.pathname.split("/").pop();
  const [isNew, setIsNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [builderID, setBuilderID] = useState([]);
  const auth = useSelector((state) => state.auth.authData);
  const [dateValue, setDateValue] = React.useState("");
  const [configurationsArray, setConfigurationsArray] = useState([]);
  const Auth = useSelector((state) => state.auth.authData);

  const [imgArrUpdate, setImgArrUpdate] = useState([]);

  const deleteConfiguration = (index) => {
    setConfigurationsArray((prev) => prev.filter((el) => el.id !== index));
  };

  const [currentImages, setCurrentImages] = useState([]);

  const addConfiguration = (id) => {
    setConfigurationsArray([
      ...configurationsArray,
      {
        id: configurationsArray.length,
        type: "",
        area: 0,
        areaUnit: "",
        bhkType: "",
        units: 0,
      },
    ]);
  };

  const configurationHandleChange = (id) => (e) => {
    console.log(id);
    const newArray = configurationsArray.map((item) => {
      if (item.id === id) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setConfigurationsArray(newArray);
  };

  const createProjectFormik = useFormik({
    initialValues: {
      name: "",
      launchedDate: "",
      userId: auth.id,
      description: "",
      builderId: "",
      //new
      builderNotListed: false,
      customBuilderName: "",
      types: [
        {
          value: "flat",
          label: "Flat",
        },
      ],
      localityDetails: {
        city: "",
        locality: "",
        street: "",
        lat: locationData !== undefined ? locationData.coord.lat : "",
        lng: locationData !== undefined ? locationData.coord.lng : "",
        address:
          locationData !== undefined
            ? locationData.address[0].formatted_address
            : "",
        other: "",
        area: "",
        state: "",
        country: "",
        zip: "",
        pincode: "",
      },

      meta: "",
      images: [""],
      configurations: configurationsArray,
      totalUnits: 0,
      totalTowers: 0,
      totalFloors: 0,
      reraRegistration: "",
      completedYear: "",
      amenities: {
        waterSupplyAllTime: false,
        powerBackup: false,
        cctv: false,
        basketBallCourt: false,
        tennisCourt: false,
        theaterRoom: false,
        cafeteria: false,
        library: false,
        yogaRoom: false,
        snooker: false,
        indoorGames: false,
        spa: false,
        businessMeetingRooms: false,
        guestRooms: false,
        banquetHall: false,
        carWash: false,
        privateLift: false,
        seniorCitizenCorner: false,
        cricketArea: false,
        squashCourt: false,
        jacuzzi: false,
        skatingRing: false,
        gym: false,
        swimmingPool: false,
        intercom: false,
        shoppingCenter: false,
        clubHouse: false,
        lift: false,
        playArea: false,
        gasPipeline: false,
        gatedSecurity: false,
        fireSafety: false,
        park: false,
        internetProvider: false,
        laundry: false,
        modularKitchen: false,
        smokeDetection: false,
      },
      brochure: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 1 characters")
        .max(20, "Name must be max 20 Characters or less")
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      for (let i = 0; i < values.images.length; i++) {
        formData.append("images", values.images[i]);
      }
      setFetching(true);
      const postUpdatedData = {
        name: values.name,
        launchedDate: values.launchedDate,
        userId: values.userId,
        description: values.description,
        builderId: values.builderId,
        //new
        builderNotListed: values.builderNotListed,
        customBuilderName: values.customBuilderName,
        types: values.types,
        localityDetails: {
          city: values.localityDetails.city,
          locality: values.localityDetails.locality,
          street: values.localityDetails.street,
          lat:
            locationData !== undefined && locationData.coord !== ""
              ? locationData.coord.lat
              : values.localityDetails.lat,
          lng:
            locationData !== undefined && locationData.coord !== ""
              ? locationData.coord.lng
              : values.localityDetails.lng,
          other: values.localityDetails.other,
          address: values.localityDetails.address,
          area: values.localityDetails.area,
          state: values.localityDetails.state,
          country: values.localityDetails.country,
          zip: values.localityDetails.zip,
          pincode: values.localityDetails.pincode,
        },
        meta: values.meta,
        images: "",
        configurations: configurationsArray,
        totalUnits: values.totalUnits,
        totalTowers: values.totalTowers,
        totalFloors: values.totalFloors,
        reraRegistration: values.reraRegistration,
        completedYear: values.completedYear,
        amenities: {
          waterSupplyAllTime: values.amenities.waterSupplyAllTime,
          powerBackup: values.amenities.powerBackup,
          cctv: values.amenities.cctv,
          basketBallCourt: values.amenities.basketBallCourt,
          tennisCourt: values.amenities.tennisCourt,
          theaterRoom: values.amenities.theaterRoom,
          cafeteria: values.amenities.cafeteria,
          library: values.amenities.library,
          yogaRoom: values.amenities.yogaRoom,
          snooker: values.amenities.snooker,
          indoorGames: values.amenities.indoorGames,
          spa: values.amenities.spa,
          businessMeetingRooms: values.amenities.businessMeetingRooms,
          guestRooms: values.amenities.guestRooms,
          banquetHall: values.amenities.banquetHall,
          carWash: values.amenities.carWash,
          privateLift: values.amenities.privateLift,
          seniorCitizenCorner: values.amenities.seniorCitizenCorner,
          cricketArea: values.amenities.cricketArea,
          squashCourt: values.amenities.squashCourt,
          jacuzzi: values.amenities.jacuzzi,
          skatingRing: values.amenities.skatingRing,
          gym: values.amenities.gym,
          swimmingPool: values.amenities.swimmingPool,
          intercom: values.amenities.intercom,
          shoppingCenter: values.amenities.shoppingCenter,
          clubHouse: values.amenities.clubHouse,
          lift: values.amenities.lift,
          playArea: values.amenities.playArea,
          gasPipeline: values.amenities.gasPipeline,
          gatedSecurity: values.amenities.gatedSecurity,
          fireSafety: values.amenities.fireSafety,
          park: values.amenities.park,
          internetProvider: values.amenities.internetProvider,
          laundry: values.amenities.laundry,
          modularKitchen: values.amenities.modularKitchen,
          smokeDetection: values.amenities.smokeDetection,
        },
        brochure: values.brochure,
      };

      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          //To create a new Project
          if (isNew) {
            const imageResponse = await ResellerApi.addImages(
              formData,
              auth.token
            );
            if (imageResponse.length > 0) {
              postUpdatedData.images = imageResponse;
              const response = await AdminApi.createProject(
                postUpdatedData,
                auth.token
              );
              if (response) {
                resetForm();
                toast("Success: Project Created!");
                setFetching(false);
                navigate(
                  `${
                    Auth.user.role === "admin"
                      ? "/dashboard/admin/all-projects"
                      : "/dashboard/reseller/all-projects"
                  }`
                );
              } else {
                setErr({ error: response.data.data, show: true });
              }
            } else {
              setFetching(false);
              alert(
                "Please add image add to the property from Gallery Tab!! 👇"
              );
            }
          }
          //To update existing projects
          else {
            //lets say you added new images to the existing project
            if (values.images.length > 0) {
              const imageResponse = await ResellerApi.addImages(
                formData,
                auth.token
              );
              const combinedImages = [...imageResponse, ...imgArrUpdate];
              postUpdatedData.images = combinedImages;
              const response = await AdminApi.updateProject(
                urlEndPath,
                postUpdatedData,
                auth.token
              );
              if (response) {
                // resetForm();
                toast("Success: Project Updated!");
                setFetching(false);
                navigate(
                  `${
                    Auth.user.role === "admin"
                      ? "/dashboard/admin/all-projects"
                      : "/dashboard/reseller/all-projects"
                  }`
                );
              } else {
                setErr({ error: response.data.data, show: true });
              }
            } else {
              //just for updating current images like removed one of them
              postUpdatedData.images = imgArrUpdate;
              const response = await AdminApi.updateProject(
                urlEndPath,
                postUpdatedData,
                auth.token
              );
              if (response) {
                // resetForm();
                toast("Success: Project Updated!");
                setFetching(false);
                navigate(
                  `${
                    Auth.user.role === "admin"
                      ? "/dashboard/admin/all-projects"
                      : "/dashboard/reseller/all-projects"
                  }`
                );
              } else {
                setErr({ error: response.data.data, show: true });
              }
            }
            toast.warning("Uploading Property, Please Wait");
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        alert("Error Updating Data");
      }
    },
  });

  console.log(createProjectFormik.values);

  const MultiSelectHandleChange = (e) => {
    createProjectFormik.setFieldValue("types", e);
  };

  const getBuilderList = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        const response = await AdminApi.getAllBuilders(auth.token);
        if (response) {
          setIsLoading(false);
          // form.setFieldValue("projectId", response);
          setBuilderID(response);
          return true;
        } else {
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      console.log(error);
      console.log("Error loading data");
    }
  };

  const checkAndGetData = async () => {
    if (urlEndPath === "new") {
      createProjectFormik.resetForm();
      setIsNew(true);
    } else {
      //get the data
      setIsLoading(true);
      setIsNew(false);
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await AdminApi.getProject(urlEndPath, auth.token);
          if (response) {
            setIsLoading(false);
            createProjectFormik.setValues(response);
            createProjectFormik.setFieldValue("images", []);
            setImgArrUpdate(response.images);
            setConfigurationsArray(response.configurations);
            // setDateValue(moment(response.launchedDate).utc());
            console.log(createProjectFormik.values);
            return true;
          } else {
            setErr({ error: response.data.data, show: true });
            return false;
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        console.log("Error loading data");
      }
    }
  };
  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  const removeExistingImage = (index) => {
    if (window.confirm(`Are you sure you want to delete this image?`)) {
      setImgArrUpdate((prev) => prev.filter((el, i) => i !== index)); // filter by index
    }
  };

  useEffect(() => {
    if (isNew) {
      setCurrentImages([createProjectFormik.values.images]);
    } else {
    }
  }, [location]);

  useEffect(() => {
    createProjectFormik.setFieldValue(
      "localityDetails.lat",
      locationData.coord.lat
    );
    createProjectFormik.setFieldValue(
      "localityDetails.lng",
      locationData.coord.lng
    );
    createProjectFormik.setFieldValue(
      "localityDetails.address",
      locationData.address[0].formatted_address
    );
  }, [locationData.coord.lat, locationData.coord.lng]);

  useEffect(() => {
    checkAndGetData();
    getBuilderList();
  }, [urlEndPath]);

  return (
    <>
      <div className="full-info w-full">
        {isLoading ? (
          <span className="loader"></span>
        ) : (
          <form
            onSubmit={createProjectFormik.handleSubmit}
            encType="multipart/form-data"
          >
            <div className="p-8 dark:bg-gray-800 rounded-lg shadow-md bg-white">
              <h1 className="dark:text-white font-semibold text-2xl mb-4">
                {isNew ? "Create new Project" : createProjectFormik.values.name}
              </h1>
              <div className=" flex flex-col gap-4">
                <div className="flex gap-4">
                  <div className="flex-1">
                    <div className="mb-2 block">
                      <Label htmlFor="name" value="Project Name" />
                    </div>
                    <TextInput
                      id="name"
                      type="text"
                      placeholder="Enter Project Name"
                      required={true}
                      value={createProjectFormik.values.name}
                      onChange={createProjectFormik.handleChange}
                    />
                    {createProjectFormik.errors.name ? (
                      <p className="text-xs text-red-600 ">
                        {createProjectFormik.errors.name}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex-1">
                    <div className="mb-2 block items-center">
                      <Label htmlFor="password" value="Project Since" />
                    </div>
                    <div className="flex">
                      <DatePickerDefault
                        defaultValue={createProjectFormik.values.launchedDate}
                        handleDateChange={(newValue) => {
                          createProjectFormik.setFieldValue(
                            "launchedDate",
                            newValue.format("MM/DD/YYYY")
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex-1">
                    <div className="mb-2 block items-center">
                      <Label htmlFor="description" value="About Project" />
                    </div>
                    <Textarea
                      id="description"
                      type="text"
                      placeholder="Write some description about the project"
                      required={true}
                      value={createProjectFormik.values.description}
                      onChange={createProjectFormik.handleChange}
                    />
                    {createProjectFormik.errors.description ? (
                      <p className="text-xs text-red-600 ">
                        {createProjectFormik.errors.description}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="projectInfo">
                  <h1 className="text-lg font-bold dark:text-white mb-2">
                    Builder Info
                  </h1>
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label htmlFor="builderId" value="Builder Name" />
                      </div>
                      <div className="flex">
                        <select
                          id="builderId"
                          value={createProjectFormik.values.builderId}
                          onChange={createProjectFormik.handleChange}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="" hidden disabled key={0}>
                            Select from options
                          </option>
                          {builderID.map((state, index) => {
                            return (
                              <option value={state._id} key={index}>
                                {state.name}
                              </option>
                            );
                          })}
                          {/* <option value={"notListedHere"} key={"NotListed"}>
                      Not Listed Here
                    </option> */}
                        </select>
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <Checkbox
                          id="builderNotListed"
                          checked={createProjectFormik.values.builderNotListed}
                          onChange={createProjectFormik.handleChange}
                          value={createProjectFormik.values.builderNotListed}
                        />
                        <Label htmlFor="remember">
                          Builder Not listed above?
                        </Label>
                      </div>
                      {createProjectFormik.errors.projectId ? (
                        <p className="text-sm text-red-600 mt-2">
                          {createProjectFormik.errors.projectId}
                        </p>
                      ) : null}
                    </div>
                    {createProjectFormik.values.builderNotListed && (
                      <div className="flex-1">
                        <div className="mb-2 block">
                          <Label
                            htmlFor="customBuilderName"
                            value="Builder Not Listed above? Add your here"
                          />
                        </div>
                        <div className="flex-1 w-full">
                          <TextInput
                            id="customBuilderName"
                            type="text"
                            placeholder="type your Builder name"
                            value={createProjectFormik.values.customBuilderName}
                            onChange={createProjectFormik.handleChange}
                            // required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="localityInfo">
                  <div className="flex-1">
                    <h1 className="text-lg font-bold dark:text-white mb-2">
                      Locality Info
                    </h1>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label htmlFor="street" value="Building Number" />
                      </div>
                      <TextInput
                        id="localityDetails.street"
                        type="text"
                        placeholder="Type here Building Number/Office"
                        required={true}
                        value={
                          createProjectFormik.values.localityDetails.street
                        }
                        onChange={createProjectFormik.handleChange}
                      />
                      {/* {createProjectFormik.errors.localityDetails.street ? (
                        <p className="text-xs text-red-600 ">
                          {createProjectFormik.errors.localityDetails.street}
                        </p>
                      ) : null} */}
                    </div>
                    <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label
                          htmlFor="localityDetails.locality"
                          value="Locality"
                        />
                      </div>
                      <TextInput
                        id="localityDetails.locality"
                        type="text"
                        placeholder="Type here locality name"
                        required={true}
                        value={
                          createProjectFormik.values.localityDetails.locality
                        }
                        onChange={createProjectFormik.handleChange}
                      />
                      {/* {createProjectFormik.errors.localityDetails.locality ? (
                        <p className="text-xs text-red-600 ">
                          {createProjectFormik.errors.localityDetails.locality}
                        </p>
                      ) : null} */}
                    </div>
                    {/* <div className="flex-1"> */}
                    {/* <div className="mb-2 block items-center">
                        <Label htmlFor="localityDetails.area" value="Area" />
                      </div>
                      <TextInput
                        id="localityDetails.area"
                        type="text"
                        placeholder="Type here locality name"
                        required={true}
                        value={createProjectFormik.values.localityDetails.area}
                        onChange={createProjectFormik.handleChange}
                      /> */}
                    {/* {createProjectFormik.errors.localityDetails.area ? (
                        <p className="text-xs text-red-600 ">
                          {createProjectFormik.errors.localityDetails.area}
                        </p>
                      ) : null} */}
                    {/* </div> */}
                  </div>
                  <div className="flex gap-4 mt-4">
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="localityDetails.city"
                          value="Project City"
                        />
                      </div>
                      <select
                        id="localityDetails.city"
                        value={createProjectFormik.values.localityDetails.city}
                        onChange={createProjectFormik.handleChange}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Cities.map((state) => {
                          return (
                            <option value={state.value}>{state.label}</option>
                          );
                        })}
                      </select>
                      {/* {createProjectFormik.errors.localityDetails.city ? (
                        <p className="text-xs text-red-600 ">
                          {createProjectFormik.errors.localityDetails.city}
                        </p>
                      ) : null} */}
                    </div>
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="localityDetails.state"
                          value="Project State"
                        />
                      </div>
                      <select
                        id="localityDetails.state"
                        value={createProjectFormik.values.localityDetails.state}
                        onChange={createProjectFormik.handleChange}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {States.map((state) => {
                          return (
                            <option value={state.value}>{state.label}</option>
                          );
                        })}
                      </select>
                      {/* {createProjectFormik.errors.localityDetails.state ? (
                        <p className="text-xs text-red-600 ">
                          {createProjectFormik.errors.localityDetails.state}
                        </p>
                      ) : null} */}
                    </div>
                    <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label
                          htmlFor="localityDetails.pincode"
                          value="Pincode"
                        />
                      </div>
                      <TextInput
                        id="localityDetails.pincode"
                        type="text"
                        placeholder="Pincode"
                        required={true}
                        value={
                          createProjectFormik.values.localityDetails.pincode
                        }
                        onChange={createProjectFormik.handleChange}
                      />
                      {/* {createProjectFormik.errors.localityDetails.pincode ? (
                        <p className="text-xs text-red-600 ">
                          {createProjectFormik.errors.localityDetails.pincode}
                        </p>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="flex gap-4 item-center mb-7 mt-4">
                    <div className="flex-1 h-80">
                      <p className="dark:text-white mb-3">
                        <b>Project Location on Google Map</b>:{" "}
                        {createProjectFormik.values.localityDetails.address !==
                        ""
                          ? createProjectFormik.values.localityDetails.address
                          : ""}
                      </p>
                      <div className="h-56">
                        <GoogleAutoComplete
                          center={{
                            lat: Number(
                              createProjectFormik.values.localityDetails.lat
                            ),
                            lng: Number(
                              createProjectFormik.values.localityDetails.lng
                            ),
                          }}
                          address={
                            createProjectFormik.values.localityDetails.address
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="configurations">
                  <h1 className="text-lg font-bold dark:text-white mb-2">
                    Project Configurations
                  </h1>
                  <div className="flex gap-4 mb-4">
                    <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label htmlFor="types" value="Project Type" />
                      </div>
                      <div className="flex w-full">
                        <MultiSelect
                          data={PropertyType}
                          defaultValue={createProjectFormik.values.types}
                          handleChange={MultiSelectHandleChange}
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label
                          htmlFor="reraRegistration"
                          value="Rera Registeration Number"
                        />
                      </div>
                      <TextInput
                        id="reraRegistration"
                        type="string"
                        placeholder="Please enter project rera registration number"
                        required={true}
                        value={createProjectFormik.values.reraRegistration}
                        onChange={createProjectFormik.handleChange}
                      />
                    </div>
                    {/* <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label htmlFor="completedYear" value="Completed Year" />
                      </div>
                      <div className="flex">
                        <select
                          id="completedYear"
                          name="completedYear"
                          value={createProjectFormik.values.completedYear}
                          onChange={createProjectFormik.handleChange}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {[...Array(55)].map((x, i) => (
                            <option value={i} key={i}>
                              {i + 1969}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}
                  </div>

                  {configurationsArray.length > 0 ? (
                    configurationsArray.map(
                      ({ id, type, area, areaUnit }, item, index) => {
                        return (
                          <>
                            <div className="flex gap-4 items-end mb-2" key={id}>
                              <div className="flex gap-4 w-full flex-nowrap items-end">
                                <div className="flex-1" key={id}>
                                  <div className="mb-2 block items-center">
                                    <Label htmlFor="type" value="Type" />
                                  </div>
                                  <TextInput
                                    id={`type`}
                                    name="type"
                                    type="text"
                                    placeholder={`BHK Type/Villa/Plot xx`}
                                    required={true}
                                    value={type}
                                    onChange={configurationHandleChange(id)}
                                  />
                                </div>
                                <div className="flex-1">
                                  <div className="mb-2 block items-center">
                                    <Label htmlFor="area" value="Area" />
                                  </div>
                                  <TextInput
                                    id={`area`}
                                    name="area"
                                    type="number"
                                    placeholder="enter the area"
                                    required={true}
                                    value={area}
                                    onChange={configurationHandleChange(id)}
                                  />
                                </div>
                                <div className="flex-1">
                                  <div className="mb-2 block">
                                    <Label
                                      htmlFor={`configurations.areaUnit`}
                                      value="Area Unit "
                                    />
                                  </div>
                                  <select
                                    id="areaUnit"
                                    name="areaUnit"
                                    value={areaUnit}
                                    onChange={configurationHandleChange(id)}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  >
                                    <option value="" selected disabled key={0}>
                                      Select area unit
                                    </option>
                                    <option value={"sqyd"} key={1}>
                                      sq yards
                                    </option>
                                    <option value={"sqft"} key={2}>
                                      sqft
                                    </option>
                                    <option value={"sqmt"} key={3}>
                                      sq meters
                                    </option>
                                  </select>
                                </div>
                                <div className="flex-1">
                                  <div className="flex gap-4">
                                    <div className="mb-2"></div>
                                    <Button
                                      size={"md"}
                                      color={"gray"}
                                      id={id}
                                      onClick={() => deleteConfiguration(id)}
                                    >
                                      <MdDelete className="mr-2" />
                                      Delete
                                    </Button>
                                    <Button
                                      size={"md"}
                                      color={"gray"}
                                      id={id}
                                      onClick={() => addConfiguration(id)}
                                    >
                                      <CgAdd className="text-green-600 mr-2" />
                                      Add
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )
                  ) : (
                    <>
                      <div className="flex w-full items-center">
                        <Button
                          size={"md"}
                          color={"gray"}
                          id={0}
                          onClick={() => addConfiguration(0)}
                        >
                          <CgAdd className="text-green-600 mr-2" />
                          Add Property Configurations
                        </Button>
                      </div>
                    </>
                  )}
                  <div className="flex gap-4 mt-4">
                    <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label htmlFor="totalUnits" value="Total Units" />
                      </div>
                      <TextInput
                        id="totalUnits"
                        type="number"
                        placeholder="Total Units"
                        required={true}
                        value={createProjectFormik.values.totalUnits}
                        onChange={createProjectFormik.handleChange}
                      />
                    </div>
                    <div className="flex-1">
                      <div className="mb-2 block items-center">
                        <Label htmlFor="totalTowers" value="Total Towers" />
                      </div>
                      <TextInput
                        id="totalTowers"
                        type="number"
                        placeholder="Total Towers"
                        required={true}
                        value={createProjectFormik.values.totalTowers}
                        onChange={createProjectFormik.handleChange}
                      />
                    </div>
                    <div className="flex-1">
                      <div className="mb-2 block">
                        <Label htmlFor="totalFloors" value="Total Floors" />
                      </div>
                      <div className="flex">
                        <select
                          id="totalFloors"
                          value={createProjectFormik.values.totalFloors}
                          onChange={createProjectFormik.handleChange}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option disabled>
                            Total Number of Floors in Building
                          </option>
                          <option value={"dontknow"}>Don't Know</option>
                          {[...Array(50)].map((x, i) => (
                            <option value={i} key={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 
                  Amenities HTML Starts here
                */}
                <div className="amenities mt-6">
                  <h1 className="text-lg font-bold dark:text-white mb-2">
                    Amenities
                  </h1>
                  <Amenities form={createProjectFormik} />
                </div>

                {/* 
                  Galley Uploads
                */}
                <div className="galleryUploads mt-6">
                  <h1 className="text-lg font-bold dark:text-white mb-3">
                    Project Photos/Gallery Upload
                  </h1>
                  <div className="mt-2 mb-6">
                    <p className="dark:text-white text-sm text-gray-500">
                      {isNew
                        ? `Already uploaded photos will appear here`
                        : `Existing photos of the project:`}
                    </p>
                    <>
                      <div className="flex gap-3 items-center shadow-lg">
                        {imgArrUpdate.length > 0 &&
                          imgArrUpdate.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="flex mt-2 mb-4 items-center relative p-4"
                                  key={index}
                                >
                                  <img
                                    src={`${BaseSetting.ApiDomain}/file/images/${item}`}
                                    alt="img"
                                    className="flex-1 w-36 h-auto"
                                  />
                                  <Tooltip
                                    placement="top-end"
                                    content="Remove this image"
                                  >
                                    <IoRemoveCircle
                                      id={index}
                                      className="hover:cursor-pointer absolute top-0 right-0 bg-white rounded-2xl w-5 h-5"
                                      onClick={() => removeExistingImage(index)}
                                    />
                                  </Tooltip>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </>
                  </div>
                  <div className="gap-4 mb-5">
                    <FileUpload form={createProjectFormik} />
                  </div>
                </div>

                <div className="flex gap-4 items-center">
                  <div className="flex-1 submit">
                    <Button
                      disabled={fetching}
                      type="submit"
                      onSubmit={createProjectFormik.handleSubmit}
                    >
                      {fetching ? <Loading /> : ""}
                      {isNew ? "Create new Project" : "Update Project"}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {err.show ? (
                  <div className="mt-2 text-[#da232aff] text-sm">
                    {err.error}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default UploadProjectContainer;
