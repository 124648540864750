import React, { useEffect, useState } from "react";
import AdminApi from "../../../../api/AdminApi";
import { Link } from "react-router-dom";
import { PriceConvert } from "../../../../utils/PriceConvert";
import { BaseSetting } from "../../../../utils/settings";

const MiniFeaturedProperties = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const getFeaturedProperties = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getFeaturedProperties();
        if (response) {
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  console.log(data);

  useEffect(() => {
    getFeaturedProperties();
  }, []);
  return (
    <>
      <div className="m-4 md:m-0 featured-properties p-7 pb-2 shadow-defaultShadow bg-white dark:bg-gray-800 border border-gray-200 rounded-lg  dark:border-gray-700">
        <div className="mb-6">
          <h1 className="mb-5 text-base font-medium text-gray-900 dark:text-white">
            Featured Properties
          </h1>
          <div className="dark:text-white">
            {data &&
              data.map((item, index) => {
                return (
                  <Link to={`/single-property/${item._id}`}>
                    <div className="flex justify-start gap-2 items-center mb-3 hover:text-mainBlue">
                      <img
                        className="mr-3 w-28"
                        src={`${BaseSetting.ApiDomain}/file/images/${item.images[0]}`}
                        alt="img"
                      />
                      <div>
                        <h5 className="overflow-custom text-sm mb-[5px] font-semibold hover:text-blue-500">
                          {item.name}
                        </h5>
                        <p className="text-blue font-semibold text-xs">
                          ₹ {PriceConvert(item?.priceDetails?.expectedPrice)}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniFeaturedProperties;
