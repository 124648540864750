import React, { useState, useEffect } from "react";
import Select from "react-select";
import stateApis from "../../../../api/statesApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const LocalityCrudComponent = () => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [localities, setLocalities] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const [newLocalityName, setNewLocalityName] = useState("");
  const auth = useSelector((state) => state.auth.authData);

  useEffect(() => {
    const fetchCities = async () => {
      const citiesData = await stateApis.getAllCities();
      if (citiesData) {
        setCities(citiesData);
      }
    };

    fetchCities();
  }, []);

  const fetchLocalities = async (cityId) => {
    const localitiesData = await stateApis.getLocalityByCity(
      cityId,
      auth?.token
    );
    if (localitiesData) {
      setLocalities(localitiesData.localities);
    }
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    fetchLocalities(selectedOption?.value);
  };

  const handleAddLocality = async () => {
    if (!selectedCity || !newLocalityName) return;

    try {
      await stateApis.createLocality(
        newLocalityName,
        selectedCity.value,
        auth?.token
      );

      // Refresh the localities list after adding
      fetchLocalities(selectedCity.value);
      setNewLocalityName("");
      toast(`${newLocalityName} added`);
    } catch (error) {
      console.error("Error adding locality", error);
    }
  };

  const handleDeleteLocality = async () => {
    if (!selectedCity || !selectedLocality) return;

    try {
      await stateApis.deleteLocality(selectedLocality.value, auth?.token);

      // Refresh the localities list after deleting
      fetchLocalities(selectedCity.value);
      setSelectedLocality(null);
      setNewLocalityName("");
      toast(`${selectedLocality.label} deleted`);
    } catch (error) {
      console.error("Error deleting locality", error);
    }
  };

  return (
    <div className="w-[70%] mx-auto mt-8 p-4 bg-gray-100 rounded shadow">
      <h1 className="text-2xl font-bold mb-4">Add/Delete Locality </h1>
      <Select
        options={cities.map((city) => ({ value: city._id, label: city.name }))}
        value={selectedCity}
        onChange={handleCityChange}
        isClearable
        className="mb-4"
      />
      <Select
        options={localities.map((locality) => ({
          value: locality._id,
          label: locality.name,
        }))}
        value={selectedLocality}
        onChange={(value) => setSelectedLocality(value)}
        isClearable
        className="mb-4"
      />
      <div className="flex space-x-4">
        <input
          type="text"
          placeholder="New Locality Name"
          value={newLocalityName}
          onChange={(e) => setNewLocalityName(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
        <button
          onClick={handleAddLocality}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Add
        </button>
        <button
          onClick={handleDeleteLocality}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default LocalityCrudComponent;
