/* eslint-disable default-case */
import React from "react";
import MyMapComponent from "./MyMapComponent";
import AdvanceOptions from "./AdvanceOptions";
import { AdvanceSearchContext } from "../SearchTabs/AdvanceSearchContext";

const AdavanceSearchResult = () => {
  const { searchData, setSearchData } = React.useContext(AdvanceSearchContext);
  console.log(searchData);
  const coordinates =
    searchData.length > 0 &&
    searchData.map((item, index) => {
      return {
        id: index,
        propertyLink: item._id,
        name: item.name,
        type: item.type,
        city: item.localityDetails.city,
        position: {
          lat: Number(item.localityDetails.lat),
          lng: Number(item.localityDetails.lng),
        },
      };
    });
  console.log(coordinates);

  return (
    <div className="md:flex justify-between">
      {coordinates.length > 0 && (
        <div className="md:fixed md:flex-1 md:h-[95%] h-[500px] w-full md:w-1/2 shadow-defaultShadow">
          <MyMapComponent allCoord={coordinates} />
        </div>
      )}
      <div className="md:flex-1"></div>
      <div className="flex-1 bg-[#f7f7f7] dark:bg-darkMap shadow-defaultShadow md:w-1/2">
        <AdvanceOptions searchData={searchData} />
      </div>
    </div>
  );
};

export default AdavanceSearchResult;
