import React from "react";
import SimilarProperties from "../PropertyViewTypes/SimilarProperties/SimilarProperties";
import MiniFeaturedProperties from "../PropertyViewTypes/MiniFeaturedProperties/MiniFeaturedProperties";
import Features from "./Features";
import MapWithOptions from "./MapWithOptions";
import BuilderWidget from "../../common/BuilderWidget";
import { Link } from "react-router-dom";
import { BsCalendar, BsDownload } from "react-icons/bs";
import { FaBed, FaShower } from "react-icons/fa";
import moment from "moment";
import LeadsButton from "../../common/GenerateLeads/LeadsButton";

//Google Map Utils Import

const PropertyDetails = ({ data }) => {
  const center = {
    lat: data.localityDetails.lat ? Number(data.localityDetails.lat) : 26.92098,
    lng: data.localityDetails.lng ? Number(data.localityDetails.lng) : 75.79422,
  };
  const zoom = 13;

  return (
    <>
      {data.name ? (
        <>
          <div className="md:flex justify-between items-start flex-nowrap">
            <div className="w-full md:w-2/3 mr-6">
              <div className="overview">
                <div className="overview shadow-defaultShadow p-6 mb-8 dark:bg-gray-800">
                  <h1 className="text-lg hover:to-blue-500 mb-3 font-semibold dark:text-white">
                    Overview
                  </h1>
                  <div className="short-desc flex-wrap md:flex-nowrap flex gap-1 md:gap-4 justify-between text-sm items-end dark:text-white">
                    <div className="mb-1 w-1/2 flex flex-1 gap-1 justify-start">
                      <h5 className="font-semibold mb-1 flex gap-1 items-center">
                        <BsCalendar className="inline text-lg mr-1" />
                        Available
                      </h5>
                      <p>{moment(data?.availableDate).format("l")}</p>
                    </div>
                    <div className="mb-1 w-1/2 flex flex-1 gap-1 justify-start">
                      <h5 className="font-semibold mb-1 flex gap-1 items-center">
                        <FaBed className="inline text-lg mr-1" />
                        Bedrooms :
                      </h5>
                      <p>{data?.bhkType}</p>
                    </div>
                    <div className="mb-1 w-1/2 flex flex-1 gap-1 justify-start">
                      <h5 className="font-semibold mb-1 flex gap-1 items-center">
                        <FaShower className="inline text-lg mr-1" />
                        Bathrooms :
                      </h5>
                      <p>{data?.details?.bathroom}</p>
                    </div>
                    <div className="mb-1 w-1/2 flex flex-1 gap-1 justify-start">
                      <h5 className="font-semibold mb-1 flex gap-1 items-center">
                        <FaShower className="inline text-lg mr-1" />
                        Carpet Area:
                      </h5>
                      <p>{data?.carpetArea} sqft</p>
                    </div>
                  </div>
                </div>
                <div className="p-6 md:hidden mb-8 shadow-defaultShadow dark:bg-gray-800">
                  <LeadsButton propertyId={data?._id} />
                </div>
                <div className="p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
                  <h1 className=" mb-3 font-bold dark:text-white">
                    Description
                  </h1>
                  <p className="text-para text-sm dark:text-white">
                    {data?.description}
                  </p>
                  <h1 className="mt-7 mb-1 font-bold dark:text-white">
                    Documents
                  </h1>
                  <div className="downloads">
                    <Link to="#">
                      {" "}
                      <p className="text-para text-sm mb-1 dark:text-white ">
                        Energetic-Certificate-PDF10{" "}
                        <BsDownload className="inline" />{" "}
                      </p>
                    </Link>
                    <Link to="#">
                      {" "}
                      <p className="text-para text-sm mb-1 dark:text-white ">
                        Energetic-Certificate-PDF10{" "}
                        <BsDownload className="inline" />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="addressAndDetails">
                <>
                  <div className="p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
                    <h1 className="mb-5 font-bold dark:text-white">Address</h1>
                    <div className="flex flex-wrap justify-between gap-2">
                      <p className="text-sm w-[32%] font-semibold dark:text-white">
                        Address:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?.localityDetails?.street}
                        </span>
                      </p>
                      <p className="text-sm w-[32%] font-semibold dark:text-white">
                        City:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?.localityDetails?.city}
                        </span>
                      </p>
                      <p className="text-sm w-[32%] font-semibold dark:text-white">
                        Area:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          Greenville
                        </span>
                      </p>
                      <p className="text-sm w-[32%] font-semibold dark:text-white">
                        State/County:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          Jaipur
                        </span>
                      </p>
                      <p className="text-sm w-[32%] font-semibold dark:text-white">
                        Zip:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          302012
                        </span>
                      </p>
                      <p className="text-sm w-[32%] font-semibold dark:text-white">
                        Country:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          India
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
                    <h1 className="mb-5 font-bold dark:text-white">Details</h1>
                    <div className="flex flex-wrap justify-between gap-3">
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Property Id:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?._id}
                        </span>
                      </p>
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Property Carpet Size:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?.carpetArea}
                        </span>
                      </p>
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Property BuiltUpArea:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?.builtUpArea}
                        </span>
                      </p>
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Water Supply:{" "}
                        <span className="text-para  font-normal dark:text-white">
                          {data?.details?.waterSupply}
                        </span>
                      </p>
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Facing:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?.facing}
                        </span>
                      </p>
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Floor Type:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?.floorType}
                        </span>
                      </p>
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Roofing:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          India
                        </span>
                      </p>
                      <p className=" text-sm w-[32%] font-semibold dark:text-white">
                        Parking Size:{" "}
                        <span className=" text-para font-normal dark:text-white">
                          {data?.details?.parking}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              </div>
              <div className="locationOnMap p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
                <h1 className="mb-5 font-bold dark:text-white">
                  Location on Map
                </h1>
                <div className="w-full h-128">
                  <MapWithOptions
                    center={center}
                    name={data.name}
                    zoom={zoom}
                  />
                </div>
              </div>

              <Features data={data} />

              <div className="similar properties p-6 lg:p-0">
                <h1 className="text-xl font-bold dark:text-white">
                  Similar Listings:
                </h1>
                <SimilarProperties
                  filterOptions={{
                    availableType: data ? data.availableType : "",
                  }}
                />
              </div>
              {/* Overview Details Left Side Div Ends here */}
            </div>

            {/*
             *************** Right Sidebar Starts here *****************
             */}
            <div className="block md:flex-1 p-6 md:p-0">
              <div className="mb-4">
                <LeadsButton propertyId={data._id} />
              </div>
              <div className="mb-4">
                <BuilderWidget data={data} />
              </div>
              <MiniFeaturedProperties />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PropertyDetails;
