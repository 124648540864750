import { Button, Checkbox, Label, Textarea, TextInput } from "flowbite-react";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AdminApi from "../../../../api/AdminApi";
import Loading from "../../../../utils/Loading";
import { FaArrowRight, FaSave } from "react-icons/fa";
import ListingButton from "../../../common/GenerateLeads/ListingButton";
import IsRequired from "../../../common/RequiredFieldMark";
import Select from "react-select";

/**
 * @async
 * @description Property Details Container
 * @param {useFormik} createPropertyFormik
 * @parent UploadPropertyContainer
 */
const PropertyDetails = ({
  form,
  projectID,
  fetching,
  handleButtonClick,
  isNew,
  validationSchema,
}) => {
  const isInitialMount = useRef(true);
  const Auth = useSelector((state) => state.auth.authData);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.auth.authData);
  const [selectedFloorType, setSelectedFloorType] = useState([]);
  const floorOptions = [
    { value: "Mosaic", label: "Mosaic" },
    { value: "Wooden", label: "Wooden" },
    { value: "Cement", label: "Cement" },
    { value: "Marble", label: "Marble/Granite" },
    { value: "Vitrified", label: "Vitrified Tiles" },
  ];

  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  async function requestForListing() {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        setIsLoading(true);
        const response = await AdminApi.requestForListing(Auth.token);
        if (response) {
          toast("Our Team will soon connect with you within 24 Hours", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  }

  const isDisabled = () => {
    return [
      "availableType",
      "projectId",
      "type",
      "bhkType",
      "superBuiltupArea",
      "builtUpArea",
      "carpetArea",
      "propertyAge",
      "facing",
      "floorType",
    ].filter((key) => {
      return form.errors[key];
    });
  };

  console.log(form, "createPropertyFormik");

  const handleFloorType = (selectedOptions) => {
    setSelectedFloorType([...selectedOptions]);
    form.values.floorType = selectedOptions;
  };

  return (
    <>
      <h2 className=" dark:text-white mb-5 font-semibold text-xl text-blue-600 border-b border-gray-700-800 pb-5">
        Property Details
      </h2>
      {projectID.length > 0 ? (
        <>
          <div className="options justify-between">
            {/* <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Property Name" />
                </div>
                <div className="flex-1 w-full">
                  <TextInput
                    id="name"
                    type="text"
                    placeholder="type your property name"
                    value={form.values.name}
                    onChange={form.handleChange}
                    required={true}
                    disabled={true}
                  />
                  {form.errors.name ? (
                    <p className="text-sm text-red-600 mt-2">
                      {form.errors.name}
                    </p>
                  ) : null}
                </div>
              </div>
            </div> */}
            <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="availableType"
                    value={
                      <>
                        <IsRequired
                          schema={validationSchema}
                          fieldName="availableType"
                          fieldLabel={"Is it for Sale or Rent?"}
                        />
                      </>
                    }
                  />
                </div>
                <div className="flex">
                  <select
                    id="availableType"
                    value={form.values.availableType}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" selected disabled key={0}>
                      Select from here
                    </option>
                    <option value="Buy" key={1}>
                      Buy/Sell
                    </option>
                    <option value="Rent" key={2}>
                      Rent
                    </option>
                  </select>
                </div>
                {form.errors.availableType ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.availableType}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="projectId"
                    value={
                      <>
                        {" "}
                        <IsRequired
                          schema={validationSchema}
                          fieldName="projectId"
                          fieldLabel={"Society / Project Name"}
                        />
                      </>
                    }
                  />
                </div>
                <div className="flex">
                  <select
                    id="projectId"
                    value={form.values.projectId}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" selected hidden disabled key={0}>
                      Select from options
                    </option>
                    {projectID.map((state, index) => {
                      return (
                        <option value={state._id} key={index}>
                          {state.name}
                        </option>
                      );
                    })}
                    {/* <option value={"notListedHere"} key={"NotListed"}>
                      Not Listed Here
                    </option> */}
                  </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <Checkbox
                    id="projectNotListed"
                    checked={form.values.projectNotListed}
                    onChange={form.handleChange}
                    value={form.values.projectNotListed}
                  />
                  <Label htmlFor="remember">Project Not listed above?</Label>
                </div>
                {form.errors.projectId ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.projectId}
                  </p>
                ) : null}
              </div>
              {form.values.projectNotListed && (
                <div className="flex-1">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="customProjectName"
                      value="Project Not Listed above? Add your here"
                    />
                  </div>
                  <div className="flex-1 w-full">
                    <TextInput
                      id="customProjectName"
                      type="text"
                      placeholder="type your Project name"
                      value={form.values.customProjectName}
                      onChange={form.handleChange}
                      // required={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="type"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="type"
                        fieldLabel={"Property Type"}
                      />
                    }
                  />
                </div>
                <div className="flex">
                  <select
                    id="type"
                    value={form.values.type}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" selected hidden disabled key={0}>
                      {" "}
                      Type of Property
                    </option>
                    <option value={"Apartment"}>Apartment</option>
                    <option value={"Villa"}>Villa</option>
                    <option value={"Flat"}>Flat</option>
                    <option value={"House"}>Independent House</option>
                  </select>
                </div>
                {form.errors.type ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.type}
                  </p>
                ) : null}
              </div>
              {/* <div className="flex-1">
                <div className="mb-2 block">
                  <Label htmlFor="availableType" value="Ownership Type" />
                </div>
                <div className="flex">
                  <select
                    id="availableType"
                    value={form.values.availableType}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value disabled>
                      Ownership Type
                    </option>
                    <option value={"SelfOwned"}>Self Owned</option>
                    <option value={"BuilderOwned"}>Builder Owned</option>
                    <option value={"Lease"}>Lease</option>
                  </select>
                </div>
              </div> */}
            </div>
            <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="bhkType"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="bhkType"
                        fieldLabel={"BHK Type"}
                      />
                    }
                  />
                </div>
                <div className="flex">
                  <select
                    id="bhkType"
                    value={form.values.bhkType}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value disabled>
                      BHK Type
                    </option>
                    <option value={"1BHK"}>1 BHK</option>
                    <option value={"2BHK"}>2 BHK</option>
                    <option value={"3BHK"}>3 BHK</option>
                    <option value={"3BHK+"}>3 BHK+</option>
                  </select>
                </div>
                {form.errors.bhkType ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.bhkType}
                  </p>
                ) : null}
              </div>
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="superBuiltupArea"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="superBuiltupArea"
                        fieldLabel={"Super Builtup Area (sq. ft)"}
                      />
                    }
                  />
                </div>
                <div className="flex-1 w-full">
                  <TextInput
                    id="superBuiltupArea"
                    type="number"
                    placeholder="area in sq ft."
                    required={true}
                    value={form.values.superBuiltupArea}
                    onChange={form.handleChange}
                  />
                </div>
                {form.errors.superBuiltupArea ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.superBuiltupArea}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="builtUpArea"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="builtUpArea"
                        fieldLabel={"Built Up Area (sq. ft)"}
                      />
                    }
                  />
                </div>
                <div className="flex-1 w-full">
                  <TextInput
                    id="builtUpArea"
                    type="number"
                    placeholder="area in sq ft."
                    required={true}
                    value={form.values.builtUpArea}
                    onChange={form.handleChange}
                  />
                </div>
                {form.errors.builtUpArea ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.builtUpArea}
                  </p>
                ) : null}
              </div>
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="carpetArea"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="carpetArea"
                        fieldLabel={"Carpet Area (sq. ft)"}
                      />
                    }
                  />
                </div>
                <div className="flex-1 w-full">
                  <TextInput
                    id="carpetArea"
                    type="number"
                    placeholder="area in sq ft."
                    required={true}
                    value={form.values.carpetArea}
                    onChange={form.handleChange}
                  />
                </div>
                {form.errors.carpetArea ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.carpetArea}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="propertyAge"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="propertyAge"
                        fieldLabel={"Property Age"}
                      />
                    }
                  />
                </div>
                <div className="flex">
                  <select
                    id="propertyAge"
                    value={form.values.propertyAge}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value disabled selected defaultValue={""}>
                      Property Age
                    </option>
                    <option value={"UnderConstruction"}>
                      Under Construction
                    </option>
                    <option value={"LessThan1"}>Less than a year</option>
                    <option value={"1to3Year"}>1 to 3 year</option>
                    <option value={"3to5Year"}>3 to 5 Year</option>
                    <option value={"5to10Year"}>5 to 10 Year</option>
                    <option value={"MoreThan10"}>More than 10 year</option>
                  </select>
                </div>
                {form.errors.propertyAge ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.propertyAge}
                  </p>
                ) : null}
              </div>
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label
                    htmlFor="facing"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="facing"
                        fieldLabel={"Facing"}
                      />
                    }
                  />
                </div>
                <div className="flex">
                  <select
                    id="facing"
                    value={form.values.facing}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option disabled>Facing</option>
                    <option value={"North"}>North</option>
                    <option value={"South"}>South</option>
                    <option value={"East"}>East</option>
                    <option value={"West"}>West</option>
                    <option value={"NorthEast"}>North-East</option>
                    <option value={"SouthEast"}>South-East</option>
                    <option value={"NorthWest"}>North-West</option>
                    <option value={"SouthWest"}>South-West</option>
                    <option value={false}>Don't Know</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex gap-4 mb-5 flex-wrap">
              <div className="w-full">
                <div className="mb-2 block">
                  <Label
                    htmlFor="floorType"
                    value={
                      <IsRequired
                        schema={validationSchema}
                        fieldName="floorType"
                        fieldLabel={"Floor Type"}
                      />
                    }
                  />
                </div>
                <div className="flex w-full">
                  <Select
                    options={floorOptions}
                    isMulti
                    onChange={handleFloorType}
                    placeholder="Select floor finishing..."
                    className="w-full"
                    id="floorType"
                    value={selectedFloorType}
                  />
                </div>
                {form.errors.floorType ? (
                  <p className="text-sm text-red-600 mt-2">
                    {form.errors.floorType}
                  </p>
                ) : null}
              </div>
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label htmlFor="certified" value="Your floor" />
                </div>
                <div className="flex">
                  <select
                    id="floor"
                    value={form.values.floor}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option disabled>Which Floor</option>
                    <option value={"dontknow"}>Don't Know</option>
                    {[...Array(50)].map((x, i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label htmlFor="totalFloor" value="Total Floor" />
                </div>
                <div className="flex">
                  <select
                    id="totalFloor"
                    value={form.values.totalFloor}
                    onChange={form.handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option disabled>Total Number of Floors in Building</option>
                    <option value={"dontknow"}>Don't Know</option>
                    {[...Array(50)].map((x, i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex gap-4 mb-5">
              <div className="flex-1">
                <div className="mb-2 block">
                  <Label htmlFor="description" value="Property Description" />
                </div>
                <div className="flex-1 w-full">
                  <Textarea
                    id="description"
                    value={form.values.description}
                    onChange={form.handleChange}
                    rows={2}
                    type="text"
                    placeholder="type your property description"
                    required={true}
                  />
                  {/* {form.errors.description ? (
                    <p className="text-sm text-red-600 mt-2">
                      {form.errors.description}
                    </p>
                  ) : null} */}
                </div>
              </div>
            </div>
            <div className="flex gap-4 mt-10 mb-5 justify-between">
              <Button
                disabled={fetching || isDisabled()?.length > 0}
                onClick={() => handleButtonClick("locality")}
                className="flex items-end"
              >
                {fetching ? <Loading /> : ""}
                Next to Locality Details
                <FaArrowRight className="text-sm ml-2" />
              </Button>
              {isNew ? (
                <></>
              ) : (
                <>
                  <Button
                    color={`gray`}
                    className=""
                    disabled={fetching}
                    onClick={form.handleSubmit}
                  >
                    <FaSave className="mr-3 h-4 w-4" /> Save All
                  </Button>
                </>
              )}
            </div>
            <ListingButton />
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-2">
            <Loading /> <h1 className="dark:text-white text-lg ">Loading...</h1>
          </div>
        </>
      )}
    </>
  );
};

export default PropertyDetails;
