import { Tabs } from "flowbite-react";
import React, { useRef, useState } from "react";
import { HiHome, HiOutlineHome } from "react-icons/hi";
import SearchOptions from "./SearchOptions";

const SearchTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  console.log(tabsRef, activeTab);

  return (
    <>
      <Tabs
        className="bg-white rounded-lg dark:bg-gray-800 dark:text-white shadow-lg"
        ref={tabsRef}
        onActiveTabChange={(tab) => setActiveTab(tab)}
        aria-label="Tabs with underline"
      >
        <Tabs.Item
          active={activeTab === 0}
          icon={HiHome}
          title="Buy"
          className="w-96 shadow-defaultShadow"
        >
          <SearchOptions type={0} />
        </Tabs.Item>
        <Tabs.Item active={activeTab === 1} icon={HiOutlineHome} title="Rent">
          <SearchOptions type={1} />
        </Tabs.Item>
      </Tabs>
    </>
  );
};

export default SearchTabs;
