import { Carousel } from "flowbite-react";
import React from "react";
import { BaseSetting } from "../../../utils/settings";

const Slider = ({ data }) => {
  const dummyData = [
    {
      id: 0,
      propertyAreaImage:
        "https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 1,
      propertyAreaImage:
        "https://images.unsplash.com/photo-1565402170291-8491f14678db?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1417&q=80",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 2,
      propertyAreaImage:
        "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
    {
      id: 3,
      propertyAreaImage:
        "https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=867&q=80",
      propertyAreaName: `Jaipur`,
      propertyAreaCount: 20,
    },
  ];
  return (
    <div className="w-full">
      <div className="h-56 sm:h-64 xl:h-128 2xl:h-128">
        {data?.images?.length > 0 ? (
          <Carousel slideInterval={5000}>
            {data.images.map((item) => {
              return (
                <img
                  src={`${BaseSetting.ApiDomain}/file/images/${item}`}
                  alt="mit_property_images"
                />
              );
            })}
          </Carousel>
        ) : (
          <>
            <h1 className="dark:text-white text-xl flex justify-center items-center pt-10">
              No images found for this property
            </h1>
          </>
        )}
      </div>
    </div>
  );
};

export default Slider;
