import React from "react";
import AddLocalitiesComp from "../../../../components/Dashboard/Admin/AddLocalitiesComp/AddLocalitiesComp";
import CityCrudComponent from "../../../../components/Dashboard/Admin/AddCities/AddCities";

const AddLocalities = () => {
  return (
    <div className="flex flex-col gap-8">
      <CityCrudComponent />
      <AddLocalitiesComp />
    </div>
  );
};

export default AddLocalities;
