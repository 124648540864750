import React, { useState, useEffect } from "react";
import Select from "react-select";
import stateApis from "../../../../api/statesApi";
import { useSelector } from "react-redux";

const StateCitySelector = ({
  selectedStates,
  setSelectedStates,
  selectedCities,
  setSelectedCities,
  selectedLocalities,
  setSelectedLocalities,
  city = false,
  locality = false,
}) => {
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [localitiesData, setLocalitiesData] = useState([]);

  const auth = useSelector((state) => state.auth.authData);

  useEffect(() => {
    // Fetch states
    getStates();
  }, []);

  const getStates = async () => {
    const response = await stateApis.getAllStates(auth?.token);
    setStatesData(
      response.map((state) => {
        return { label: state.name, value: state.name, id: state._id };
      })
    );
  };

  const handleStateChange = async (selectedOptions) => {
    setSelectedStates(selectedOptions);
    const selectedStatedId = selectedOptions.map((option) => option.id);
    setSelectedCities(
      selectedCities.filter((city) => {
        return selectedStatedId.includes(city.stateId);
      })
    );

    // Fetch cities for the selected state
    const selectedStateValues = selectedOptions.map((state) => state.value);
    if (selectedStateValues.length > 0) {
      const response = await processArrayAsync(selectedOptions);

      setCitiesData(
        response
          .map((state) =>
            state.cities.map((c) => {
              return {
                label: c.name,
                value: c.name,
                id: c._id,
                stateId: state._id,
                stateName: state.name,
              };
            })
          )
          .flat()
      );
    }
  };

  const handleCityChange = async (selectedOptions) => {
    setSelectedCities(selectedOptions);

    const selectedCityId = selectedOptions.map((option) => option.id);
    setSelectedLocalities(
      selectedLocalities.filter((city) => {
        return selectedCityId.includes(city.id);
      })
    );

    const selectedCityValues = selectedOptions.map((city) => city.value);
    if (selectedCityValues.length > 0) {
      const response = await processArrayAsync(selectedOptions, "city");

      setLocalitiesData(
        response
          .map((city) =>
            city.localities.map((c) => {
              return {
                label: c.name,
                value: c.name,
                id: c._id,
                cityName: city.name,
              };
            })
          )
          .flat()
      );
    }
  };

  const handleLocalityChange = (selectedOptions) => {
    setSelectedLocalities(selectedOptions);
  };

  const getCityOptions = () => {
    return citiesData;
  };

  const getLocalityOptions = () => {
    return localitiesData;
  };

  // Example asynchronous function using Axios
  function asyncAxiosOperation(item, type) {
    if (type === "state") {
      return stateApis
        .getCityByState(item.id, auth?.token)
        .then((response) => {
          console.log(`Processed: ${item}`);
          return response;
        })
        .catch((error) => {
          console.error(`Error processing ${item}:`, error.message);
          throw error;
        });
    }
    if (type === "city") {
      return stateApis
        .getLocalityByCity(item.id, auth?.token)
        .then((response) => {
          console.log(`Processed: ${item}`);
          return response;
        })
        .catch((error) => {
          console.error(`Error processing ${item}:`, error.message);
          throw error;
        });
    }
  }

  async function processArrayAsync(dataArray, type = "state") {
    try {
      const results = await Promise.all(
        dataArray?.map((item) => asyncAxiosOperation(item, type))
      );
      return results;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <Select
        className="w-full"
        options={statesData}
        value={selectedStates}
        onChange={handleStateChange}
        isMulti
        placeholder="Select States"
      />

      {city
        ? selectedStates.length > 0 && (
            <Select
              className="w-full"
              options={getCityOptions()}
              value={selectedCities}
              onChange={handleCityChange}
              isMulti
              placeholder="Select Cities"
              getOptionLabel={(option) => (
                <>
                  {option.label}{" "}
                  <span className="text-gray-400 float-right">
                    {option.stateName}{" "}
                  </span>
                </>
              )}
            />
          )
        : null}

      {locality
        ? selectedCities.length > 0 && (
            <Select
              className="w-full"
              options={getLocalityOptions()}
              value={selectedLocalities}
              onChange={handleLocalityChange}
              isMulti
              placeholder="Select Locality"
              getOptionLabel={(option) => (
                <>
                  {option.label}{" "}
                  <span className="text-gray-400 float-right">
                    {option.cityName}{" "}
                  </span>
                </>
              )}
            />
          )
        : null}
    </div>
  );
};

export default StateCitySelector;
