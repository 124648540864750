import React from 'react'
import DashboardHome from '../../components/Dashboard/DashboardHome';

const index = () => {
  return (
    <>
    <div className=''>
      <DashboardHome />
    </div>
    </>
  )
}

export default index;