import { Button, Label, Textarea, ToggleSwitch } from "flowbite-react";
import React from "react";
import { BsPower, BsWater } from "react-icons/bs";
import { CgGames } from "react-icons/cg";
import {
  FaBasketballBall,
  FaChild,
  FaFire,
  FaIntercom,
  FaOm,
  FaShoppingBag,
  FaSpa,
  FaSteam,
  FaSwimmingPool,
  FaWifi,
} from "react-icons/fa";
import {
  GiCctvCamera,
  GiClubs,
  GiCricket,
  GiElevator,
  GiGate,
  GiGymBag,
  GiLift,
  GiPipes,
  GiPoolTableCorner,
  GiTennisBall,
  GiTheater,
} from "react-icons/gi";
import { HiLibrary } from "react-icons/hi";
import { IoCafe, IoPeopleOutline, IoSquareSharp } from "react-icons/io5";
import {
  MdKitchen,
  MdLocalLaundryService,
  MdMeetingRoom,
  MdOutlineMeetingRoom,
  MdPark,
  MdRoom,
  MdSmokeFree,
} from "react-icons/md";
import { RiCarWashingFill } from "react-icons/ri";

const Amenities = ({ form }) => {
  return (
    <>
      <h2 className=" dark:text-white mb-5 font-semibold text-xl text-blue-600 border-b border-gray-700-800 pb-5">
        Provide additional details about your property
      </h2>
      <div className="options justify-between">
        <div className="amenities mt-6">
          <h1 className="text-lg font-bold dark:text-white mb-2">Amenities</h1>
          <div className="flex gap-4 mb-5 mt-7">
            <p className="dark:text-white text-para text-base">
              Select the available amenities
            </p>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <BsWater className="dark:text-white text-blue-600" />
                  <Label
                    htmlFor="waterSupplyAllTime"
                    value="24*7 Water Supply"
                  />
                </div>
                <ToggleSwitch
                  id="amenities.waterSupplyAllTime"
                  name="amenities.waterSupplyAllTime"
                  checked={
                    form.values.amenities.waterSupplyAllTime
                  }
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.waterSupplyAllTime",
                      checked ? true : false
                    );
                  }}
                  value={
                    form.values.amenities.waterSupplyAllTime
                  }
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <BsPower className="dark:text-white text-blue-600" />
                  <Label htmlFor="powerBackup" value="Power Backup" />
                </div>
                <ToggleSwitch
                  id="amenities.powerBackup"
                  name="amenities.powerBackup"
                  checked={form.values.amenities.powerBackup}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.powerBackup",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.powerBackup}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiCctvCamera className="dark:text-white text-blue-600" />
                  <Label htmlFor="cctv" value="CCTV" />
                </div>
                <ToggleSwitch
                  id="amenities.cctv"
                  name="amenities.cctv"
                  checked={form.values.amenities.cctv}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.cctv",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.cctv}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiTheater className="dark:text-white text-blue-600" />
                  <Label htmlFor="theaterRoom" value="Theater Room" />
                </div>
                <ToggleSwitch
                  id="amenities.theaterRoom"
                  name="amenities.theaterRoom"
                  checked={form.values.amenities.theaterRoom}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.theaterRoom",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.theaterRoom}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <IoCafe className="dark:text-white text-blue-600" />
                  <Label htmlFor="cafeteria" value="Cafeteria" />
                </div>
                <ToggleSwitch
                  id="amenities.cafeteria"
                  name="amenities.cafeteria"
                  checked={form.values.amenities.cafeteria}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.cafeteria",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.cafeteria}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <HiLibrary className="dark:text-white text-blue-600" />
                  <Label htmlFor="library" value="Library" />
                </div>
                <ToggleSwitch
                  id="amenities.library"
                  name="amenities.library"
                  checked={form.values.amenities.library}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.library",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.library}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <MdMeetingRoom className="dark:text-white text-blue-600" />
                  <Label
                    htmlFor="businessMeetingRooms"
                    value="Business Meeting Rooms"
                  />
                </div>
                <ToggleSwitch
                  id="amenities.businessMeetingRooms"
                  name="amenities.businessMeetingRooms"
                  checked={
                    form.values.amenities.businessMeetingRooms
                  }
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.businessMeetingRooms",
                      checked ? true : false
                    );
                  }}
                  value={
                    form.values.amenities.businessMeetingRooms
                  }
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <MdOutlineMeetingRoom className="dark:text-white text-blue-600" />
                  <Label htmlFor="guestRooms" value="Guest Rooms" />
                </div>
                <ToggleSwitch
                  id="amenities.guestRooms"
                  name="amenities.guestRooms"
                  checked={form.values.amenities.guestRooms}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.guestRooms",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.guestRooms}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <MdRoom className="dark:text-white text-blue-600" />
                  <Label htmlFor="banquetHall" value="Banquet Hall" />
                </div>
                <ToggleSwitch
                  id="amenities.banquetHall"
                  name="amenities.banquetHall"
                  checked={form.values.amenities.banquetHall}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.banquetHall",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.banquetHall}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaShoppingBag className="dark:text-white text-blue-600" />
                  <Label htmlFor="shoppingCenter" value="Shoping Center" />
                </div>
                <ToggleSwitch
                  id="amenities.shoppingCenter"
                  name="amenities.shoppingCenter"
                  checked={form.values.amenities.shoppingCenter}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.shoppingCenter",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.shoppingCenter}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <RiCarWashingFill className="dark:text-white text-blue-600" />
                  <Label htmlFor="carWash" value="Car Wash" />
                </div>
                <ToggleSwitch
                  id="amenities.carWash"
                  name="amenities.carWash"
                  checked={form.values.amenities.carWash}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.carWash",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.carWash}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <IoPeopleOutline className="dark:text-white text-blue-600" />
                  <Label
                    htmlFor="seniorCitizenCorner"
                    value="Senior Citizen Corner"
                  />
                </div>
                <ToggleSwitch
                  id="amenities.seniorCitizenCorner"
                  name="amenities.seniorCitizenCorner"
                  checked={
                    form.values.amenities.seniorCitizenCorner
                  }
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.seniorCitizenCorner",
                      checked ? true : false
                    );
                  }}
                  value={
                    form.values.amenities.seniorCitizenCorner
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaSteam className="dark:text-white text-blue-600" />
                  <Label htmlFor="jacuzzi" value="Steam/Sauna/Jacuzzi" />
                </div>
                <ToggleSwitch
                  id="amenities.jacuzzi"
                  name="amenities.jacuzzi"
                  checked={form.values.amenities.jacuzzi}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.jacuzzi",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.jacuzzi}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaSpa className="dark:text-white text-blue-600" />
                  <Label htmlFor="spa" value="Spa" />
                </div>
                <ToggleSwitch
                  id="amenities.spa"
                  name="amenities.spa"
                  checked={form.values.amenities.spa}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.spa",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.spa}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiClubs className="dark:text-white text-blue-600" />
                  <Label htmlFor="clubHouse" value="Club House" />
                </div>
                <ToggleSwitch
                  id="amenities.clubHouse"
                  name="amenities.clubHouse"
                  checked={form.values.amenities.clubHouse}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.clubHouse",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.clubHouse}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiElevator className="dark:text-white text-blue-600" />
                  <Label htmlFor="lift" value="Lift" />
                </div>
                <ToggleSwitch
                  id="amenities.lift"
                  name="amenities.lift"
                  checked={form.values.amenities.lift}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.lift",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.lift}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaFire className="dark:text-white text-blue-600" />
                  <Label htmlFor="fireSafety" value="Fire Safety" />
                </div>
                <ToggleSwitch
                  id="amenities.fireSafety"
                  name="amenities.fireSafety"
                  checked={form.values.amenities.fireSafety}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.fireSafety",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.fireSafety}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiGate className="dark:text-white text-blue-600" />
                  <Label htmlFor="gatedSecurity" value="Gated Security" />
                </div>
                <ToggleSwitch
                  id="amenities.gatedSecurity"
                  name="amenities.gatedSecurity"
                  checked={form.values.amenities.gatedSecurity}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.gatedSecurity",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.gatedSecurity}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaIntercom className="dark:text-white text-blue-600" />
                  <Label htmlFor="intercom" value="Intercom" />
                </div>
                <ToggleSwitch
                  id="amenities.intercom"
                  name="amenities.intercom"
                  checked={form.values.amenities.intercom}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.intercom",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.intercom}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaChild className="dark:text-white text-blue-600" />
                  <Label htmlFor="playArea" value="Play Area" />
                </div>
                <ToggleSwitch
                  id="amenities.playArea"
                  name="amenities.playArea"
                  checked={form.values.amenities.playArea}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.playArea",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.playArea}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <MdPark className="dark:text-white text-blue-600" />
                  <Label htmlFor="park" value="Park" />
                </div>
                <ToggleSwitch
                  id="amenities.park"
                  name="amenities.park"
                  checked={form.values.amenities.park}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.park",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.park}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5 mt-7">
            <p className="dark:text-white text-para text-base font-semibold">
              Interior Design
            </p>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <MdLocalLaundryService className="dark:text-white text-blue-600" />
                  <Label htmlFor="laundry" value="Laundry" />
                </div>
                <ToggleSwitch
                  id="amenities.laundry"
                  name="amenities.laundry"
                  checked={form.values.amenities.laundry}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.laundry",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.laundry}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <MdKitchen className="dark:text-white text-blue-600" />
                  <Label htmlFor="modularKitchen" value="Modular Kitchen" />
                </div>
                <ToggleSwitch
                  id="amenities.modularKitchen"
                  name="amenities.modularKitchen"
                  checked={form.values.amenities.modularKitchen}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.modularKitchen",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.modularKitchen}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <MdSmokeFree className="dark:text-white text-blue-600" />
                  <Label htmlFor="smokeDetection" value="Smoke detectors" />
                </div>
                <ToggleSwitch
                  id="amenities.smokeDetection"
                  name="amenities.smokeDetection"
                  checked={form.values.amenities.smokeDetection}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.smokeDetection",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.smokeDetection}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaWifi className="dark:text-white text-blue-600" />
                  <Label htmlFor="internetProvider" value="Internet Provider" />
                </div>
                <ToggleSwitch
                  id="amenities.internetProvider"
                  name="amenities.internetProvider"
                  checked={
                    form.values.amenities.internetProvider
                  }
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.internetProvider",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.internetProvider}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiPipes className="dark:text-white text-blue-600" />
                  <Label htmlFor="gasPipeline" value="Gas Pipeline" />
                </div>
                <ToggleSwitch
                  id="amenities.gasPipeline"
                  name="amenities.gasPipeline"
                  checked={form.values.amenities.gasPipeline}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.gasPipeline",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.gasPipeline}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiLift className="dark:text-white text-blue-600" />
                  <Label htmlFor="privateLift" value="Private Lift" />
                </div>
                <ToggleSwitch
                  id="amenities.privateLift"
                  name="amenities.privateLift"
                  checked={form.values.amenities.privateLift}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.privateLift",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.privateLift}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5 mt-7">
            <p className="dark:text-white text-para text-base font-semibold">
              Sports
            </p>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <IoSquareSharp className="dark:text-white text-blue-600" />
                  <Label htmlFor="squashCourt" value="Squash Court" />
                </div>
                <ToggleSwitch
                  id="amenities.squashCourt"
                  name="amenities.squashCourt"
                  checked={form.values.amenities.squashCourt}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.squashCourt",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.squashCourt}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaSwimmingPool className="dark:text-white text-blue-600" />
                  <Label htmlFor="swimmingPool" value="Swimming Pool" />
                </div>
                <ToggleSwitch
                  id="amenities.swimmingPool"
                  name="amenities.swimmingPool"
                  checked={form.values.amenities.swimmingPool}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.swimmingPool",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.swimmingPool}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiGymBag className="dark:text-white text-blue-600" />
                  <Label htmlFor="gym" value="Gym" />
                </div>
                <ToggleSwitch
                  id="amenities.gym"
                  name="amenities.gym"
                  checked={form.values.amenities.gym}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.gym",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.gym}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaBasketballBall className="dark:text-white text-blue-600" />
                  <Label htmlFor="basketBallCourt" value="Basket Ball Court" />
                </div>
                <ToggleSwitch
                  id="amenities.basketBallCourt"
                  name="amenities.basketBallCourt"
                  checked={form.values.amenities.basketBallCourt}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.basketBallCourt",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.basketBallCourt}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiTennisBall className="dark:text-white text-blue-600" />
                  <Label htmlFor="tennisCourt" value="Tennis Court" />
                </div>
                <ToggleSwitch
                  id="amenities.tennisCourt"
                  name="amenities.tennisCourt"
                  checked={form.values.amenities.tennisCourt}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.tennisCourt",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.tennisCourt}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <FaOm className="dark:text-white text-blue-600" />
                  <Label htmlFor="yogaRoom" value="Yoga Room" />
                </div>
                <ToggleSwitch
                  id="amenities.yogaRoom"
                  name="amenities.yogaRoom"
                  checked={form.values.amenities.yogaRoom}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.yogaRoom",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.yogaRoom}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-5 mt-7">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiCricket className="dark:text-white text-blue-600" />
                  <Label htmlFor="cricketArea" value="Cricket Area" />
                </div>
                <ToggleSwitch
                  id="amenities.cricketArea"
                  name="amenities.cricketArea"
                  checked={form.values.amenities.cricketArea}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.cricketArea",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.cricketArea}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <GiPoolTableCorner className="dark:text-white text-blue-600" />
                  <Label htmlFor="snooker" value="Snooker" />
                </div>
                <ToggleSwitch
                  id="amenities.snooker"
                  name="amenities.snooker"
                  checked={form.values.amenities.snooker}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.snooker",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.snooker}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  <CgGames className="dark:text-white text-blue-600" />
                  <Label htmlFor="indoorGames" value="Indoor Games" />
                </div>
                <ToggleSwitch
                  id="amenities.indoorGames"
                  name="amenities.indoorGames"
                  checked={form.values.amenities.indoorGames}
                  onChange={(checked) => {
                    form.setFieldValue(
                      "amenities.indoorGames",
                      checked ? true : false
                    );
                  }}
                  value={form.values.amenities.indoorGames}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex gap-4 mt-10 mb-5 ">
          <Button onClick={form.handleSubmit}>Save All</Button>
        </div> */}
      </div>
    </>
  );
};

export default Amenities;