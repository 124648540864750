import React from "react";
import MiniFeaturedProperties from "../../Website/PropertyViewTypes/MiniFeaturedProperties/MiniFeaturedProperties";

const ResellerDashboardConatiner = () => {
  return (
    <>
      <div className="block md:flex gap-6 w-full mb-8">
        <div className="flex-1 mb-4 md:m-0 p-6 rounded-lg bg-white shadow-defaultShadow dark:bg-gray-800">
          <MiniFeaturedProperties />
        </div>
        <div className="flex-1 p-6 rounded-lg bg-white shadow-defaultShadow dark:bg-gray-800">
          <MiniFeaturedProperties />
        </div>
      </div>
    </>
  );
};

export default ResellerDashboardConatiner;
