import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { Button, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import Loading from "../../../../utils/Loading";
import moment from "moment";
import AdminApi from "../../../../api/AdminApi";
import { differenceBy } from "lodash";
import { toast } from "react-toastify";
import { BsEye } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";

const AllLeadsContainer = () => {
  const Auth = useSelector((state) => state.auth.authData);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  console.log(selectedRows);
  const { theme, setTheme } = React.useContext(ThemeContext);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    async function handleDelete() {
      if (
        window.confirm(
          `Are you sure you want to delete Property:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        try {
          if (navigator.onLine) {
            setErr({
              error: "",
              show: false,
            });
            const response = await AdminApi.deleteProperty(
              selectedRows[0]._id,
              Auth.token
            );
            if (response) {
              toast("Success: Property Deleted!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setIsLoading(false);
              setToggleCleared(!toggleCleared);
              setSelectedRows([]);
              setData(differenceBy(data, selectedRows, "name"));
            } else {
              setErr({ error: response.data.data, show: true });
              setIsLoading(false);
              return false;
            }
          } else {
            alert("You`re offline");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Error loading data");
        }
      }
    }
    async function handleApprove() {
      if (
        window.confirm(
          `Are you sure you want to approve this Property:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        try {
          if (navigator.onLine) {
            setErr({
              error: "",
              show: false,
            });
            const response = await AdminApi.updateProperty(
              selectedRows[0]._id,
              { adminApproval: true },
              Auth.token
            );
            if (response) {
              toast(
                "Success: Property Approved! It will be visible on the website now.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setIsLoading(false);
              setToggleCleared(!toggleCleared);
              setSelectedRows([]);
              // setData(differenceBy(data, selectedRows, "adminApproval"));
              getAllLeads();
            } else {
              setErr({ error: response.data.data, show: true });
              setIsLoading(false);
              return false;
            }
          } else {
            alert("You`re offline");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Error loading data");
        }
      }
    }

    return (
      <>
        <div className="flex gap-2 items-center">
          <Button
            key="delete"
            onClick={handleDelete}
            style={{ backgroundColor: "red" }}
            icon
          >
            Delete
          </Button>
          {selectedRows.map((r) => {
            console.log(r);
            if (!r.adminApproval) {
              return (
                <>
                  <Button
                    key="approve"
                    onClick={handleApprove}
                    color={"dark"}
                    icon
                  >
                    {isLoading ? <Loading /> : ""}
                    Approve Property
                  </Button>
                  ;
                </>
              );
            }
          })}
        </div>
      </>
    );
  }, [data, selectedRows, toggleCleared]);

  // createTheme creates a new theme named solarized that overrides the build in dark theme
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "#2aa198",
      },
      background: {
        default: "rgb(31 41 55 / 1)",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "rgb(55 65 81/1)",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  // A super simple expandable component.
  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const columns = [
    {
      name: "User Name",
      sortable: true,
      selector: (row) => row.userId?.name,
    },
    {
      name: "User Mobile",
      sortable: true,
      selector: (row) => (
        <>
          <Link to={`tel:+91${row.userId?.mobile}`}>
            <h1 className="text-mainBlue">{row.userId?.mobile}</h1>
          </Link>
        </>
      ),
    },
    {
      name: "Property Name",
      sortable: true,
      selector: (row) => row.propertyId?.name,
    },
    {
      name: "Ownership Type",
      sortable: true,
      selector: (row) => row.propertyId?.availableType,
    },
    {
      name: "Type",
      sortable: true,
      selector: (row) => row.propertyId?.type,
    },

    {
      name: "Create On",
      sortable: true,
      selector: (row) => moment(row.createdAt).format("ll"),
    },

    {
      name: "Actions",
      grow: 2,
      selector: (row) => (
        <>
          <div className="flex gap-2">
            <Link
              className="flex gap-2"
              to={`/single-property/${row.propertyId?._id}`}
            >
              <Button color={"light"} size={"sm"}>
                <BsEye className="mr-1" />
                View Property
              </Button>
            </Link>
          </div>
        </>
      ),
    },
  ];

  const getAllLeads = async () => {
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getAllLeads(Auth.token);
        console.log(response);
        if (response) {
          setIsLoading(false);
          setData(response);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getAllLeads();
  }, []);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextInput
          id="username"
          type="text"
          placeholder="Search Properties"
          required={true}
          onChange={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const downloadCSV = () => {
    console.log("clicked");
  };

  return (
    <div className="users-list">
      <h1 className="dark:text-white text-2xl font-semibold mb-4">All Leads</h1>
      {!isLoading ? (
        <>
          <div className="afterLoad">
            <div className="users-option flex justify-between items-center mb-6">
              {/* <div className="left flex justify-between items-center">
                <div className="search-box w-96 border-r border-darkBorder pr-4">
                  {subHeaderComponentMemo}
                </div>
                {selectedRows.length > 0 ? (
                  <>
                    <div className="flex ml-3">{contextActions}</div>
                  </>
                ) : (
                  ""
                )}
              </div> */}

              <div className="right flex gap-3">
                <Link to={"/dashboard/reseller/upload-property/new"}>
                  <Button color={"info"}>
                    <FaPlus className="mr-2 font-bold" />
                    Add Property
                  </Button>
                </Link>
              </div>
            </div>

            <div className="data-table">
              <div className="data-loaded">
                <DataTable
                  title=""
                  columns={columns}
                  data={data}
                  pagination
                  // paginationResetDefaultPage={resetPaginationToggle}
                  customStyles={customStyles}
                  // subHeader
                  // subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  selectableRows
                  selectableRowsSingle={true}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  theme={theme}
                  className={"font-medium"}
                  // selectableRowDisabled={rowDisabledCriteria}
                  // defaultSortFieldId={1}
                  //   contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  fixedHeader
                  responsive={true}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-center h-full">
            <h1 className="loading text-2xl dark:text-white block">Loading</h1>
            <Loading />
          </div>
        </>
      )}
    </div>
  );
};

export default AllLeadsContainer;
