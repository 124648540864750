import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { CityContext } from "./CityContext";
import stateApis from "../api/statesApi";
import { LocalityContext, LocalityContextProvider } from "./LocalityContext";
import { useNavigate } from "react-router";

const CitySelector = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { city, setCity } = React.useContext(CityContext);
  const { locality, setLocality } = React.useContext(LocalityContext);
  const [allCities, setAllCities] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    const response = await stateApis.getAllCities();
    setAllCities(response);
    const cityResponse = await stateApis.getCityByName(city);
    console.log(cityResponse);
    setLocality(cityResponse.localities);
  };

  const handleCity = async (e) => {
    setCity(e);
    setIsLoading(true);
    const cityResponse = await stateApis.getCityByName(e);
    setIsLoading(false);
    setLocality(cityResponse.localities);
    navigate("/");
  };

  return (
    <div className="citySelect">
      <Dropdown
        fullSized={true}
        size={"sm"}
        label={`${city ? city : "Select City"}`}
        placement="auto"
        title="Buy or Rent"
        dismissOnClick={true}
        pill={true}
        className="dark:bg-black bg-white"
      >
        <div className="flex flex-wrap overflow-scroll gap-4 justify-start items-center bg-white dark:bg-gray-800">
          {allCities.length > 0 &&
            allCities.map((city) => (
              <Dropdown.Item
                style={{ width: "100px" }}
                onClick={() => handleCity(city.name)}
              >
                {city.name}
              </Dropdown.Item>
            ))}
        </div>
      </Dropdown>
    </div>
  );
};

export default CitySelector;
