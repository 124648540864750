import React from "react";
import Breadcrumb from "../components/common/Breadcrumb";
import ContactDetailsWithMap from "../components/common/ContactDetailsWithMap";
import ContactFormSidebar from "../components/common/ContactFormSidebar";
import MainFooter from "../components/common/MainFooter";
import SocialIcons from "../components/common/SocialIcons";
import SubFooter from "../components/common/SubFooter";
import FeaturedProperties from "../components/Website/PropertyViewTypes/MiniFeaturedProperties/MiniFeaturedProperties";

const ContactUs = () => {
  return (
    <>
      <ContactDetailsWithMap />
      <div className="bg-slate-100 h-auto">
        <div className="max-w-5xl mx-auto py-6">
          <Breadcrumb />
          <div className="flex justify-between items-start ">
            <div
              className="dark:bg-gray-800 dark:text-white w-2/3 mr-6 p-8 bg-white shadow-defaultShadow mb-10"
              style={{ padding: "32px" }}
            >
              <h1 className="text-4xl mt-2 mb-5 font-semibold">
                MIT HOMES - MOVE IN TODAY
              </h1>
              <h2 className="text-2xl mb-6 ">
                Business Park Theale C1, Center Berkshire RS1 5A1, England
              </h2>
              <SocialIcons />
              <div className="mt-4 mb-8">
                <div className="flex justify-start gap-6 text-sm mb-2">
                  <p
                    style={{ minWidth: "90px" }}
                    className="w-1/3 text-para font-medium dark:text-white"
                  >
                    Phone:
                  </p>
                  <p className="hover:text-blue ">
                    <a
                      className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400"
                      href="tel:+919928382340"
                    >
                      +91-9928382340
                    </a>
                  </p>
                </div>
                <div className="flex justify-start gap-6 text-sm mb-2">
                  <p
                    style={{ minWidth: "90px" }}
                    className="w-1/3 text-para font-medium dark:text-white"
                  >
                    Email:
                  </p>
                  <p className="hover:text-blue ">
                    <a className="text-slate-600" href="mailto:yogesh@tej.life">
                      yogesh@tej.life
                    </a>
                  </p>
                </div>
                <div className="flex justify-start gap-6 text-sm mb-2">
                  <p
                    style={{ minWidth: "90px" }}
                    className="w-1/3 text-para font-medium dark:text-white"
                  >
                    For Resellers:
                  </p>
                  <p className="hover:text-blue ">
                    <a className="text-slate-600" href="mailto:yogesh@tej.life">
                      support@tej.life
                    </a>
                  </p>
                </div>
              </div>
              <p className="text-para text-sm mb-8 dark:text-white">
                Whether you’re looking for property for sale in New York area or
                property for rent, WP Residence makes searching easy. Use our
                unique geolocation mapping feature to root-out your ideal villa,
                townhouse or apartment and contact the owners direct.
              </p>
              <img
                src="https://wpresidence.net/wp-content/uploads/2020/07/team2.jpg"
                alt="team"
                className="h-full w-full"
              />
            </div>

            <div className="flex-1">
              <ContactFormSidebar />
              <FeaturedProperties />
            </div>
          </div>
          <h1 className="text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 my-6">Just one click away to book your dream home today </h1>
        </div>
      </div>
      <SubFooter />
      <MainFooter />
    </>
  );
};

export default ContactUs;
