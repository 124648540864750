import React from 'react'
import ResellerLeadsContainer from '../../../../components/Dashboard/Reseller/ResellerLeads/ResellerLeadsContainer';

const ResellerLeads = () => {
  return (
    <>
        <ResellerLeadsContainer />
    </>
  )
}

export default ResellerLeads;