import React from 'react'

const HomeBanner = () => {
  return (
    <>
        <div className='h-80 items-center justify-center flex bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 dark:from-cyan-500 dark:to-blue-500'>
            <div className='max-w-5xl mx-auto text-center text-white '>
                <h1 className="text-l sm:text-xl md:text-3xl lg:text-6xl mb-5 text-center font-bold">Find Your Dream Home</h1>
                <p className="text-sm sm:text-sm md:text-base lg:text-lg px-4 sm:px-8 md:px-14 lg:px-28">We are recognized for exceeding client expectations and delivering great results through dedication, ease of process, and extraordinary services to our worldwide clients.</p>
            </div>
        </div>
    </>
  )
}

export default HomeBanner;