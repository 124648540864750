import React from 'react'
import ForgotPasswordContainer from '../components/Auth/ForgotPasswordContainer';

const ForgotPassword = () => {
  return (
    <>
        <ForgotPasswordContainer />
    </>
  )
}

export default ForgotPassword;