import React from "react";
import PropertiesInRow from "../components/common/PropertyViews/PropertiesInRow";
import SubFooter from "../components/common/SubFooter";
import MainFooter from "../components/common/MainFooter";

const PropertyTypes = () => {
  return (
    <>
      <PropertiesInRow />
      <SubFooter />
      <MainFooter />
    </>
  );
};

export default PropertyTypes;
