import { Button } from "flowbite-react";
import React, { useState } from "react";
import { BsHeart, BsPrinter, BsShare } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import PropertyDetails from "./PropertyDetails";
import ResellerApi from "../../../api/ResellerApi";
import { toast } from "react-toastify";
import { PriceConvert } from "../../../utils/PriceConvert";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const Details = ({ data }) => {
  const [shareShow, setShareShow] = useState(false);
  const navigate = useNavigate();
  const Auth = useSelector((state) => state.auth.authData);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop();

  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  console.log(err);
  const addToWishlist = async () => {
    console.log(lastPart);
    try {
      if (navigator.onLine) {
        setErr({
          error: "",
          show: false,
        });
        setIsLoading(true);
        if (Auth.token) {
          const response = await ResellerApi.addFavourite(lastPart, Auth.token);
          console.log(response);
          if (response.status === 1) {
            toast("Added to your Favorites!");
            setIsLoading(false);
            setIsAdded(true);
          } else {
            toast.warning(`${response.data}`);
            setErr({ error: response.data, show: true });
            setIsLoading(false);
            return false;
          }
        } else {
          navigate("/login");
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };

  return (
    <>
      {data.name ? (
        <div className="md:max-w-5xl mt-6 mx-auto">
          <div className="main-details-div">
            {/*
             ********** Heading section of property with share buttons
             */}
            <div className="pl-5 md:p-0 headings-section mb-9">
              <Breadcrumb />
              <div className="block md:flex justify-between items-center">
                <div className="block title md:flex-1">
                  <h1 className=" dark:text-white text-2xl md:text-4xl font-bold mt-4 md:m-0">
                    {data.name}
                  </h1>
                  <Link
                    to={"/google-map-link"}
                    className="flex gap-2 mt-3 hover:text-mainBlue dark:text-white"
                  >
                    <HiLocationMarker className="relative top-1" />
                    <p>
                      {data.localityDetails.street}, {data.localityDetails.city}
                    </p>
                  </Link>
                </div>
                <div className="price ">
                  <h1 className="mt-4 md:m-0 text-xl md:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                    ₹ {PriceConvert(data.priceDetails.expectedPrice)}{" "}
                    <span className="ml-2 dark:text-white text-xs text-gray-800 font-normal">
                      For {data.availableType}
                    </span>
                  </h1>
                  <div className="widgets flex gap-2 mt-6">
                    <Button
                      pill={true}
                      size={"sm"}
                      color={"gray"}
                      onClick={() => setShareShow(!shareShow)}
                    >
                      <BsShare className="mr-2" />
                      Share
                    </Button>
                    <Button
                      pill={true}
                      size={"sm"}
                      // color={isAdded ? "lime" : "gray"}
                      gradientDuoTone={
                        isAdded ? "purpleToPink" : "purpleToBlue"
                      }
                      onClick={addToWishlist}
                      disabled={isLoading}
                    >
                      <BsHeart className="mr-2" />
                      Favorite
                    </Button>

                    <Button
                      pill={true}
                      size={"sm"}
                      color={"gray"}
                      onClick={() => window.print()}
                    >
                      <BsPrinter className="mr-2" />
                      Print
                    </Button>
                  </div>
                  {shareShow ? (
                    <>
                      <div className="flex gap-2 mt-3">
                        <div>
                          <FacebookShareButton
                            url={"https://www.moveintoday.com"}
                            quote={"Dummy text!"}
                            hashtag="#muo"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                        </div>
                        <div>
                          <WhatsappShareButton
                            url={"https://www.moveintoday.com"}
                            quote={"Dummy text!"}
                            hashtag="#muo"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="mt-3">
                    {err.show && err.error !== undefined ? (
                      <div className="mt-2 text-[#da232aff] text-sm">
                        {err.error.message ? "Invalid Property Id" : err.error}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* 
          Overview Details Starts From Here 
        */}
            <div className="details-section">
              <PropertyDetails data={data} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Details;
