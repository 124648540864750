import React from 'react';
import AdvanceSearchResult from "../components/Website/AdvanceSearchResult/AdavanceSearchResult";

const AdvanceSearch = () => {
  return (
    <>
        <AdvanceSearchResult />
    </>
  )
}

export default AdvanceSearch;