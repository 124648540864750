import React, { useEffect, useState, createContext } from 'react';

export const GoogleAutoCompleteContext = createContext();

export const GoogleAutoCompleteProvider = ({ initialData, children }) => {
    const [locationData, setLocationData] = useState({
      coord: { lat: 26.9124336, lng: 75.7872709 },
      address: [{
        formatted_address: "Jaipur, Rajasthan India"
      }]
    });
    return (
      <GoogleAutoCompleteContext.Provider value={{ locationData, setLocationData }}>
        {children}
      </GoogleAutoCompleteContext.Provider>
    );
  };