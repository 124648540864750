import React from "react";
import AboutContainer from "../components/Website/AboutContainer";
import SubFooter from "../components/common/SubFooter";
import MainFooter from "../components/common/MainFooter";

const About = () => {
  return (
    <>
      <AboutContainer />
      <SubFooter />
      <MainFooter />
    </>
  );
};

export default About;
