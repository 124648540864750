import React from "react";
import UserPieChart from "./Reseller/Stats/UserPieChart";
import { GradientChart } from "./Reseller/Stats/GradientChart";
import ListOfUsers from "./Admin/Common/ListOfUsers";
import MiniFeaturedProperties from "../Website/PropertyViewTypes/MiniFeaturedProperties/MiniFeaturedProperties";
import ListingRequests from "./Admin/Common/ListingRequests";

const DashboardHome = () => {
  return (
    <>
      <div className="flex gap-6 w-full mb-8">
        <div className="flex-1 ">
          <ListOfUsers />
        </div>
        <div className="flex-1 p-6 rounded-lg bg-white shadow-defaultShadow dark:bg-gray-800">
          {/* <VerticalChart /> */}
          <MiniFeaturedProperties />
        </div>
      </div>
      <div className="flex gap-6 w-full mb-8">
        <div className="flex-1 ">
          <ListingRequests />
        </div>
        <div className="flex-1 p-6 rounded-lg bg-white shadow-defaultShadow dark:bg-gray-800">
          {/* <VerticalChart /> */}
          <MiniFeaturedProperties />
        </div>
      </div>
      <div className="flex gap-6 w-full mb-8">
        <div className="flex-1 p-6 rounded-lg bg-white shadow-defaultShadow dark:bg-gray-800">
          <GradientChart />
        </div>
        <div className="flex-1 p-6 max-h-80 rounded-lg bg-white shadow-defaultShadow dark:bg-gray-800">
          <UserPieChart />
        </div>
      </div>
    </>
  );
};

export default DashboardHome;
