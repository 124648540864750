import React from "react";
import { BsPower, BsWater } from "react-icons/bs";
import { CgGames } from "react-icons/cg";
import {
  FaBasketballBall,
  FaChild,
  FaFire,
  FaIntercom,
  FaOm,
  FaShoppingBag,
  FaSpa,
  FaSteam,
  FaSwimmingPool,
  FaWifi,
} from "react-icons/fa";
import {
  GiCctvCamera,
  GiClubs,
  GiCricket,
  GiElevator,
  GiGate,
  GiGymBag,
  GiLift,
  GiPipes,
  GiPoolTableCorner,
  GiTennisBall,
  GiTheater,
} from "react-icons/gi";
import { HiLibrary } from "react-icons/hi";
import { IoCafe, IoPeopleOutline, IoSquareSharp } from "react-icons/io5";
import {
  MdKitchen,
  MdLocalLaundryService,
  MdMeetingRoom,
  MdOutlineMeetingRoom,
  MdPark,
  MdRoom,
  MdSmokeFree,
} from "react-icons/md";
import { RiCarWashingFill } from "react-icons/ri";

const Features = ({ data }) => {
  console.log(data);
  return (
    <>
      {data ? (
        <>
          <div className="p-6 mb-8 shadow-defaultShadow dark:bg-gray-800">
            <h1 className="mb-5 font-bold dark:text-white">Features</h1>

            <div className="">
              <div>
                <p className=" text-sm w-[32%] font-semibold dark:text-white">
                  Basic Amenities
                </p>
                <div className="flex flex-wrap justify-between gap-4 items-center w-full mt-3 dark:text-white">
                  {data?.amenities?.waterSupplyAllTime && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <BsWater className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Water Supply
                      </span>
                    </p>
                  )}
                  {data?.amenities?.powerBackup && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <BsPower className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray " />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Power Backup
                      </span>
                    </p>
                  )}

                  {data?.amenities?.cctv && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiCctvCamera className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        CCTV
                      </span>
                    </p>
                  )}

                  {data?.amenities?.theaterRoom && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiTheater className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Theater Room
                      </span>
                    </p>
                  )}

                  {data?.amenities?.cafeteria && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <IoCafe className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Cafeteria
                      </span>
                    </p>
                  )}

                  {data?.amenities?.library && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <HiLibrary className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Library
                      </span>
                    </p>
                  )}

                  {data?.amenities?.businessMeetingRooms && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <MdMeetingRoom className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Meeting Rooms
                      </span>
                    </p>
                  )}

                  {data?.amenities?.guestRooms && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <MdOutlineMeetingRoom className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Guest Rooms
                      </span>
                    </p>
                  )}

                  {data?.amenities?.banquetHall && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <MdRoom className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Banquet Hall
                      </span>
                    </p>
                  )}

                  {data?.amenities?.shoppingCenter && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaShoppingBag className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Shoping Center
                      </span>
                    </p>
                  )}

                  {data?.amenities?.carWash && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <RiCarWashingFill className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Car Wash
                      </span>
                    </p>
                  )}

                  {data?.amenities?.seniorCitizenCorner && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <IoPeopleOutline className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Senior Citizen Corner
                      </span>
                    </p>
                  )}

                  {data?.amenities?.jacuzzi && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaSteam className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Steam/Sauna
                      </span>
                    </p>
                  )}

                  {data?.amenities?.spa && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaSpa className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Spa
                      </span>
                    </p>
                  )}

                  {data?.amenities?.clubHouse && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiClubs className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Club House
                      </span>
                    </p>
                  )}

                  {data?.amenities?.lift && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiElevator className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Lift
                      </span>
                    </p>
                  )}

                  {data?.amenities?.fireSafety && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaFire className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Fire Safety
                      </span>
                    </p>
                  )}

                  {data?.amenities?.gatedSecurity && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiGate className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Gated Security
                      </span>
                    </p>
                  )}

                  {data?.amenities?.intercom && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaIntercom className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Intercom
                      </span>
                    </p>
                  )}

                  {data?.amenities?.playArea && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaChild className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Play Area
                      </span>
                    </p>
                  )}

                  {data?.amenities?.park && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <MdPark className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Park
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-7">
                <p className=" text-sm w-[32%] font-semibold dark:text-white mb-3">
                  Interior Design
                </p>
                <div className="flex flex-wrap justify-between gap-4 items-center w-full mt-3 dark:text-white">
                  {data?.amenities?.laundry && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <MdLocalLaundryService className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Laundry
                      </span>
                    </p>
                  )}
                  {data?.amenities?.modularKitchen && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <MdKitchen className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Modular Kitchen
                      </span>
                    </p>
                  )}

                  {data?.amenities?.smokeDetection && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <MdSmokeFree className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Smoke detectors
                      </span>
                    </p>
                  )}

                  {data?.amenities?.internetProvider && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaWifi className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Internet Provider
                      </span>
                    </p>
                  )}

                  {data?.amenities?.gasPipeline && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiPipes className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Gas Pipeline
                      </span>
                    </p>
                  )}

                  {data?.amenities?.privateLift && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiLift className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Private Lift
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-7">
                <p className=" text-sm w-[32%] font-semibold dark:text-white mb-2">
                  Sports
                </p>
                <div className="flex flex-wrap justify-between gap-4 items-center w-full mt-3 dark:text-white">
                  {data?.amenities?.squashCourt && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <IoSquareSharp className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Squash Court
                      </span>
                    </p>
                  )}

                  {data?.amenities?.swimmingPool && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaSwimmingPool className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Swimming Pool
                      </span>
                    </p>
                  )}

                  {data?.amenities?.gym && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiGymBag className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Gym
                      </span>
                    </p>
                  )}

                  {data?.amenities?.basketBallCourt && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaBasketballBall className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        BasketBall Court
                      </span>
                    </p>
                  )}

                  {data?.amenities?.tennisCourt && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiTennisBall className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Tennis Court
                      </span>
                    </p>
                  )}

                  {data?.amenities?.yogaRoom && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <FaOm className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Yoga Room
                      </span>
                    </p>
                  )}

                  {data?.amenities?.cricketArea && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiCricket className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Cricket Area
                      </span>
                    </p>
                  )}

                  {data?.amenities?.snooker && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <GiPoolTableCorner className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Snooker/Pool
                      </span>
                    </p>
                  )}

                  {data?.amenities?.indoorGames && (
                    <p className="md:w-[30%] flex items-center text-sm whitespace-nowrap overflow-visible text-blue">
                      <CgGames className="w-7 h-7 mr-3 text-blue-600 dark:text-lightGray" />{" "}
                      <span className="text-para text-sm dark:text-white">
                        Indoor Games Area
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h1>Loading...</h1>{" "}
        </>
      )}
    </>
  );
};

export default Features;
