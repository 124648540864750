import { useEffect, useState, createContext } from 'react';
import React from 'react';

export const LocalityContext = createContext();

export const LocalityProvider = ({ initialLocality, children }) => {
  const [locality, setLocality] = useState([]);

  const rawSetLocality = (rawLocality) => {
    localStorage.setItem('mit-locality', rawLocality);
  };

  if (initialLocality) {
    rawSetLocality(initialLocality);
  }

  useEffect(() => {
    rawSetLocality(locality);
  }, [locality]);

  return (
    <LocalityContext.Provider value={{ locality, setLocality }}>
      {children}
    </LocalityContext.Provider>
  );
};
