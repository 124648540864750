import { Button, Checkbox, Label } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Countdown from "react-countdown";

import { SignUpWithOTP, VerifySignUpOTP } from "../../presenter/Auth/auth";
import Loading from "../../utils/Loading";
import Login from "./Login";
import { useSelector } from "react-redux";

const AuthContainer = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountFormed, setIsAccountFormed] = useState(false);
  const [isOtpReceived, setIsOtpReceived] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const Auth = useSelector((state) => state.auth.authData.isAuthenticated);
  const AuthError = useSelector((state) => state.auth.authError);

  useEffect(() => {
    if (Auth) {
      navigate("/");
    }
  }, [Auth]);

  useEffect(() => {
    setErr(AuthError);
  }, [AuthError]);

  const toggleForm = () => {
    setErr({ error: "", show: false });
    setIsLogin(!isLogin);
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <>
          <p className="underline text-xs text-blue-500">Resend otp</p>
        </>
      );
    } else {
      // Render a countdown
      return (
        <span className="loading text-xs text-blue-500">
          Resend OTP in {seconds}
        </span>
      );
    }
  };

  const signUpFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, "Name must be 20 Characters or less")
        .required("Required"),
      email: Yup.string().email().required("Required"),
      mobile: Yup.string()
        .required("Required")
        .min(10, "Minimum 10 digits")
        .max(10, "Max 10 digits"),
      password: Yup.string()
        .max(20, "Password must be min 5 characters and max 20 characters")
        .min(5, "Minimum 5 letters should be in your password")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setIsAccountFormed(true);
      const postUpdatedData = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        password: values.password,
        role: "reseller",
      };

      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await SignUpWithOTP(postUpdatedData);
          console.log(response);
          if (response) {
            // resetForm();
            if (response.data.status === 0) {
              // alert(response.data.data);
              setErr({ error: response.data.data, show: true });
            } else {
              setIsOtpReceived(true);
              otpFormik.values.mobile = values.mobile;
            }
            setIsLoading(false);
          } else {
            setErr({ error: response.data.data, show: true });
          }
        } else {
          alert("You`re offline");
        }
      } catch (error) {
        console.log(error);
        setIsOtpReceived(false);
        setIsLoading(false);
        setIsAccountFormed(false);
        alert("Error Updating Data");
        setErr({
          error: "CORS Error",
          show: true,
        });
      }
    },
  });

  const otpFormik = useFormik({
    initialValues: {
      mobile: "",
      otp: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string(),
      otp: Yup.string()
        .max(4, "must be max four characters")
        .min(4, "minimum 4 characters"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const postUpdatedData = {
        mobile: values.mobile,
        otp: values.otp,
      };
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await VerifySignUpOTP(postUpdatedData);
          if (response) {
            alert("Succesfully Registered!!");
            setIsVerified(true);
            setIsLoading(false);
          } else {
            setErr({ error: response.data.data, show: true });
          }
        } else {
          alert("You're offline");
        }
      } catch (error) {
        console.log(error);
        setErr({
          error: "CORS Error",
          show: true,
        });
        alert("OTP Form: Error Updating Data");
      }
    },
  });

  const ButtonComponent = () => {
    if (!(isAccountFormed && isOtpReceived)) {
      return (
        <>
          <Button type="submit" onSubmit={signUpFormik.handleSubmit}>
            <>
              {isLoading ? <Loading /> : ""}
              Register Account
            </>
          </Button>
        </>
      );
    }
    if (isAccountFormed && isOtpReceived && !err.show) {
      return (
        <>
          <Button type="submit" onSubmit={otpFormik.handleSubmit}>
            <>
              {isLoading ? <Loading /> : ""}
              Verify OTP
            </>
          </Button>
        </>
      );
    }
  };
  return (
    <>
      <div className="block md:flex justify-center mt-6 mx-auto">
        <div className="hidden md:block image flex-1 w-1/2 h-screen">
          <img
            className="h-full"
            src="https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            alt="img"
          />
        </div>
        {isLogin ? (
          /***
           *
           * Login Form Component
           */
          <>
            <Login toggleForm={toggleForm} />
          </>
        ) : (
          <>
            {/***
             *
             * Sign up Form
             *
             * */}
            {!isVerified ? (
              <>
                <div className="px-6 lg:px-8 h-[100vh]  flex-1 w-full md:w-1/2">
                  <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                    Sign up on our platform
                  </h3>
                  <form
                    className="flex flex-col gap-4"
                    onSubmit={
                      isOtpReceived
                        ? otpFormik.handleSubmit
                        : signUpFormik.handleSubmit
                    }
                  >
                    <div
                      className={`flex gap-4 ${
                        isAccountFormed && !err.show ? "blur-sm" : ""
                      }`}
                    >
                      <div className="flex-1">
                        <div className="mb-2 block">
                          <Label htmlFor="email2" value="Your Name" />
                        </div>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={signUpFormik.values.name}
                          onChange={signUpFormik.handleChange}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Yogesh Nogia"
                          shadow={true}
                          required
                          disabled={isAccountFormed && !err.show}
                        />
                        {signUpFormik.errors.name ? (
                          <p className="text-xs text-red-600 ">
                            {signUpFormik.errors.name}
                          </p>
                        ) : null}
                      </div>
                      <div className="flex-1">
                        <div className="mb-2 block">
                          <Label htmlFor="email2" value="Your email" />
                        </div>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={signUpFormik.values.email}
                          onChange={signUpFormik.handleChange}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="manish@mithomes.com"
                          shadow={true}
                          required
                          disabled={isAccountFormed && !err.show}
                        />
                        {signUpFormik.errors.email ? (
                          <p className="text-xs text-red-600 ">
                            {signUpFormik.errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1">
                        <div className="mb-2 block">
                          <Label htmlFor="mobile" value="Your Mobile Number" />
                        </div>
                        <input
                          type="text"
                          id="mobile"
                          value={signUpFormik.values.mobile}
                          onChange={signUpFormik.handleChange}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="992838XXXX"
                          shadow={true}
                          required
                          disabled={isAccountFormed && !err.show}
                        />
                        {signUpFormik.errors.mobile ? (
                          <p className="text-xs text-red-600 ">
                            {signUpFormik.errors.mobile}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {isOtpReceived && (
                      <>
                        <div className="flex gap-4">
                          <div className="flex-1">
                            <div className="mb-2 block">
                              <Label htmlFor="otp" value="Enter OTP" />
                            </div>
                            <input
                              type="text"
                              id="otp"
                              value={otpFormik.values.otp}
                              onChange={otpFormik.handleChange}
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="OTP"
                              shadow={true}
                              required
                            />
                            {otpFormik.errors.otp ? (
                              <p className="text-xs text-red-600 ">
                                {otpFormik.errors.otp}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <Countdown
                          date={Date.now() + 30000}
                          renderer={renderer}
                        />
                      </>
                    )}
                    <div
                      className={`flex gap-4 ${
                        isAccountFormed && !err.show ? "blur-sm" : ""
                      }`}
                    >
                      <div className="flex-1">
                        <div className="mb-2 block">
                          <Label htmlFor="password" value="Your password" />
                        </div>
                        <input
                          type="password"
                          id="password"
                          value={signUpFormik.values.password}
                          onChange={signUpFormik.handleChange}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Enter a password"
                          shadow={true}
                          required
                          disabled={isAccountFormed && !err.show}
                        />
                        {signUpFormik.errors.password ? (
                          <p className="text-xs text-red-600 ">
                            {signUpFormik.errors.password}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 items-center gap-2">
                        <Checkbox checked={true} id="agree" className="mr-2" />
                        <Label htmlFor="agree">
                          I agree with the{" "}
                          <a
                            href="/forms"
                            className="text-blue-600 hover:underline dark:text-blue-500"
                          >
                            terms and conditions
                          </a>
                        </Label>
                      </div>
                    </div>
                    <div className="flex gap-4 mt-4">
                      <div className="flex-1 w-full">
                        <ButtonComponent />
                      </div>
                    </div>
                  </form>
                  <div className="mt-3">
                    {err.show ? (
                      <div className="mt-2 text-[#da232aff] text-sm">
                        {err.error}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-3">
                    <p
                      href="#"
                      onClick={toggleForm}
                      className="underline text-sm dark:text-white hover:underline hover:cursor-pointer"
                    >
                      Already have an account? Login here
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="px-6 lg:px-8  flex-1 w-1/2">
                  <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                    Thank you for registering on your platform. Happy Home
                    Planning.
                  </h3>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AuthContainer;
