import { Button, Footer, Label, TextInput } from "flowbite-react";
import React, { useContext } from "react";
import { FaArrowLeft, FaArrowRight, FaLocationArrow } from "react-icons/fa";
import { useSelector } from "react-redux";
import { GoogleAutoCompleteContext } from "../../../../contexts/GoogleAutoCompleteData";

import { Cities } from "../../../../types/State";
import GoogleAutoComplete from "../../../common/GoogleMap/GoogleAutoComplete";
import Loading from "../../../../utils/Loading";
import IsRequired from "../../../common/RequiredFieldMark";

const LocalityDetails = ({
  form,
  fetching,
  handleButtonClick,
  validationSchema,
}) => {
  const auth = useSelector((state) => state.auth.authData);
  const { locationData, setLocationData } = useContext(
    GoogleAutoCompleteContext
  );

  const isDisabled = () => {
    return ["city", "street"].filter((key) => {
      return form.errors?.["localityDetails"]?.[key];
    });
  };

  return (
    <>
      <h2 className=" dark:text-white mb-5 font-semibold text-xl text-blue-600 border-b border-gray-700-800 pb-5">
        Locality Details
      </h2>
      <div className="options justify-between">
        <div className="flex gap-4 mb-7">
          <div className="flex-1">
            <div className="mb-2 block">
              <Label
                htmlFor="city"
                value={
                  <IsRequired
                    schema={validationSchema}
                    fieldName="localityDetails.city"
                    fieldLabel={"City"}
                    isRequired={true}
                  />
                }
              />
            </div>
            <div className="flex">
              <select
                id="localityDetails.city"
                value={form.values.localityDetails.city}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected hidden disabled key={0}>
                  Select from options
                </option>
                {Cities.map((state) => {
                  return <option value={state.value}>{state.label}</option>;
                })}
              </select>
            </div>
            {form.errors?.localityDetails?.city ? (
              <p className="text-sm text-red-600 mt-2">
                {form.errors.localityDetails.city}
              </p>
            ) : null}
          </div>
        </div>

        <div className="flex gap-4 mb-5">
          <div className="flex-1">
            <div className="mb-2 block">
              <Label
                htmlFor="street"
                value={
                  <IsRequired
                    schema={validationSchema}
                    fieldLabel={"Enter Complete Address with Pin Code"}
                    isRequired={true}
                  />
                }
              />
            </div>
            <div className="flex-1 w-full">
              <TextInput
                id="localityDetails.street"
                type="text"
                placeholder="Enter Your Property Address"
                required={true}
                value={form.values.localityDetails.street}
                onChange={form.handleChange}
              />
            </div>
            {form.errors?.localityDetails?.street ? (
              <p className="text-sm text-red-600 mt-2">
                {form.errors.localityDetails.street}
              </p>
            ) : null}
          </div>
        </div>

        <Footer.Divider></Footer.Divider>

        <div className="gap-4 my-5">
          <div className="">
            <h1 className="dark:text-white flex items-center">
              <FaLocationArrow className="mr-3" /> <b>Current Location</b>:{" "}
              {form.values.localityDetails.locality}
            </h1>
            <p className="text-xs mt-4 text-para mb-4">
              Set new property location by using search box on Google Map
            </p>
          </div>
          <div className="map h-auto max-h-80">
            <div className="h-72">
              <GoogleAutoComplete
                center={{
                  lat: Number(form.values.localityDetails.lat),
                  lng: Number(form.values.localityDetails.lng),
                }}
                address={form.values.localityDetails.locality}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 mt-24 mb-5 items-center">
          <Button
            disabled={fetching}
            onClick={() => handleButtonClick("property")}
            className="flex items-end"
            color={"gray"}
          >
            {fetching ? <Loading /> : ""}
            <FaArrowLeft className="text-sm mr-2" />
            Back
          </Button>
          <Button
            disabled={fetching || isDisabled()?.length > 0}
            onClick={() => handleButtonClick("resale")}
            className="flex items-end"
          >
            {fetching ? <Loading /> : ""}
            Next to Resale Details
            <FaArrowRight className="text-sm ml-2" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default LocalityDetails;
