import { CssBaseline, TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, Checkbox, Label, Textarea, TextInput } from "flowbite-react";
import React from "react";
import { FaArrowLeft, FaArrowRight, FaRupeeSign } from "react-icons/fa";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import Loading from "../../../../utils/Loading";
import IsRequired from "../../../common/RequiredFieldMark";

const ResaleDetails = ({
  form,
  setAvailableDate,
  dateValue,
  fetching,
  handleButtonClick,
}) => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });
  const BasicDatePicker = () => {
    return (
      <>
        <div className="w-full h-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dateValue}
              inputFormat="DD-MM-YYYY"
              onChange={(newValue) => {
                newValue.format("DD-MM-YYYY");
                console.log(newValue);
                setAvailableDate(newValue);
              }}
              mask="__-__-____"
              name="availableDate"
              id="availableDate"
              renderInput={(params) => (
                <TextField {...params} className="h-6 dark:text-white" />
              )}
            />
          </LocalizationProvider>
        </div>
      </>
    );
  };

  const isDisabled = () => {
    return Boolean(
      form.errors?.["priceDetails"]?.["expectedPrice"] ||
        form.errors?.["details"]?.["furnishingType"]
    );
  };

  console.log(isDisabled(), form, "qwertyutrewqertyuio");
  return (
    <>
      <h2 className=" dark:text-white mb-5 font-semibold text-xl text-blue-600 border-b border-gray-700-800 pb-5">
        Resale Details
      </h2>
      <div className="options justify-between">
        <div className="flex gap-6 mb-5 items-end">
          <div className="flex-1">
            <div className="mb-2 block">
              <Label
                htmlFor="name"
                value={
                  <IsRequired fieldLabel={"Expected price"} isRequired={true} />
                }
              />
            </div>
            <div className="flex">
              <TextInput
                icon={FaRupeeSign}
                id="priceDetails.expectedPrice"
                type="text"
                placeholder="Enter Amount"
                required={true}
                className="w-full"
                value={form.values.priceDetails.expectedPrice}
                onChange={form.handleChange}
              />
            </div>
            <div className="flex mt-4">
              <div className="flex items-center gap-2">
                <Checkbox
                  id="priceDetails.negotiable"
                  checked={form.values.priceDetails.negotiable}
                  onChange={form.handleChange}
                  value={form.values.priceDetails.negotiable}
                />
                <Label htmlFor="accept">
                  Price Negotiable{" "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline dark:text-blue-500"
                  >
                    terms and conditions
                  </a>
                </Label>
              </div>
            </div>
            {form.errors?.priceDetails?.expectedPrice ? (
              <p className="text-sm text-red-600 mt-2">
                {form.errors?.priceDetails?.expectedPrice}
              </p>
            ) : null}
          </div>
          {/* <div className="flex-1">
            <div className="flex mt-4">
              <div className="flex items-center gap-2">
                <Checkbox
                  id="priceDetails.underLoan"
                  checked={form.values.priceDetails.underLoan}
                  onChange={form.handleChange}
                  value={form.values.priceDetails.underLoan}
                />
                <Label htmlFor="accept">Currently under loan</Label>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex gap-6 mb-5">
          <div className="flex-1">
            <div className="mb-2 block">
              <Label
                htmlFor="availableDate"
                value={
                  <IsRequired fieldLabel={"Available From"} isRequired={true} />
                }
              />
            </div>
            <div className="flex">
              {/* <DatePickerDefault /> */}
              <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <BasicDatePicker />
              </ThemeProvider>
            </div>
          </div>
          <div className="flex-1">
            <div className="mb-2 block">
              <Label htmlFor="kitchenType" value="Kitchen Type" />
            </div>
            <div className="flex">
              <select
                id="details.kitchenType"
                value={form.values.details.kitchenType}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value disabled>
                  Kitchen Type
                </option>
                <option value={"Modular"}>Modular</option>
                <option value={"CoveredShelves"}>Covered Shelves</option>
                <option value={"OpenShelves"}>Open Shelves</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex gap-6 mb-5 items-end">
          <div className="flex-1">
            <div className="mb-2 block">
              <Label
                htmlFor="furnishingType"
                value={
                  <IsRequired fieldLabel={"Furnishing"} isRequired={true} />
                }
              />
            </div>
            <div className="flex">
              <select
                id="details.furnishingType"
                value={form.values.details.furnishingType}
                onChange={form.handleChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" selected hidden disabled key={0}>
                  Select from options
                </option>
                <option value={"fullFurnished"} key={1}>
                  Full Furnished
                </option>
                <option value={"semiFurnished"} key={2}>
                  Semi Furnished
                </option>
                <option value={"unFurnished"} key={3}>
                  Unfurnished
                </option>
              </select>
            </div>
            {form.errors?.details?.furnishingType ? (
              <p className="text-sm text-red-600 mt-2">
                {form.errors?.details?.furnishingType}
              </p>
            ) : null}
          </div>
          <div className="flex-1">
            <div className="mb-2 block">
              <Label htmlFor="parking" value="Parking" />
            </div>
            <div className="flex">
              <TextInput
                id="details.parking"
                value={form.values.details.parking}
                onChange={form.handleChange}
                required={true}
                className="text-sm"
                placeholder="Number of parkings"
                type="number"
              ></TextInput>
            </div>
          </div>
        </div>

        <div className="flex gap-6 mb-5">
          <div className="w-full">
            <Textarea
              id="details.propDesc"
              value={form.values.details.propDesc}
              onChange={form.handleChange}
              required={true}
              rows={5}
              className="text-sm"
              placeholder="Write a few lines about your property something which is special and makes your property stand out. Please do not mention your contact details in any format."
            ></Textarea>
          </div>
        </div>

        <div className="flex gap-4 mb-5 items-center">
          <Button
            disabled={fetching}
            onClick={() => handleButtonClick("locality")}
            className="flex items-end"
            color={"gray"}
          >
            {fetching ? <Loading /> : ""}
            <FaArrowLeft className="text-sm mr-2" />
            Back
          </Button>
          <Button
            disabled={fetching || isDisabled()}
            onClick={() => handleButtonClick("amenity")}
            className="flex items-end"
          >
            {fetching ? <Loading /> : ""}
            Next to Amenities
            <FaArrowRight className="text-sm ml-2" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResaleDetails;
