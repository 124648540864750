import { Button, Select } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { useNavigate } from "react-router";
import MultiSelect from "../../common/MultiSelect";
import { Locality, PropertyType } from "../../../types/Locality";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CityContext } from "../../../contexts/CityContext";
import UserApi from "../../../api/UserApi";
import Loading from "../../../utils/Loading";
import { AdvanceSearchContext } from "./AdvanceSearchContext";
import { LocalityContext } from "../../../contexts/LocalityContext";

const SearchOptions = ({ type }) => {
  const { searchData, setSearchData } = React.useContext(AdvanceSearchContext);
  const [isLoading, setIsLoading] = useState(false);
  const naviagte = useNavigate();
  const { city, setCity } = React.useContext(CityContext);
  const { locality, setLocality } = React.useContext(LocalityContext);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  const [propertyType, setPropertyType] = useState([]);
  const [localityArr, setLocalityArr] = useState([]);

  const advanceSearchFormik = useFormik({
    initialValues: {
      availableType: "",
      city: city,
      locality: "",
      type: "", //apartment, flat, house
      range: {
        from: "",
        to: "",
      },
    },
    validationSchema: Yup.object({
      city: Yup.string()
        .min(1, "Name must be at least 1 characters")
        .max(20, "Name must be max 20 Characters or less")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setIsLoading(true);
      const postUpdatedData = {
        availableType: type ? "Rent" : "Buy",
        city: city,
        // locality: values.locality,
        // type: values.type,
        range: {
          from: values.range.from,
          to: values.range.to,
        },
      };
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await UserApi.getAdvanceSearchResults(
            postUpdatedData
          );
          setIsLoading(false);
          console.log(response);
          if (response) {
            const approvedOnly = response?.data?.filter(
              (item) => item.adminApproval === true
            );
            setSearchData(approvedOnly);
            naviagte("/advance-search");
            return true;
          } else {
            setErr({ error: response.data.data, show: true });
            return false;
          }
        } else {
          setIsLoading(false);
          alert("You`re offline");
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        alert("Error Updating Data");
      }
    },
  });

  const handleLocality = (e) => {
    // setLocality(e);
  };

  const handlePropertyType = (e) => {
    console.log(propertyType);
  };

  useEffect(() => {
    const sort =
      locality.length > 0 &&
      locality.map((item, index) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
    setLocalityArr(sort);
  }, [city, locality]);

  const LocalityAndType = () => {
    return (
      <>
        <div className="flex gap-4">
          <div className="w-1/2">
            <p className="mb-2 text-sm font-semibold">Property Locality</p>
            {localityArr && (
              <MultiSelect data={localityArr} handleChange={handleLocality} />
            )}
          </div>

          <div className="w-1/2">
            <p className="mb-2 text-sm font-semibold">Property Type</p>
            <MultiSelect
              data={PropertyType}
              handleChange={handlePropertyType}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="px-6 py-4">
      <form className="" onSubmit={advanceSearchFormik.handleSubmit}>
        <LocalityAndType />
        <p className="mt-4 mb-2 text-sm font-semibold">Price Range lakhs</p>
        <div className="flex gap-4">
          <div className="w-1/2">
            {type ? (
              <>
                <Select
                  id="range.from"
                  name="range.from"
                  value={advanceSearchFormik.values.range.from}
                  onChange={advanceSearchFormik.handleChange}
                  shadow={true}
                  className=""
                  color={"info"}
                  sizing={"sm"}
                >
                  <option selected disabled value={null}>
                    Select range
                  </option>
                  <option value={"5000"}>₹ 5000</option>
                  <option value={"10000"}>₹ 10000</option>
                  <option value={"20000"}>₹ 20000</option>
                  <option value={"30000"}>₹ 30000</option>
                  <option value={"40000"}>₹ 40000</option>
                  <option value={"50000"}>₹ 50000</option>
                  <option value={"50000"}>All above 50K</option>
                </Select>
              </>
            ) : (
              <>
                <Select
                  id="range.from"
                  name="range.from"
                  value={advanceSearchFormik.values.range.from}
                  onChange={advanceSearchFormik.handleChange}
                  shadow={true}
                  className=""
                  color={"info"}
                  sizing={"sm"}
                >
                  <option selected disabled value={null}>
                    Select range
                  </option>
                  <option value={"1000000"}>₹ 10 Lakhs</option>
                  <option value={"2000000"}>₹ 20 Lakhs</option>
                  <option value={"3000000"}>₹ 30 Lakhs</option>
                  <option value={"4000000"}>₹ 40 Lakhs</option>
                </Select>
              </>
            )}
          </div>
          <div className="w-1/2">
            {type ? ( //rent is true if type is true
              <>
                <Select
                  id="range.to"
                  name="range.to"
                  value={advanceSearchFormik.values.range.to}
                  onChange={advanceSearchFormik.handleChange}
                  shadow={true}
                  className=""
                  color={"info"}
                  sizing={"sm"}
                >
                  <option selected disabled value={null}>
                    Select range
                  </option>
                  <option value={"10000"}>₹ 10000</option>
                  <option value={"20000"}>₹ 20000</option>
                  <option value={"30000"}>₹ 30000</option>
                  <option value={"40000"}>₹ 40000</option>
                  <option value={"50000"}>₹ 50000</option>
                  <option value={"50000"}>All above 50K</option>
                </Select>
              </>
            ) : (
              <>
                <Select
                  id="range.to"
                  name="range.to"
                  value={advanceSearchFormik.values.range.to}
                  onChange={advanceSearchFormik.handleChange}
                  shadow={true}
                  className=""
                  color={"info"}
                  sizing={"sm"}
                >
                  <option selected disabled value={null}>
                    Select range
                  </option>
                  <option value={"4000000"}>₹ 40 Lakhs</option>
                  <option value={"5000000"}>₹ 50 Lakhs</option>
                  <option value={"8000000"}>₹ 80 Lakhs</option>
                  <option value={"10000000000"}>₹ 1 Cr+</option>
                </Select>
              </>
            )}
          </div>
        </div>

        <div className="mt-6 flex justify-between gap-2">
          <div className="flex-1">
            <Button
              type="submit"
              onSubmit={advanceSearchFormik.handleSubmit}
              disabled={isLoading}
              fullSized={true}
              color={"info"}
            >
              {isLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <HiSearch className="mr-2 h-5 w-5" />
              )}
              {/* */}
              Search
            </Button>
          </div>
          {/* <div className="flex-1 flex justify-end">
            <Button
              type="submit"
              color={"white"}
              onSubmit={advanceSearchFormik.handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <BsFilter className="mr-2 h-5 w-5" />
              )}
              Filters
            </Button>
          </div> */}
        </div>
        {/* <div className="mt-6 flex justify-between gap-2">
          
        </div> */}
      </form>
    </div>
  );
};

export default SearchOptions;
