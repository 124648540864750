import React, { useEffect, useState } from "react";
import { FaDollyFlatbed, FaExpand, FaShower } from "react-icons/fa";
import AdminApi from "../../../../api/AdminApi";
import { Link } from "react-router-dom";
import { PriceConvert } from "../../../../utils/PriceConvert";
import { BaseSetting } from "../../../../utils/settings";
import Loading from "../../../../utils/Loading";

const SimilarProperties = ({ filterOptions }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);
  const [err, setErr] = useState({
    error: "",
    show: false,
  });
  console.log(data);
  const getSimilarProperties = async () => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        setErr({
          error: "",
          show: false,
        });
        const response = await AdminApi.getSimilarProperties(filterOptions);
        console.log(response);
        if (response) {
          const approvedOnly = response.filter(
            (item) => item.adminApproval === true
          );
          setData(approvedOnly);
          setIsLoading(false);
        } else {
          setErr({ error: response.data.data, show: true });
          setIsLoading(false);
          return false;
        }
      } else {
        alert("You`re offline");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error loading data");
    }
  };
  useEffect(() => {
    getSimilarProperties();
  }, []);
  return (
    <>
      <div className="flex flex-wrap gap-5 mt-5 bg-transparent">
        {data.length > 0 ? (
          data.map((item, index) => {
            return (
              <div className=" flex-1 justify-between bg-transparent hoverTransition">
                <div className="max-w-sm bg-white rounded-lg shadow-defaultShadow dark:bg-gray-800 dark:border-gray-700">
                  <Link to={`/single-property/${item._id}`}>
                    <img
                      className="rounded-t-lg "
                      src={`${BaseSetting.ApiDomain}/file/images/${item.images[0]}`}
                      alt=""
                    />
                  </Link>
                  <div className="p-5">
                    <Link to={`/single-property/${item._id}`}>
                      <h5 className="overflow-custom mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white hover:text-mainBlue">
                        {item?.name}{" "}
                        <span className="md:ml-3 text-sm font-normal text-gray-600 dark:text-gray-500">
                          {item.availableType === "Rent"
                            ? "For Rent"
                            : "For Buy Sale"}
                        </span>
                      </h5>
                      <p className="text-base hover:text-blue-700 text-blue-600 dark:text-white">
                        ₹ {PriceConvert(item?.priceDetails?.expectedPrice)}
                      </p>
                    </Link>
                    <p className="my-3 text-xs text-gray-700 dark:text-gray-400 max-h-[35px] overflow-hidden">
                      {item?.description}
                    </p>
                    <div className="flex gap-4">
                      <p className="flex items-center gap-2 text-para text-xs">
                        <FaDollyFlatbed />{" "}
                        <span>{item?.details?.bathroom}</span>
                      </p>
                      <p className="flex items-center gap-2 text-para text-xs">
                        <FaShower /> {item?.details?.bathroom}
                      </p>
                      <p className="flex items-center gap-2 text-para text-xs">
                        <FaExpand /> {item?.superBuiltupArea} sq.ft
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default SimilarProperties;
