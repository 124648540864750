import { Avatar, Button, Dropdown, Navbar, ToggleSwitch } from "flowbite-react";
import React from "react";
import { FaBars } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { emptyAuth } from "../../state/auth/Actions";
import ThemeSelector from "../../contexts/ThemeSelector";
import { BaseSetting } from "../../utils/settings";

const NavDashboard = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const Auth = useSelector((state) => state.auth.authData);
  const onChange = () => {
    console.log("onchange");
  };
  const SignOut = () => {
    emptyAuth();
    navigate("/");
  };
  return (
    <Navbar
      fluid={true}
      rounded={false}
      className="border-b-2 rounded-none z-10 fixed w-full"
    >
      <Navbar.Brand href="#" className="flex items-center text-main">
        <Button
          onClick={toggleSidebar}
          size="sm"
          color="gray"
          outline={false}
          className="border-none mr-3 p-0"
        >
          <FaBars className="h-6 sm:h-9 text-xl" />
        </Button>
        <Link to={`/`}>
          <span className="self-center text-main whitespace-nowrap text-2xl font-semibold dark:text-white">
            MIT Homes
          </span>
        </Link>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <div className="mr-5">
          <ThemeSelector />
        </div>
        <Dropdown
          arrowIcon={false}
          inline={true}
          label={
            <Avatar
              alt="User settings"
              img={
                Auth.user.profilePic
                  ? `${BaseSetting.ApiDomain}/file/images/${Auth.user.profilePic}`
                  : ""
              }
              rounded={true}
            />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">{Auth.user.name}</span>
            <span className="block truncate text-sm font-medium">
              {Auth.user.email}
            </span>
          </Dropdown.Header>
          <Dropdown.Item>
            <Link to={"/dashboard"}>Dashboard</Link>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={SignOut}>Sign out</Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      {/* <Navbar.Collapse>
            <Navbar.Link href="/navbars" active={true}>
              Home
            </Navbar.Link>
            <Navbar.Link href="/navbars">About</Navbar.Link>
            <Navbar.Link href="/navbars">Services</Navbar.Link>
            <Navbar.Link href="/navbars">Pricing</Navbar.Link>
            <Navbar.Link href="/navbars">Contact</Navbar.Link>
          </Navbar.Collapse> */}
    </Navbar>
  );
};

export default NavDashboard;
