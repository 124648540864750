import React from "react";
import { Routes } from "react-router-dom";
import { Navigate, Route } from "react-router";
import FrontLayout from "../Layout/FrontLayout";
import Home from "../pages/Home";
import DashboardLayout from "../Layout/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import AdvanceSearch from "../pages/AdvanceSearch";
import SingleProperty from "../pages/SingleProperty";
import ContactUs from "../pages/ContactUs";
import AllUsers from "../pages/Dashboard/Admin/Users/AllUsers";
import { BaseSetting } from "../utils/settings";
import Wishlist from "../pages/Dashboard/Customers/Wishlist/Wishlist";
import UserProfile from "../pages/Dashboard/Customers/Profile/UserProfile";
import ResellerProfile from "../pages/Dashboard/Resellers/Profile/ResellerProfile";
import UploadProperty from "../pages/Dashboard/Resellers/UploadProperty/UploadProperty";
import AddUser from "../pages/Dashboard/Admin/Users/AddUser";
import UploadProject from "../pages/Dashboard/Admin/UploadProject/UploadProject";
import AllProperties from "../pages/Dashboard/Admin/AllProperties/AllProperties";
import AllProjects from "../pages/Dashboard/Admin/AllProjects/AllProjects";
import ForgotPassword from "../pages/ForgotPassword";
import AllBuilders from "../pages/Dashboard/Admin/AllBuilders/AllBuilders";
import UploadBuilder from "../pages/Dashboard/Admin/UploadBuilder/UploadBuilder";
import AllLeads from "../pages/Dashboard/Admin/AllLeads/AllLeads";
import ResellerLeads from "../pages/Dashboard/Resellers/ResellerLeads/ResellerLeads";
import ListingReq from "../pages/Dashboard/Admin/ListingRequests.jsx/ListingReq";
import ResellerProperties from "../pages/Dashboard/Resellers/ResellerProperties/ResellerProperties";
import SingleProject from "../pages/SingleProject";
import { useSelector } from "react-redux";
import ResellerDashboard from "../pages/Dashboard/Resellers/ResellerDashboard";
import ChangePassword from "../components/Dashboard/Customer/ChangePassword";
import PropertyTypes from "../pages/PropertyTypes";
import About from "../pages/About";
import AddLocalities from "../pages/Dashboard/Admin/AddLocalities/Addlocalities";

const AppRouter = () => {
  const Auth = useSelector((state) => state.auth.authData);
  console.log(Auth);
  //Design console output
  const ex1234nsdbduehe8re = [
    "padding: 1rem 1.5rem;",
    "background: #3872e1;",
    "font: 1rem/1 Arial;",
    "color: #ffffff;",
  ].join("");
  console.log("%c%s", ex1234nsdbduehe8re, BaseSetting.name);
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/" element={<FrontLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="advance-search" element={<AdvanceSearch />} />
        <Route path="property-types/location/:id" element={<PropertyTypes />} />
        <Route path="property-types/type/:id" element={<PropertyTypes />} />
        <Route path="/single-property/:id" element={<SingleProperty />} />
        <Route path="/project/:id" element={<SingleProject />} />
        <Route
          path="/builder/:id"
          element={() =>
            console.log("Project Overview Page, listing of properties")
          }
        />
        <Route path="/contact" element={<ContactUs />} />
      </Route>
      <Route
        path="/dashboard"
        element={
          !Auth.isAuthenticated ? <Navigate to="/login" /> : <DashboardLayout />
        }
      >
        {/*
         ********* Common Paths
         */}
        <Route
          index
          element={
            Auth.user.role !== "admin" ? (
              <Navigate to={"/dashboard/reseller"} />
            ) : (
              <Dashboard />
            )
          }
        />
        <Route path="profile" element={<UserProfile />} />{" "}
        <Route path="change-password" element={<ChangePassword />} />{" "}
        {/***********************************************************
         ********************* Admin Routes **************************
         ************************ */}
        <Route
          path="admin/allusers"
          element={
            Auth.user.role !== "admin" ? <Navigate to={"/"} /> : <AllUsers />
          }
        />
        <Route
          path="admin/adduser"
          element={
            Auth.user.role !== "admin" ? <Navigate to={"/"} /> : <AddUser />
          }
        />
        <Route
          path="admin/addLocalities"
          element={
            Auth.user.role !== "admin" ? (
              <Navigate to={"/"} />
            ) : (
              <AddLocalities />
            )
          }
        />
        <Route
          path="admin/all-leads"
          element={
            Auth.user.role !== "admin" ? <Navigate to={"/"} /> : <AllLeads />
          }
        />
        <Route
          path="admin/all-properties"
          element={
            Auth.user.role !== "admin" ? (
              <Navigate to={"/"} />
            ) : (
              <AllProperties />
            )
          }
        />
        <Route
          path="admin/all-projects"
          element={
            Auth.user.role !== "admin" ? <Navigate to={"/"} /> : <AllProjects />
          }
        />
        <Route
          path="admin/upload-project/:id"
          element={
            Auth.user.role !== "admin" ? (
              <Navigate to={"/"} />
            ) : (
              <UploadProject />
            )
          }
        />
        <Route
          path="admin/all-builders"
          element={
            Auth.user.role !== "admin" ? <Navigate to={"/"} /> : <AllBuilders />
          }
        />
        <Route
          path="admin/upload-builder/:id"
          element={
            Auth.user.role !== "admin" ? (
              <Navigate to={"/"} />
            ) : (
              <UploadBuilder />
            )
          }
        />
        <Route
          path="admin/all-listings"
          element={
            Auth.user.role !== "admin" ? <Navigate to={"/"} /> : <ListingReq />
          }
        />
        {/*****************************************************************
         ***************************** Resellers Routes **********************
         ******************************************************************/}
        <Route path="reseller" element={<ResellerDashboard />} />
        <Route path="reseller/leads" element={<ResellerLeads />} />
        <Route
          path="reseller/all-properties"
          element={<ResellerProperties />}
        />
        <Route
          path="reseller/upload-property/:id"
          element={<UploadProperty />}
        />
        <Route
          path="reseller/edit-property"
          element={() => console.log("Property Edit")}
        />
        <Route path="wishlist" element={<Wishlist />} />
        <Route path="user/wishlist" element={<Wishlist />} />
        {/* <Route path="reseller/profile/" element={<ResellerProfile />} /> */}
      </Route>
    </Routes>
  );
};

export default AppRouter;
