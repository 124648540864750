import { del, get, headers, patch, post, put } from "./index.js";
import { BaseSetting } from "../utils/settings.js";
const apis = {
  /** 
   * Get All Users
   * @param {String} token User Token
   * @returns {[]} an array[]: data
   */
  getAllUsers: async (token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/user`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  },

  /** 
   * Delete User
   * @param {string} id UserID which will be deleted
   * @param {String} token Auth Token
   * @returns {Boolean}
   */
  deleteUser: async(id, token) => {
    try {
        const response = await del(BaseSetting.ApiDomain + '/builder/' + id, {
          ...headers,
          'x-auth-token': token
        })
        return response.data.status === 1 ? true : false
    } catch (error) {
        return false
    }
  },

  /** 
   * Create User without Verifying Mobile Number, Only for Admin Section
   * @param {object} req Userid which will be deleted
   * @param {String} token User Token
   * @returns {Object} data
   */
  createUser: async(req, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/user/create', req, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data;
        // return response.data.status === 1 ? response.data.data : false;
    } catch (error) {
        return false
    }
  },

  /**
   * Builder APIs
   */
  getAllBuilders: async(token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/builder`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  },
  createBuilder: async(req, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/builder/', req, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  getBuilder: async(id) => {
    try {
        const response = await get(BaseSetting.ApiDomain + '/builder/' + id, {
            ...headers,
            // ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  updateBuilder: async(id, req, token) => {
    try {
        const response = await put(BaseSetting.ApiDomain + '/builder/' + id, req, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  deleteBuilder: async(id, token) => {
    try {
        const response = await del(BaseSetting.ApiDomain + '/builder/' + id, {
          ...headers,
          'x-auth-token': token
        })
        return response.data.status === 1 ? true : false
    } catch (error) {
        return false
    }
  },
  /** */

  /**
   ****** Property APIs *****
   */
  getAllProperties: async(token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/property`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  },
  createProperty: async(req, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/property', req, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  getProperty: async(id, token) => {
    try {
        const response = await get(BaseSetting.ApiDomain + '/property/' + id, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },

  getFeaturedProperties: async() => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/property/getByFilter/', {
          "featured": true
        }, {
            ...headers,
            // ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },

  //filterOptions is an object which take multiple filters for similar properties 
  //like availableType, carpetArea etc.
getSimilarProperties: async(filterOptions) => { 
    try {
        const response = await post(BaseSetting.ApiDomain + '/property/getByFilter/', filterOptions, {
            ...headers,
            // ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },

  getPropertyByUserId: async(id, token) => {
    try {
        const response = await get(BaseSetting.ApiDomain + '/property/getByUser/' + id, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  updateProperty: async(id, req, token) => {
    try {
        const response = await put(BaseSetting.ApiDomain + '/property/' + id, req, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  deleteProperty: async(id, token) => {
    try {
        const response = await del(BaseSetting.ApiDomain + '/property/' + id, {
          ...headers,
          'x-auth-token': token
        })
        return response.data.status === 1 ? true : false
    } catch (error) {
        return false
    }
  },
  /** */

  /**
   * Project APIs
   */
  getAllProjects: async(token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/project`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  }, 
  createProject: async(req, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/project', req, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  getProject: async(id, token) => {
    try {
        const response = await get(BaseSetting.ApiDomain + '/project/' + id, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  getByProject: async(id) => {
    try {
        const response = await get(BaseSetting.ApiDomain + '/property/getByProject/' + id, {
            ...headers,
            // ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  updateProject: async(id, req, token) => {
    try {
        const response = await put(BaseSetting.ApiDomain + '/project/' + id, req, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  deleteProject: async(id, token) => {
    try {
        const response = await del(BaseSetting.ApiDomain + '/project/' + id, {
          ...headers,
          'x-auth-token': token
        })
        return response.data.status === 1 ? true : false
    } catch (error) {
        return false
    }
  },


  /***
   * Listing Request APIs
   */
  //All Listing Requests for Property Addition
  getAllListings: async(token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/reseller/listingRequest`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  }, 

  requestForListing: async(token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/reseller/listingRequest/' , {}, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? true : false
    } catch (error) {
        return false
    }
  },

  listingRequestComplete: async(id, token) => {
    try {
        const response = await patch(BaseSetting.ApiDomain + '/reseller/listingRequest/markComplete/' + id, {}, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? true : false
    } catch (error) {
        return false
    }
  },

  /**
   * Lead Generation APIs
   **/
  /** Get All Leads
   * @param {String} token User Token
   * @returns an array[]: data
   */
  getAllLeads: async(token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/reseller/lead/getAll`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  }, 

  /** Get Reseller Leads
   * @param {String} token User Token
   * @returns an array[]: data
   */
  getResellerLeads: async(token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/reseller/lead`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  }, 

  /** Get Reseller Leads
   * @param {String} token User Token
   * @returns an array[]: data
   * Use this api to fetch a particular reseller leads
   */
  getMyLeads: async(token) => { 
    try {
      const response = await get(BaseSetting.ApiDomain + `/reseller/lead/getMyLeads`, { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  }, 

  /** Get Leads by PropertyId
   * @param {String} id Property ID
   * @param {String} token User Token
   * @returns {[]} an array[]: data
   */
  getLeadsByPropertyId: async(id, token) => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/reseller/lead/` + id , { ...headers, ["x-auth-token"]: token });
      return response?.data?.status === 1 ? response?.data?.data : false;
    } catch (error) {
      return error;
    }
  }, 

   /** 
   * Create a Lead
   * @param {String} id Property ID
   * @param {String} token User Token
   * @returns a status: 1 or 0 [true or false]
   */
  createLead: async(propertyId, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/reseller/lead/' + propertyId, {}, {
            ...headers,
            'x-auth-token': token
        })
        console.log(response);
        return response.data.status === 1 ? true : false;
    } catch (error) {
        return false
    }
  },
   
  

  /**
   * 
   * Submit Property for Approval
   * @param id  Propertyid which needs Approval from Admin
   * @param token UserToken who is requesting the Approval for property
   */
  sendApproveRequest: async(id, token) => {
    try {
        const response = await post(BaseSetting.ApiDomain + '/reseller/approveRequest/' + id , {}, {
            ...headers,
            ['x-auth-token']: token
        })
        return response.data.status === 1 ? response.data.data : false
    } catch (error) {
        return false
    }
  },
  /**
   *Admin can only approve the properties, only then they will be visible on the website
   */
};

export default apis;
