import { Select } from "flowbite-react";
import React from "react";
import { FaDollyFlatbed, FaExpand, FaShower } from "react-icons/fa";
import { PriceConvert } from "../../../utils/PriceConvert";
import SearchTabs from "../SearchTabs/SearchTabs";
import { Link } from "react-router-dom";
import CitySelector from "../../../contexts/CitySelector";
import { BaseSetting } from "../../../utils/settings";

const AdvanceOptions = ({ searchData }) => {
  console.log(searchData);
  return (
    <>
      <div className="advance-options-main-div pl-4 pt-4 pr-2 pb-2">
        <div className="flex">
          <div className="options w-full">
            <div className="search-box ">
              <div className="w-full">
                <CitySelector className="w-full" />
              </div>
            </div>
            <div className="tab-options mt-4 w-full">
              {/* #
              ####
                ##### SearchTabs Complete Component Called 👎
               */}
              <SearchTabs />
            </div>
          </div>
        </div>
        <div className="advance-search-results w-full mt-8">
          <div className="results-heading flex flex-wrap justify-between items-center">
            <h1 className="dark:text-white text-xl font-semibold min-w-[50%]">
              {searchData.length} Listings
            </h1>
            <div className="prices_select">
              <Select
                id="prices"
                required={false}
                shadow={true}
                className="flex-1"
                color={"info"}
              >
                <option>Prices High to Low</option>
                <option>Price Low to High</option>
                <option>Newest First</option>
                <option>Oldest First</option>
              </Select>
            </div>
            <div className="mt-3">
              <p className="dark:text-white text-xs leading-5 pr-24 text-darkBody">
                Placeat laboriosam cumque suscipit voluptatem quis facere fugit
                sapiente expedita. Eaque pariatur obcaecati ipsum fugiat. Minus,
                inventore!
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-between gap-5 mt-5 bg-transparent w-full">
            {searchData.length > 0 ? (
              searchData.map((item, index) => {
                return (
                  <div className=" flex-1 bg-transparent">
                    <div className=" bg-white rounded-lg shadow-defaultShadow dark:bg-gray-800 dark:border-gray-700">
                      <Link to={`/single-property/${item._id}`}>
                        <img
                          className="rounded-t-lg"
                          src={`${BaseSetting.ApiDomain}/file/images/${item.images[0]}`}
                          alt=""
                        />
                      </Link>
                      <div className="p-5">
                        <Link to={`/single-property/${item._id}`}>
                          <h5 className="overflow-custom mb-2 text-lg md:text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {item.name}{" "}
                            <span className="ml-3 text-xs md:text-sm font-normal text-gray-600">
                              {item.availableType === "Rent"
                                ? "For Rent"
                                : "For Buy Sale"}
                            </span>
                          </h5>
                          <p className="text-sm hover:text-blue-700 text-blue-600 dark:text-white">
                            ₹ {PriceConvert(item?.priceDetails?.expectedPrice)}
                          </p>
                        </Link>
                        <p className="my-3 text-xs text-gray-700 dark:text-gray-400 max-h-[35px] overflow-hidden">
                          {item.description}
                        </p>
                        <div className="flex md:gap-4 gap-2">
                          <p className="flex items-center gap-1 md:gap-2 text-para text-xs">
                            <FaDollyFlatbed />{" "}
                            <span>{item?.details?.bathroom}</span>
                          </p>
                          <p className="flex items-center gap-1 md:gap-2 text-para text-xs">
                            <FaShower /> {item?.details?.bathroom}
                          </p>
                          <p className="flex items-center gap-1 md:gap-2 text-para text-xs">
                            <FaExpand /> {item?.superBuiltupArea} sq.ft
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h1 className="dark:text-white text-sm">
                No properties for this search, try another search!
              </h1>
            )}
          </div>
        </div>{" "}
        {/***Advance Search Results Div Ends here */}
      </div>
    </>
  );
};

export default AdvanceOptions;
