import { Button, Label } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router";

import {
  SignInWithPass,
  GenerateOTP,
  SubmitVerifyOTP,
} from "../../presenter/Auth/auth";

import { emailRegEx, mobileRegEx } from "../../utils/regex";
import Loading from "../../utils/Loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const Login = ({ toggleForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const Auth = useSelector((state) => state.auth.authData.isAuthenticated);
  // const AuthData = useSelector((state) => state.auth.authData);
  const AuthError = useSelector((state) => state.auth.authError);
  const [upInputs, setUpInputs] = useState({});
  const [otpToggle, setOtpToggle] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const navigate = useNavigate();
  const [err, setErr] = useState({
    error: "",
    show: false,
  });

  useEffect(() => {
    setErr(AuthError);
  }, [AuthError]);

  useEffect(() => {
    if (Auth) {
      navigate("/");
    }
  }, [Auth]);

  /****
   * Handle Functions
   */
  const toggleOtp = () => {
    // setErr({ error: "", show: false });
    setOtpToggle(!otpToggle);
  };

  const handleChange = (e) => {
    setErr({ error: "", show: false });
    let name = e.target.name;
    const value = e.target.value;
    let loginId = upInputs;
    if (name === "userId") {
      if (value !== "") {
        if (mobileRegEx.test(value)) {
          name = "mobile";
          if (loginId.hasOwnProperty("email")) {
            delete loginId.email;
            setUpInputs(loginId);
          }
        } else if (emailRegEx.test(value)) {
          name = "email";
          if (loginId.hasOwnProperty("mobile")) {
            delete loginId.mobile;
            setUpInputs(loginId);
          }
        } else {
          setErr({ error: "Invalid Mobile Number or Email Id", show: true });
          return;
        }
      }
    }
    if (name === "mobile") {
      // console.log(value);
      let len = value?.length;
      let zero = value?.startsWith("0");
      let one = value?.startsWith("1");
      let two = value?.startsWith("2");
      let three = value?.startsWith("3");
      let four = value?.startsWith("4");
      let five = value?.startsWith("5");

      if (len !== 10 || zero || one || two || three || four || five) {
        setErr({ error: "Invalid Mobile Number", show: true });
        return;
      } else {
        setErr({ error: "", show: false });
      }
    }
    setUpInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const response = await SignInWithPass(upInputs);
    console.log(response);
    if (response) {
      setIsLoading(false);
    }
  };

  const otpLoginFormik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .min(10, "Min 10 Characters")
        .max(10, "Max 10 Characters"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const postUpdatedData = {
        mobile: values.mobile,
      };
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await GenerateOTP(postUpdatedData);
          if (response) {
            // alert("Succesfully received OTP");
            verifyOtpFormik.values.mobile = values.mobile;
            setIsOtpSent(true);
            setIsLoading(false);
          } else {
            setErr({ error: response.data.data, show: true });
          }
        } else {
          alert("You're offline");
        }
      } catch (error) {
        console.log(error);
        alert("Error Updating Data");
        setErr({
          error: "Server Error",
          show: true,
        });
      }
    },
  });

  const verifyOtpFormik = useFormik({
    initialValues: {
      mobile: "",
      otp: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .min(10, "Min 10 Characters")
        .max(10, "Max 10 Characters"),
      otp: Yup.string()
        .min(4, "Min 4 Characters")
        .max(4, "Maximum 4 Characters"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const postUpdatedData = {
        mobile: values.mobile,
        otp: values.otp,
      };
      try {
        if (navigator.onLine) {
          setErr({
            error: "",
            show: false,
          });
          const response = await SubmitVerifyOTP(postUpdatedData);
          if (response) {
            // alert("OTP Verified");
            setIsOtpVerified(true);
          } else {
            setErr({ error: response.data.data, show: true });
          }
          setIsLoading(false);
        } else {
          alert("You're offline");
        }
      } catch (error) {
        setIsLoading(false);
        alert("OTP Verify: Error Updating Data");
        setErr({
          error: "Server Error",
          show: true,
        });
      }
    },
  });

  // console.log(otpLoginFormik.values);
  // console.log(verifyOtpFormik.values);

  return (
    <>
      <div className="py-6 px-6 lg:px-8 h-[100vh]  flex-1 w-full md:w-1/2">
        <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
          Sign in to our platform
        </h3>
        {otpToggle ? (
          <>
            <form
              className="flex flex-col gap-4"
              onSubmit={
                isOtpSent
                  ? verifyOtpFormik.handleSubmit
                  : otpLoginFormik.handleSubmit
              }
            >
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="mobile" value="Enter Your Mobile Number" />
                </div>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  value={otpLoginFormik.values.mobile}
                  onChange={otpLoginFormik.handleChange}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="992838XXXX"
                  shadow={true}
                  required
                  disabled={isOtpSent && !err.show}
                />
                {otpLoginFormik.errors.mobile ? (
                  <p className="text-xs text-red-600 ">
                    {otpLoginFormik.errors.mobile}
                  </p>
                ) : null}
              </div>
              {isOtpSent ? (
                <>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="otp" value="Enter OTP here" />
                    </div>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      value={verifyOtpFormik.values.otp}
                      onChange={verifyOtpFormik.handleChange}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter 4 digit OTP here"
                      shadow={true}
                      required
                      // disabled={isAccountFormed && !err.show}
                    />
                    {verifyOtpFormik.errors.otp ? (
                      <p className="text-xs text-red-600 ">
                        {verifyOtpFormik.errors.otp}
                      </p>
                    ) : null}
                  </div>
                </>
              ) : (
                ""
              )}
              {/* <div className="flex items-center gap-2">
                <Checkbox checked={true} id="agree" />
                <Label htmlFor="agree">
                  I agree with the{" "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline dark:text-blue-500"
                  >
                    terms and conditions
                  </a>
                </Label>
              </div> */}
              {isOtpSent ? (
                <>
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? <Loading /> : "Verify OTP"}
                  </Button>
                </>
              ) : (
                <>
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? <Loading /> : " Send OTP"}
                  </Button>
                </>
              )}
            </form>

            <div className="mt-3">
              {err.show ? (
                <div className="mt-2 text-[#da232aff] text-sm">
                  {err.error.message ? err.error.message : err.error}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mt-3 flex justify-between items-center">
              <p
                href="#"
                onClick={toggleOtp}
                className="text-sm dark:text-white underline hover:cursor-pointer "
              >
                Login with Password
              </p>
            </div>
          </>
        ) : (
          // <></>
          <>
            <form
              className="flex flex-col gap-4"
              onSubmit={(e) => handleSubmit(e)}
              // onChange={handleUPChange}
            >
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email" value="Your email/Mobile Number" />
                </div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  // value={loginFormik.values.name}
                  onChange={handleChange}
                  // onKeyUp={loginFormik.onChange}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="enter your email"
                  shadow={true}
                  required
                  // disabled={isAccountFormed}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password" value="Your password" />
                </div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  // value={loginFormik.values.password}
                  onChange={handleChange}
                  // onKeyUp={loginFormik.onChange}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="password"
                  shadow={true}
                  required
                  // disabled={isAccountFormed}
                />
              </div>
              {/* <div className="flex items-center gap-2">
                <Checkbox checked={true} id="agree" />
                <Label htmlFor="agree">
                  I agree with the{" "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline dark:text-blue-500"
                  >
                    terms and conditions
                  </a>
                </Label>
              </div> */}
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <Loading /> : "Login"}
              </Button>
            </form>
            <div className="mt-3">
              {err.show ? (
                <div className="mt-2 text-[#da232aff] text-sm">
                  {err.error.message ? err.error.message : err.error}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mt-3 flex justify-between items-center">
              <Link
                to={"/forgot-password"}
                className="text-sm dark:text-white underline hover:cursor-pointer "
              >
                Forgot password?
              </Link>
              <p
                href="#"
                onClick={toggleOtp}
                className="text-sm dark:text-white underline hover:cursor-pointer "
              >
                Click to Login with Mobile OTP
              </p>
            </div>
          </>
          // <></>
        )}

        <div className="text-center mt-20">
          <div className="h-[1px] w-3/4 dark:bg-lightGray text-center m-auto"></div>
          <h1 className="text-base font-semibold mt-6 block dark:text-white">
            Don't have an account?
          </h1>
          <div className="flex justify-center items-center mt-4">
            <Button outline={true} pill={true} onClick={toggleForm}>
              SIGN UP FOR MIT HOMES
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
