import React from 'react'
import ListingRequestsContainer from '../../../../components/Dashboard/Admin/ListingRequests/ListingRequestsContainer';

const ListingReq = () => {
  return (
    <>
        <ListingRequestsContainer />
    </>
  )
}

export default ListingReq;