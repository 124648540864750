import {get, headers, post, put} from "./index";
import { BaseSetting } from "../utils/settings";
import { setAuth } from "../state/auth/Actions";

const apis = {
    getByUserId: async (id, token) => {
        // id = '635acdb6d73dc75ae04a8217'
        try {
          const response = await get(
            BaseSetting.userApiDomain + `/${id}`,
            { ...headers, ["x-auth-token"]: token }
          );
          //console.log({ response });
          return response?.data?.status === 1 ? response?.data?.data : false;
        } catch (error) {
          return false;
        }
      },
      getMe: async (token) => {
        try {
          const response = await get(
            BaseSetting.userApiDomain + `/me`,
            { ...headers, "x-auth-token": token }
          );
          return response?.data?.status === 1 ? response?.data?.data : false;
        } catch (error) {
          return false;
        }
      },
      getAdvanceSearchResults: async(params) => {
        try {
            const response = await post(BaseSetting.ApiDomain + '/property/getByFilter/', params, {
                ...headers,
            })
            return response.data;
        } catch (error) {
            return false
        }
      },
}


export default apis;