import React from "react";
import AuthContainer from "../components/Auth/AuthContainer";

const Login = () => {
  return (
    <>
      <AuthContainer />
    </>
    
  );
};

export default Login;
