import { Avatar, Dropdown, Navbar } from "flowbite-react";
import React from "react";
import { useSelector } from "react-redux";
import { HiHome } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { CityContext } from "../../contexts/CityContext";
import ThemeSelector from "../../contexts/ThemeSelector";
import { emptyAuth } from "../../state/auth/Actions";
import { BaseSetting } from "../../utils/settings";
import { FaBars } from "react-icons/fa";
import CitySelector from "../../contexts/CitySelector";

const Navigation = () => {
  const navigate = useNavigate();
  const Auth = useSelector((state) => state.auth.authData);

  const signOut = () => {
    emptyAuth();
    navigate("/");
  };
  return (
    <>
      <div className="w-full flex mx-auto shadow-defaultShadow fixed z-10">
        <Navbar fluid={true} rounded={true} className="w-full rounded-none">
          <Link to={"/"}>
            <Navbar.Brand href="/">
              <img
                src="https://flowbite.com/docs/images/logo.svg"
                className="mr-3 h-6 sm:h-9"
                alt="Flowbite Logo"
              />
              <span className="hidden md:block self-center whitespace-nowrap text-base md:text-xl font-semibold dark:text-white">
                {process.env.REACT_APP_TITLE}
              </span>
            </Navbar.Brand>
          </Link>

          <div className="hidden md:flex items-center justify-center gap-6 dark:text-white">
            <Link to="/#" active={true} className="hover:text-mainBlue">
              Home
            </Link>
            <Link to="/about" active={true} className="hover:text-mainBlue">
              About us
            </Link>
            <Link to="/dashboard" className="hover:text-mainBlue">
              Dashboard
            </Link>
            <CitySelector />
          </div>

          <div className="flex md:order-2 items-center">
            <div className="mr-5 items-center hidden md:flex">
              <Link to={"/dashboard/reseller/upload-property/new"}>
                <p className="flex items-center dark:text-white  border-b-2">
                  <HiHome className="mr-1  dark:text-white text-blue-500" />{" "}
                  Upload property
                </p>
              </Link>
            </div>
            <div className="mr-5">
              <ThemeSelector />
            </div>
            <div className="mr-5">
              {Auth.isAuthenticated ? (
                <>
                  <Dropdown
                    arrowIcon={false}
                    inline={true}
                    label={
                      <Avatar
                        alt="User img"
                        img={
                          Auth.user.profilePic
                            ? `${BaseSetting.ApiDomain}/file/images/${Auth.user.profilePic}`
                            : ""
                        }
                        rounded={true}
                      />
                    }
                  >
                    <Dropdown.Header>
                      <span className="block text-sm">{Auth.user.name}</span>
                      <span className="block truncate text-sm font-medium">
                        {Auth.user.email}
                      </span>
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link to="/dashboard">Dashboard</Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={signOut}>Sign out</Dropdown.Item>
                  </Dropdown>
                </>
              ) : (
                <>
                  <div className="flex items-center">
                    <Link
                      to="/login"
                      className="text-base font-normal dark:text-white"
                    >
                      Login
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="flex md:hidden dark:text-white">
              <Dropdown
                arrowIcon={true}
                floatingArrow={true}
                inline={true}
                label={<FaBars className="w-full dark:text-white h-6" />}
              >
                <Dropdown.Item>
                  <Link to="/">Home</Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Link to="/dashboard">Dashboard</Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={signOut}>Sign out</Dropdown.Item>
                <Dropdown.Divider />
                <CitySelector />
              </Dropdown>
            </div>
          </div>
        </Navbar>
      </div>
    </>
  );
};

export default Navigation;
